import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Tab,
  Grid,
  Tabs,
  Input,
  Avatar,
  Switch,
  TextField,
  Typography,
  Button,
} from "@mui/material";

import { getCurrentUserFromStorage } from "../../../lib/helpers";

import { store } from "../../../lib/store";
import {
  prefixUrl,
  getUpdatedUserInfo,
  uploadProfilePicture,
  updateProfileFields,
} from "../../../lib/api-client";
import { FormatOverline } from "@mui/icons-material";

const AccountMainProfile = () => {
  const { state, dispatch } = useContext(store);
  const { currentUser } = state;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [profileValues, setProfileValues] = React.useState({
    bio: currentUser.bio || "",
    website: currentUser.website?.replace(/\&#x2F;/g, "/") || "",
    twitter: currentUser.social?.twitter || "",
    facebook: currentUser.social?.facebook || "",
    instagram: currentUser.social?.instagram || "",
    firstName: currentUser.firstName || "",
    emailPrivacy: currentUser.emailPrivacy || false,
  });

  useEffect(() => {
    getUpdatedUserInfo()
      .then((data) => {
        dispatch({
          type: "updateCurrentUser",
          value: { ...currentUser, ...data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUploadNewPic = (evt) => {
    console.log(evt.target.files[0]);
    uploadProfilePicture(evt.target.files[0])
      .then(() => {
        enqueueSnackbar("Profile pictures saved.", { variant: "success" });
        window.location.reload();
        return;
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error uploading profile image", { variant: "error" });
      });
  };

  const handleProfileFormUpdate = (evt, key) => {
    setProfileValues({ ...profileValues, [key]: evt.target.value });
  };

  const handleOnSave = () => {
    updateProfileFields(profileValues)
      .then((res) => {
        enqueueSnackbar("saved!", { variant: "success" });
        const user = getCurrentUserFromStorage();
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            ...res.data,
          })
        );
        dispatch({
          type: "updateCurrentUser",
          value: { ...res.data, id: res.data._id },
        });
      })
      .catch((err) => {
        enqueueSnackbar("Unable to save fields", { variant: "error" });
      });
  };

  return (
    <>
      <Tabs value={0} variant="scrollable" scrollButtons={false}>
        <Tab
          value={0}
          label="Account"
          onClick={() => history.push("/profile")}
        />
      </Tabs>
      <Box
        sx={{
          borderRadius: "4px",
          background: "#fff",
          padding: "24px",
          marginBottom: "24px",
        }}
      >
        <Grid container spacing={4} sx={{ marginBottom: "24px" }}>
          <Grid item md={4} sm={12}>
            <Typography variant="h5">Account Info</Typography>
            <Typography variant="subtitle2">
              These details cannot be changed.
            </Typography>
          </Grid>
          <Grid item md={8} sm={12}>
            <Typography variant="h6" sx={{ marginBottom: "12px" }}>
              Account Info
            </Typography>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Username"
                  value={currentUser.username}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Account Type"
                  value={currentUser.accountType}
                />
              </Grid>
            </Grid>

            <TextField
              sx={{ marginBottom: "24px" }}
              fullWidth
              label="Email"
              value={currentUser.email}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ borderRadius: "4px", background: "#fff", padding: "24px" }}>
        <Grid container spacing={4} sx={{ marginBottom: "24px" }}>
          <Grid item md={4} sm={12}>
            <Typography variant="h5">Public Profile</Typography>
            <Typography variant="subtitle2">
              These are personal details that will appear on your profile.
            </Typography>
          </Grid>
          <Grid item md={8} sm={12}>
            <Typography variant="h6" sx={{ marginBottom: "12px" }}>
              Profile Picture
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "24px",
              }}
            >
              <label htmlFor="contained-button-file">
                <input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleUploadNewPic}
                  style={{ display: "none" }}
                />

                <Avatar
                  src={`https://ewr1.vultrobjects.com/cs-profilepictures/${currentUser.id}`}
                  sx={{ width: 128, height: 128, cursor: "pointer" }}
                  role="button"
                />
              </label>
              <small style={{ marginLeft: "24px" }}>
                Image size Limit should be 125kb Max.
              </small>
            </Box>

            <Typography variant="h6" sx={{ marginBottom: "12px" }}>
              Profile Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="First Name"
                  onChange={(evt) => handleProfileFormUpdate(evt, "firstName")}
                  value={profileValues.firstName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Website"
                  onChange={(evt) => handleProfileFormUpdate(evt, "website")}
                  value={profileValues.website}
                />
              </Grid>
            </Grid>
            <TextField
              sx={{ marginBottom: "24px" }}
              rows={4}
              multiline
              fullWidth
              label="Bio"
              onChange={(evt) => handleProfileFormUpdate(evt, "bio")}
              value={profileValues.bio}
            />

            <Typography variant="h6" sx={{ marginBottom: "12px" }}>
              Social
            </Typography>

            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Facebook Username"
                  onChange={(evt) => handleProfileFormUpdate(evt, "facebook")}
                  value={profileValues.facebook}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Instagram Username"
                  onChange={(evt) => handleProfileFormUpdate(evt, "instagram")}
                  value={profileValues.instagram}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  sx={{ marginBottom: "24px" }}
                  fullWidth
                  label="Twitter Username"
                  onChange={(evt) => handleProfileFormUpdate(evt, "twitter")}
                  value={profileValues.twitter}
                />
              </Grid>
            </Grid>

            <Button
              onClick={handleOnSave}
              sx={{ marginTop: 3 }}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AccountMainProfile;
