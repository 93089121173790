import { gql, useQuery } from "@apollo/client";

export interface GetCertificateAppraisalsData {
  _id: string;
  appraisedBy: string;
  appraisalDate: number;
  appraisalNotes: string;
  appraisedValue: number;
  appraisalReason: string;
}

export interface GetCertificateAppraisalsQueryData {
  getCertificateAppraisals: [GetCertificateAppraisalsData];
}

export type GetCertificateAppraisalsQueryInput = {
  id: string;
};

export const getCurrentUserQuery = gql`
  query GetCertificateAppraisals($id: ID!) {
    getCertificateAppraisals(id: $id) {
      _id
      appraisedBy
      appraisalDate
      appraisalNotes
      appraisedValue
      appraisalReason
    }
  }
`;

export const useGetCertificateAppraisalsQuery = (id: string) => {
  const result = useQuery<
    GetCertificateAppraisalsQueryData,
    GetCertificateAppraisalsQueryInput
  >(getCurrentUserQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      id,
    },
  });

  return { ...result, appraisals: result?.data?.getCertificateAppraisals };
};
