import { Box, Grid, Skeleton } from "@mui/material";
import * as React from "react";

const PageHeaderStencils = () => (
  <Box
    sx={{
      gap: 1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <div>
      <Skeleton variant="text" height={24} width="200px" />
      <Skeleton variant="text" height={45} width="400px" />
    </div>
    <Skeleton variant="rectangular" height={45} width="120px" />
  </Box>
);

export const ViewCertificateSkeletons = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PageHeaderStencils />
      </Grid>
      <Grid item xs={12} sm={12} md={7} lg={8}>
        {/* Item Gallery */}
        <Skeleton
          sx={{ mb: 2 }}
          variant="rectangular"
          height={300}
          width="100%"
        />

        <Skeleton
          sx={{ mb: 2 }}
          variant="rectangular"
          height={90}
          width="100%"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={4}>
        {/* About the artist */}
        <Skeleton
          sx={{ mb: 2 }}
          variant="rectangular"
          height={150}
          width="100%"
        />

        {/* Details */}
        <Skeleton
          sx={{ mb: 2 }}
          variant="rectangular"
          height={360}
          width="100%"
        />
      </Grid>
    </Grid>
  );
};
