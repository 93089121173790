import { gql, useMutation } from "@apollo/client";

export interface FinishOnboardingQueryData {
  FinishOnboarding: boolean;
}

export type FinishOnboardingInput = {
  website?: string;
  location?: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: Date;
  artistName: string;
  phoneNumber: string;
  address: {
    city: string;
    postal: string;
    address: string;
    country: string;
    province: string;
  };
  profileName?: string;
  settings: {
    allowContact: boolean;
    displayEmailPublic: boolean;
  };
};

const setFinishOnboardingMutation = gql`
  mutation FinishOnboarding($finishOnboardingInput: FinishOnboardingInput) {
    FinishOnboarding(finishOnboardingInput: $finishOnboardingInput)
  }
`;

export const useFinishOnboardingMutation = () => {
  return useMutation(setFinishOnboardingMutation);
};
