import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./NotFound.scss";
import PageContainer from "../../components/molecules/PageContainer/PageContainer";
import notFoundImg from "../../../assets/img/404.svg";
import { useSnackbar } from "notistack";

const NotFound404 = () => {
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    setTimeout(() => {
      enqueueSnackbar(
        "You reached an unknown page so we redirected you to the home page.",
        {
          persist: true,
          action: () => (
            <Button onClick={() => closeSnackbar()} variant="contained">
              OK
            </Button>
          ),
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      history.push("/");
    }, 3000);
  }, []);

  return (
    <div className="not-found-page">
      <PageContainer>
        <img src={notFoundImg} />
        <h3>Oops</h3>
        <p>
          Looks like the page you tried to reach is not available, we are
          redirecting you back to the homepage.
        </p>
        <p>
          If you need to reach this page and its broken, please contact us at
          support@certseal.ca
        </p>

        <Link to="/">Go back home</Link>
      </PageContainer>
    </div>
  );
};

NotFound404.propTypes = {};

export default NotFound404;
