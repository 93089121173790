import {
  Box,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import * as React from "react";
import moment from "moment";
import { ethers } from "ethers";
import { Link } from "react-router-dom";

export const LatestTransactionsController = ({
  latestTransactions,
}: {
  latestTransactions: any[];
}) => {
  const filledTransactions = React.useMemo(
    () =>
      latestTransactions.filter(
        (transaction) => transaction.status === "filled"
      ),
    [latestTransactions]
  );

  const TableRows = React.useMemo(
    () =>
      filledTransactions.map((transaction) => {
        const formatedPrice = transaction?.buy?.data?.quantity_with_fees
          ? `${ethers.utils.formatEther(
              transaction?.buy?.data?.quantity_with_fees
            )} Eth`
          : 0;

        return (
          <TableRow>
            <TableCell>
              {
                <Link to={`/certificate/${transaction.certId}`}>
                  {_e(transaction.certTitle)}
                </Link>
              }
            </TableCell>
            <TableCell>
              <a
                target="_blank"
                href={`https://immutascan.io/address/${transaction.user}`}
              >
                {transaction.user}
              </a>
            </TableCell>
            <TableCell align="right">
              {moment(transaction.updated_timestamp).fromNow()}
            </TableCell>
            <TableCell align="right">{formatedPrice}</TableCell>
          </TableRow>
        );
      }),
    [filledTransactions]
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Filled transactions
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Certificate name</TableCell>
              <TableCell>Purchaser</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{TableRows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
