import * as React from "react";
import {
  Box,
  Menu,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { MenuButtonWrapper } from "./components/UserWalletHeaderDropdown.elements";
import { AttachMoney, LocalAtm } from "@mui/icons-material";
import { TooltipCaption } from "library/components/atoms/TooltipCaption/TooltipCaption";
import { CurrencyConversionType } from "../ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";
import { getEthUsdConversion } from "lib/api-client";
import { formatMoney } from "library/helpers/moneyFormat";
import { useGetOwnerBalances } from "./data/useGetUserBalances";
import { ethers } from "ethers";
import { Link } from "react-router-dom";

export const UserWalletHeaderDropdownController = () => {
  const [userMenuAnchor, set_userMenuAnchor] = React.useState(null);
  const [conversion, setConversion] = React.useState<CurrencyConversionType>(
    {}
  );
  const userMenuOpen = Boolean(userMenuAnchor);
  const handleUserMenuOpen = (event: any) => {
    set_userMenuAnchor(event.currentTarget);
  };

  const { balances, loading: balancesLoading } = useGetOwnerBalances();

  const handleUserMenuClose = () => {
    set_userMenuAnchor(null);
  };

  React.useEffect(() => {
    getEthUsdConversion().then((data: any) => {
      if (data) {
        setConversion(data);
      }
    });
  }, []);

  const hasEthBalance = React.useMemo(() => {
    return balances?.result.filter((bal) => bal.symbol === "ETH");
  }, [balances]);

  const BalanceComponent = ({ hasEthBalance }: { hasEthBalance: any }) => {
    const convertedPrice = ethers.utils.formatEther(
      hasEthBalance?.balance?.hex
    );
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AttachMoney />
          <Tooltip title={convertedPrice} arrow placement="top">
            <TooltipCaption
              style={{ display: "flex", alignItems: "flex-end", gap: 8 }}
            >
              <Typography
                sx={{ wordBreak: "break-all" }}
                variant="h5"
                lineHeight="22px"
              >
                {convertedPrice.length > 4
                  ? parseFloat(convertedPrice).toFixed(4)
                  : convertedPrice}
              </Typography>
              <Typography variant="body2">(ETH)</Typography>
            </TooltipCaption>
          </Tooltip>
        </Box>

        <Typography
          color="GrayText"
          variant="caption"
          sx={{ textAlign: "center" }}
        >
          {conversion.ethereum &&
            `Estimated conversion: ${formatMoney(
              parseFloat(convertedPrice) * conversion.ethereum.usd
            )} (USD)`}
        </Typography>
      </>
    );
  };

  return (
    <>
      <MenuButtonWrapper
        onClick={handleUserMenuOpen}
        style={{ marginLeft: 14, cursor: "pointer", display: "flex" }}
        aria-controls={userMenuOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={userMenuOpen ? "true" : undefined}
      >
        <LocalAtm />
        Wallet
      </MenuButtonWrapper>
      <Menu
        anchorEl={userMenuAnchor}
        id="account-menu"
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        onClick={handleUserMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            gap: 2,
            width: 200,
            padding: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {balancesLoading && <CircularProgress size="20px" />}

          {!balancesLoading && !!hasEthBalance?.length ? (
            <BalanceComponent hasEthBalance={hasEthBalance[0]} />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AttachMoney />

                <Typography
                  sx={{ wordBreak: "break-all" }}
                  variant="h5"
                  lineHeight="22px"
                >
                  0.00
                </Typography>
                <Typography variant="body2">(ETH)</Typography>
              </Box>

              <Typography
                color="GrayText"
                variant="caption"
                sx={{ textAlign: "center" }}
              >
                {conversion.ethereum &&
                  `Estimated conversion: ${formatMoney(
                    parseFloat("0") * conversion.ethereum.usd
                  )} (USD)`}
              </Typography>
            </>
          )}

          <Typography
            color="steelblue"
            variant="caption"
            sx={{ textAlign: "center" }}
          >
            <Link to="/account/wallet">View more</Link>
          </Typography>
        </Box>
      </Menu>
    </>
  );
};
