import React, {useState} from 'react';

import {
  Input,
  Button,
  Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const InputBox = styled('div')(() => ({
  backgroundColor: '#fcfcfb',
  border: '1px solid #ced4da',
  marginTop: '24px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '> label': {
    width: '100%',
    padding: '20px 12px',
    cursor: 'pointer',
    textAlign: 'center',
  },
  '&:hover > label p': {
    textDecoration: 'underline',
  },
  '> label > svg': {
    transition: 'all 0.3s',
  },
  '&:hover > label > svg': {
    transform: 'scale(1.1)',
  }
}));

const FileUploadInput = (props) => {
  const { onChange, multiple, id, ...rest } = props;

  const [files, set_files] = useState([]);

  const handleOnUpdate = (evt) => {
    const files = evt.target.files;
    set_files(files);
    onChange?.(files);
  }

  return (
    <InputBox>
      <label htmlFor={id}>
        <FileUploadOutlinedIcon fontSize='large' />
        <input 
          accept="image/*"
          id={id}
          type="file"
          multiple={multiple}
          onChange={handleOnUpdate}
          {...rest}
          style={{display: 'none'}} />
          <Typography>
            { !files.length ? 'Select an image' : files.length > 1 ? `${files.length} files` : files[0].name }
          </Typography>
      </label>
    </InputBox>
  )
};

export default FileUploadInput;