import styled from "@emotion/styled";

export const TakeoverWrapper = styled.div({
  zIndex: 9999,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: "fixed",
  background: "white",
});

export const ScrollableTakeoverWrapper = styled.div({
  zIndex: 9999,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: "auto",
  position: "fixed",
  background: "white",
  "#full-page-header": {
    top: 0,
    position: "sticky",
  },
});
