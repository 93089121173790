import * as React from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Grid } from "@mui/material";
import { CertificateSlideshow } from "library/routes/SingleCertificateView/components/CertificateSlideshow";
import { GetSingleCertificateTypeForVerify } from "../data/getSingleCertificateForVerify";
import { CertificateStatusCard } from "library/routes/SingleCertificateView/components/CertificateStatusCard";
import { ViewCertificateBlockchainDetails } from "library/routes/ViewCertificateRevamp/components/ViewCertificateBlockchainDetails";
import { ViewCertificateProvenanceTable } from "library/routes/ViewCertificateRevamp/components/ViewCertificateProvenanceTable";
import { ViewCertificateDetails } from "library/routes/ViewCertificateRevamp/components/ViewCertificateDetails";
import { ViewCertificateAboutArtist } from "library/routes/ViewCertificateRevamp/components/ViewCertificateAboutArtist";
import { ViewCertificateAppraisalHistory } from "library/routes/ViewCertificateRevamp/components/ViewCertificateAppraisalHistory";
import { ViewCertificateAppraisalHistoryController } from "library/features/ViewCertificateAppraisalHistory/ViewCertificateAppraisalHistoryController";
import { ViewCertificateBuyOptionsController } from "library/features/ViewCertificateBuyOptions/ViewCertificateBuyOptionsController";

export const VerifyCertificateController = ({
  imxData,
  certificate,
}: {
  imxData?: any[];
  certificate: GetSingleCertificateTypeForVerify;
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={7} lg={8}>
        {/* Item Gallery */}
        <CertificateSlideshow certificate={certificate} />

        {/* Certificate status */}
        <CertificateStatusCard status={certificate.status} />

        {/* Description */}
        <ViewCertificateBlockchainDetails certificate={certificate} />

        {/* Provenance */}
        <ViewCertificateProvenanceTable certificate={certificate} />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={4}>
        {/* About the artist */}
        <ViewCertificateAboutArtist certificate={certificate} />

        {/* Details */}
        <ViewCertificateDetails unauth certificate={certificate} />

        {/* Order Details */}
        <ViewCertificateBuyOptionsController
          imxData={imxData}
          certificate={certificate}
        />

        {/* Appraisal History */}
        <ViewCertificateAppraisalHistoryController certificate={certificate} />
      </Grid>
    </Grid>
  );
};
