import * as React from "react";
import { Card } from "library/components/atoms/Card/Card";
import {
  Box,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
} from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import moment from "moment";
import { Link } from "react-router-dom";
import { GetMyCertificatesQueryType } from "../data/getMyCertificates";

export const MyCertificatesTableController = ({
  page,
  setPage,
  isLoading,
  certificates,
  numberOfCerts,
}: {
  page: number;
  setPage: (val: number) => void;
  isLoading: boolean;
  certificates?: GetMyCertificatesQueryType[];
  numberOfCerts?: number;
}) => {
  if (!certificates?.length || !certificates) {
    return (
      <Card title="">
        <p>This is where your certificates will show up</p>
      </Card>
    );
  }

  return (
    <Card title="">
      <Box sx={{ overflowX: "auto" }}>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell align="right">Sku</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificates.map((row, indx) => {
              const itemImage = row?.itemImage;
              const imgSrc =
                itemImage && itemImage?.path
                  ? itemImage?.path
                  : itemImage && itemImage.length
                  ? itemImage[0].path
                  : "";

              return (
                <TableRow
                  key={indx}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      to={`/certificate/${row._id}`}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar src={imgSrc} sx={{ marginRight: "12px" }} />
                      {_e(row.itemName)}
                    </Link>
                  </TableCell>
                  <TableCell align="right">{row.itemSku}</TableCell>
                  <TableCell align="right">
                    {moment(row.dateCreated).format("DD MMM, YYYY")}
                  </TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      {numberOfCerts && numberOfCerts > 10 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10]}
          count={numberOfCerts}
          rowsPerPage={10}
          page={page}
          onPageChange={(_, pageNum) => setPage(pageNum)}
        />
      )}
    </Card>
  );
};
