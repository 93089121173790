import React from 'react';
import {
  Grid,
  Typography,
} from '@mui/material';

export const RowInputGroup = (props) => {
  const {
    children,
    groupHeading,
    groupSubtitle,
  } = props;

  return (
    <Grid container spacing={4} sx={{marginBottom: '24px'}}>
      <Grid item md={6} sm={12}>
        <Typography variant='h6'>{groupHeading}</Typography>
        <Typography>{groupSubtitle}</Typography>
      </Grid>
      <Grid item md={6} sm={12}>
        {children}
      </Grid>
    </Grid>
  )
};