import * as React from "react";
import { FormatListBulletedOutlined } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";
import { Typography, Link, ListItem, ListItemText } from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import styled from "@emotion/styled";

const DetailsSubTitle = styled.strong({
  display: "block",
  fontWeight: 500,
  marginBottom: 12,
});

const DetailsInfoWrapper = styled.div({
  gap: 12,
  display: "flex",
  flexWrap: "wrap",
});

const BlockchainDetailWrapper = styled.div<{ link: boolean }>(
  ({ link, theme }) => ({
    background: "white",
    border: "1px solid #F1E4E8",
    padding: "8px 12px",
    display: "inline-flex",
    borderRadius: 4,
    flexDirection: "column",
    cursor: link ? "pointer" : "inherit",
    ":hover": {
      borderColor: "#03071E",
    },
  })
);

const BlockchainDetailItem = ({
  link,
  title,
  value,
}: {
  link?: string;
  title: string;
  value: string;
}) => {
  const valueDesc = value.length > 25 ? `${value.slice(0, 25)}...` : value;

  return (
    <BlockchainDetailWrapper title={value} link={!!link}>
      <Typography variant="subtitle2">{title}</Typography>
      {link ? (
        <Link target="_blank" href={link}>
          {valueDesc}
        </Link>
      ) : (
        <Typography>{valueDesc}</Typography>
      )}
    </BlockchainDetailWrapper>
  );
};

export const ViewCertificateBlockchainDetails = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  if (!certificate.isMinted) {
    return (
      <div style={{ marginTop: 24 }}>
        <Card title="Blockchain Details" icon={<FormatListBulletedOutlined />}>
          <Typography>
            This certificate is not yet minted to blockchain therefor it should
            not be considered authentic until it can be verified on blockchain.
            Once your certificate is approved, it will be automatically created
            on blockchain.
          </Typography>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 24 }}>
      {/* <Card title="Blockchain Details" icon={<FormatListBulletedOutlined />}> */}
      <DetailsInfoWrapper>
        {/* IPFS Url */}
        {certificate?.ipfsMetaUrl && (
          <BlockchainDetailItem
            title="IPFS"
            link={certificate.ipfsMetaUrl}
            value={certificate.ipfsMetaUrl}
          />
        )}

        {/* Contract Address new */}
        {certificate.blockchainData?.imx ? (
          <BlockchainDetailItem
            title="Contract Address"
            link={`https://immutascan.io/address/${certificate.blockchainData?.imx.contract_address}`}
            value={certificate.blockchainData.imx.contract_address}
          />
        ) : null}

        {/* Artist royalties */}
        {certificate.additionalDetails?.royaltyPercent ? (
          <BlockchainDetailItem
            title="Artist Royalties"
            value={`${certificate.additionalDetails?.royaltyPercent}%`}
          />
        ) : null}

        {/* Network */}
        <BlockchainDetailItem title="Network" value="Ethereum Layer 2" />

        {/* Contract Type */}
        <BlockchainDetailItem title="Contract Type" value="ERC721" />

        {/* Transaction old */}
        {certificate.blockchainData?.tx ? (
          <BlockchainDetailItem
            title="Minted Transaction Hash"
            link={`https://bscscan.com/tx/${certificate.blockchainData?.tx}`}
            value={certificate.blockchainData?.tx || "N/A"}
          />
        ) : null}

        {/* Contract Address old */}
        {certificate.blockchainData?.receipt?.to ? (
          <BlockchainDetailItem
            title="Contract Address"
            value={certificate.blockchainData?.receipt?.to}
          />
        ) : null}
      </DetailsInfoWrapper>
      {/* </Card> */}
    </div>
  );
};
