import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import { UnknownObject } from "library/shared/sharedTypes";

enum PossibleDialogCloseReasons {
  backdrop = "backdropClick",
  escapeKey = "escapeKeyDown",
}

export const ViewCertificateCancelOrderConfirmation = ({
  open,
  isBusy,
  onClose,
  onConfirmOk,
}: {
  open: boolean;
  isBusy: boolean;
  onClose: () => void;
  onConfirmOk: () => void;
}) => {
  const handleOnClose = (
    event: UnknownObject | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: string
  ) => {
    if (isBusy || reason === PossibleDialogCloseReasons.backdrop) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ alignItems: "center", display: "flex", gap: 1 }}
        id="alert-dialog-title"
      >
        <InfoOutlined />
        {isBusy ? "Please wait" : "Are you sure?"}
      </DialogTitle>
      <DialogContent>
        {isBusy ? (
          <DialogContentText id="alert-dialog-description" sx={{ m: 2 }}>
            This could take a while as we complete the Blockchain transaction.
            Writting to the Blockchain is slow and takes time, please be
            patient, do not reload or navigate away from the page.
          </DialogContentText>
        ) : (
          <>
            <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
              Are you sure you want to cancel this order? This will remove your
              certificate from the marketplaces.
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isBusy} onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          disabled={isBusy}
          color="error"
          variant="contained"
          onClick={onConfirmOk}
          autoFocus
        >
          {isBusy ? <CircularProgress size="1rem" /> : "Cancel order"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
