import { useCallback, useState } from "react";

export const useStepsV2 = <T>(steps: Array<T>, initialStep = 0) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);

  const maxStep = steps.length - 1;

  const isAtFirstStep = currentStepIndex === 0;
  const isAtLastStep = currentStepIndex === maxStep;

  return {
    currentStepIndex,
    currentStep: steps[currentStepIndex],
    nextStep: useCallback(() => {
      if (isAtLastStep) {
        return;
      }
      setCurrentStepIndex(currentStepIndex + 1);
    }, [currentStepIndex, isAtLastStep]),
    prevStep: useCallback(() => {
      if (isAtFirstStep) {
        return;
      }
      setCurrentStepIndex(currentStepIndex - 1);
    }, [currentStepIndex, isAtFirstStep]),
    reset: useCallback(() => {
      setCurrentStepIndex(initialStep);
    }, [initialStep]),
    isAtFirstStep,
    isAtLastStep,
  };
};
