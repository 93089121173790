import styled from "@emotion/styled";

export const IframeContainer = styled.div({
  width: "100%",
  height: "100%",
  marginTop: 24,

  "> iframe": {
    width: "100%",
    height: "100%",
    border: "none",
    minHeight: 800,
  },
});
