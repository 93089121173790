import * as React from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import {
  DashboardSectionTitleWrapper,
  DashboardLatestCertificatesWrapper,
} from "../components/DashbaordRoute.elements";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";
import { getIMXUserMintedAssets } from "lib/api-client";
import styled from "@emotion/styled";
import { MailOutline } from "@mui/icons-material";
import { useGetUserByWalletQuery } from "../data/getUserByWallet";

const ClientsWrapper = styled.div({
  gap: 16,
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const ClientItemWrapper = styled.div({
  gap: 8,
  display: "flex",
  alignItems: "center",
});

export const TopClientsController = () => {
  const user = useGetCurrentUser();
  if (user?.accountType === "individual") {
    // return early for individual accounts as they wont be able to create certificates
    return null;
  }

  const [clients, setClients] = React.useState({});
  const { users: usersInCertSeal } = useGetUserByWalletQuery({
    walletIds: Object.values(clients)?.map((a: any) => a.user) ?? [],
  });

  React.useEffect(() => {
    getIMXUserMintedAssets().then((res) => {
      const filteredClients = res?.data.result.filter(
        (token: any) =>
          token.user.toLowerCase() !== user?.wallet.pub.toLowerCase()
      );
      const countUsers = filteredClients.reduce((acc: any, curr: any) => {
        if (acc[curr.user]) {
          acc[curr.user].count++;
        } else {
          acc[curr.user] = { ...curr, count: 1 };
        }
        return acc;
      }, {});

      setClients(countUsers);
    });
  }, []);

  React.useEffect(() => {
    const newMappedUsers: any = clients;
    usersInCertSeal?.map((certSealUser) => {
      const matchedUserIndex = Object.values(newMappedUsers)?.findIndex(
        (client: any) =>
          client?.user.toLowerCase() === certSealUser.wallet.pub.toLowerCase()
      );
      if (matchedUserIndex !== -1) {
        const itemKey = Object.keys(newMappedUsers)[matchedUserIndex];
        newMappedUsers[itemKey].username = certSealUser.username;
        newMappedUsers[itemKey].certSealUser = true;
        newMappedUsers[itemKey]._id = certSealUser._id;
      }
    });
    setClients({ ...newMappedUsers });
  }, [usersInCertSeal]);

  if (!clients || !Object.values(clients).length) {
    return null;
  }

  return (
    <>
      <DashboardSectionTitleWrapper>
        <Typography variant="h2">Clients</Typography>
      </DashboardSectionTitleWrapper>

      <DashboardLatestCertificatesWrapper>
        <ClientsWrapper>
          {Object.values(clients)?.map((client: any, indx) => {
            const isCertSealUser = client.certSealUser;
            return (
              <ClientItemWrapper key={indx}>
                <Avatar
                  src={`https://ewr1.vultrobjects.com/cs-profilepictures/${client._id}`}
                />
                <Box sx={{ width: "100%" }}>
                  <Typography variant="h3">
                    {isCertSealUser ? client.username : "External user"}
                  </Typography>
                  <Typography variant="caption" color="GrayText">
                    Owns {client.count} certificates
                  </Typography>
                </Box>
                {/* <IconButton disabled>
                  <MailOutline fontSize="small" />
                </IconButton> */}
              </ClientItemWrapper>
            );
          })}
        </ClientsWrapper>
      </DashboardLatestCertificatesWrapper>
    </>
  );
};
