import React from "react";
import { useUserCreatedCertificatesQuery } from "library/routes/DashboardRevamp/data/getUserCreatedCertificates";
import {
  getIMXLatestOrdersForTokenIds,
  getIMXLatestTransfersForTokenIds,
} from "lib/api-client";

export const useGetAllTransfersInIMX = ({ userId }: { userId: string }) => {
  const {
    error: createdTokenIdsError,
    loading: createdTokenIdsLoading,
    tokenIds: createdTokenIds,
  } = useUserCreatedCertificatesQuery({ id: userId });
  const [isLoadingTransfers, setIsLoadingTransfers] = React.useState(false);
  const [isErrorTransfers, setIsErrorTransfers] = React.useState(false);
  const [latestTransactions, setLatestTransactions] = React.useState<any[]>([]);
  React.useEffect(() => {
    Promise.all([
      getIMXLatestTransfersForTokenIds(),
      getIMXLatestOrdersForTokenIds(),
    ])
      .then((res) => {
        const [transfersData, ordersData] = res;
        const transfers = [transfersData, ordersData]
          .map((data: any) => data.data?.result)
          .flat(1)
          .sort((a: any, b: any) => (a.timestamp < b.timestamp ? 1 : -1))
          .map((transfer: any) => {
            const cert = transfer.order_id
              ? createdTokenIds?.find(
                  (e: any) =>
                    e.tokenId !== null &&
                    e.tokenId == transfer?.sell?.data.token_id
                )
              : createdTokenIds?.find(
                  (e: any) =>
                    e.tokenId !== null &&
                    e.tokenId == transfer?.token?.data.token_id
                );
            return {
              ...transfer,
              certId: cert?._id,
              certTitle: cert?.itemName,
            };
          });

        setLatestTransactions(transfers);
        setIsLoadingTransfers(false);
      })
      .catch((err) => {
        setIsErrorTransfers(true);
        console.log("oh dear", err);
      });
  }, [createdTokenIds]);

  return {
    createdTokenIds,
    isErrorTransfers: isErrorTransfers || createdTokenIdsError,
    latestTransactions,
    isLoadingTransfers: isLoadingTransfers || createdTokenIdsLoading,
  };
};
