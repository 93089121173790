import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IMXLink } from "lib/imx/link/imxLinkController";
import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default function HeaderMoreDropdown() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [address, setAddress] = React.useState<null | string>(
    localStorage.getItem("WALLET_ADDRESS")
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnectWallet = () => {
    handleClose();
    localStorage.removeItem("WALLET_ADDRESS");
    setAddress(null);
  };

  const handleLinkConnect = async () => {
    handleClose();
    if (address) {
      return;
    }
    const { address: newAddress, starkPublicKey } = await IMXLink.setup({});
    localStorage.setItem("WALLET_ADDRESS", newAddress);
    localStorage.setItem("STARK_PUBLIC_KEY", starkPublicKey);
    setAddress(newAddress);
  };

  return (
    <div style={{ marginLeft: 8 }}>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {address ? (
          <MenuItem onClick={handleDisconnectWallet}>
            Disconnect wallet
          </MenuItem>
        ) : (
          <MenuItem onClick={handleLinkConnect}>
            Connect external wallet
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
