import styled from "@emotion/styled";

export enum FullPageActionWrapperStyles {
  primary = "primary",
  alternative = "alternative",
}

type FullPageActionWrapperProps = {
  heightStyle?: FullPageActionWrapperStyles;
};

export const FullPageActionWrapper = styled.div<FullPageActionWrapperProps>(
  ({ heightStyle }) => ({
    height: `${
      heightStyle === FullPageActionWrapperStyles.alternative ? "auto" : "100%"
    }`,
    display: "flex",
    "@media screen and (max-width: 768px)": {
      flexDirection: "column-reverse",
      overflow: "auto",
    },
  })
);

export const FullPageActionLeftContentWrapper = styled.div({
  width: "100%",
  display: "flex",
  background: "white",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
});

export const LeftContentWrapper = styled.div({
  width: "100%",
  height: "100%",
  padding: "24px 48px",
  display: "flex",
  overflowY: "auto",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "flex-start",
  "@media screen and (max-width: 600px)": {
    padding: "12px",
  },
});

export const LeftContentFooter = styled.div({
  width: "100%",
  padding: 24,
  display: "flex",
  borderTop: "1px solid #F1E4E8",
});

export const LeftContentActionsWrapper = styled.div({
  gap: 16,
  display: "flex",
  marginLeft: "auto",
});

export const FullPageActionRightContentWrapper =
  styled.div<FullPageActionWrapperProps>(({ heightStyle }) => ({
    width: "100%",
    display: "flex",
    overflow: `${
      heightStyle === FullPageActionWrapperStyles.alternative
        ? "unset"
        : "hidden"
    }`,
    alignItems: `${
      heightStyle === FullPageActionWrapperStyles.alternative
        ? "flex-start"
        : "center"
    }`,
    justifyContent: "center",
  }));
