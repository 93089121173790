import React from "react";

import {
  Box,
  Menu,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
} from "@mui/material";

import { useLocation, Link as RouterLink } from "react-router-dom";
import { clearCurrentUserFromStorage } from "../../../../lib/helpers";
import { store } from "../../../../lib/store";
import { prefixUrl } from "../../../../lib/api-client";
import { MenuButtonWrapper } from "./components/AppBarDropdownMenu.elements";

import {
  Info,
  AttachMoney,
  Notifications,
  AddPhotoAlternate,
} from "@mui/icons-material";

export const MenuItemLink = (props) => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <MenuItem button component={renderLink}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {primary}
      </MenuItem>
    </li>
  );
};

const AppBarUpdatesDropdownMenu = () => {
  const { state } = React.useContext(store);
  const [userMenuAnchor, set_userMenuAnchor] = React.useState(null);

  const userMenuOpen = Boolean(userMenuAnchor);
  const handleUserMenuOpen = (event) => {
    set_userMenuAnchor(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    set_userMenuAnchor(null);
  };

  return (
    <>
      <MenuButtonWrapper
        onClick={handleUserMenuOpen}
        size="small"
        style={{ marginLeft: 14, cursor: "pointer", display: "flex" }}
        aria-controls={userMenuOpen ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={userMenuOpen ? "true" : undefined}
      >
        <Notifications />
        Updates
      </MenuButtonWrapper>
      <Menu
        anchorEl={userMenuAnchor}
        id="account-menu"
        open={userMenuOpen}
        onClose={handleUserMenuClose}
        onClick={handleUserMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ width: 360 }}>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <AttachMoney />
              </ListItemAvatar>
              <ListItemText
                primary="Royalties!"
                secondary="We are working on fully implementing royalties! Stay tuned for more info."
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <AddPhotoAlternate />
              </ListItemAvatar>
              <ListItemText
                primary="New certificate template!"
                secondary="We have added a vertical certificate template, we will be adding more varieties in the future."
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Info />
              </ListItemAvatar>
              <ListItemText
                primary="Design updates!"
                secondary="We are updating the look and feel of the certificate page, you may notice some things have shifted to new locations."
              />
            </ListItem>
          </List>
        </Box>
      </Menu>
    </>
  );
};

export default AppBarUpdatesDropdownMenu;
