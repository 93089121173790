import { gql, useQuery } from "@apollo/client";

export interface GetUserSeenFlagsQueryData {
  UserSeenFlags: [string];
}

export const getUserSeenFlagsQuery = gql`
  query GetUserSeenFlags {
    UserSeenFlags
  }
`;

export const useGetUserSeenFlagsQuery = () => {
  const result = useQuery<GetUserSeenFlagsQueryData>(getUserSeenFlagsQuery, {
    fetchPolicy: "cache-and-network",
  });

  return { ...result, seenFlags: result?.data?.UserSeenFlags };
};
