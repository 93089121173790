import { gql, useQuery } from "@apollo/client";

export type WalletType = {
  pub: string;
};

export type UserSettingsType = {
  [key: string]: boolean;
};

export interface GetCurrentUserData {
  _id: string;
  bio: string;
  status: string;
  wallet: WalletType;
  credits: number;
  website: string;
  settings: UserSettingsType;
  accountType: string;
  UserSeenFlags: [string];
}

export interface GetCurrentUserQueryData {
  GetCurrentUser: GetCurrentUserData;
}

export const getCurrentUserQuery = gql`
  query GetCurrentUser {
    GetCurrentUser {
      _id
      bio
      status
      wallet {
        pub
      }
      credits
      website
      settings
      seenFlags
      artistName
      profileName
      accountType
    }
  }
`;

export const useGetCurrentUserQuery = () => {
  const result = useQuery<GetCurrentUserQueryData>(getCurrentUserQuery, {
    fetchPolicy: "cache-and-network",
  });

  return { ...result, user: result?.data?.GetCurrentUser };
};
