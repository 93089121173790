import * as React from "react";
import {
  LeftContentFooter,
  LeftContentWrapper,
  FullPageActionWrapper,
  LeftContentActionsWrapper,
  FullPageActionWrapperStyles,
  FullPageActionLeftContentWrapper,
  FullPageActionRightContentWrapper,
} from "./components/FullPageAction.elements";

export const FullPageAction = ({
  style,
  actions,
  heightStyle,
  mainContent,
  secondaryContent,
}: {
  style?: React.CSSProperties;
  actions?: React.ReactNode;
  heightStyle?: FullPageActionWrapperStyles;
  mainContent: React.ReactNode;
  secondaryContent: React.ReactNode;
}) => {
  return (
    <FullPageActionWrapper style={style} heightStyle={heightStyle}>
      <FullPageActionLeftContentWrapper>
        <LeftContentWrapper>{mainContent}</LeftContentWrapper>
        {actions && (
          <LeftContentFooter>
            <LeftContentActionsWrapper>{actions}</LeftContentActionsWrapper>
          </LeftContentFooter>
        )}
      </FullPageActionLeftContentWrapper>
      <FullPageActionRightContentWrapper heightStyle={heightStyle}>
        {secondaryContent}
      </FullPageActionRightContentWrapper>
    </FullPageActionWrapper>
  );
};
