import { useSteps } from "library/hooks/useSteps";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import * as React from "react";
import { TransferCertificateStep1 } from "../components/TransferCertificateStep1";
import { TransferCertificateStep2 } from "../components/TransferCertificateStep2";
import { TransferFormValuesType } from "../TransferCertificateTakeoverController";

export const useGetTransferCertificateSteps = ({
  onClose,
  onSuccess,
  certificate,
  updateFormValues,
  transferFormValues,
}: {
  onClose: () => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
  updateFormValues: ({ name, value }: { name: string; value: any }) => void;
  transferFormValues: TransferFormValuesType;
}) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const { step, nextStep, prevStep } = useSteps({ maxSteps: 2 });

  const getComponents = React.useCallback(() => {
    switch (step) {
      case 0:
        return TransferCertificateStep1({
          nextStep,
          certificateName: certificate.itemName,
        });
      case 1:
        return TransferCertificateStep2({
          isBusy,
          onClose,
          nextStep,
          prevStep,
          setIsBusy,
          onSuccess,
          certificate,
          updateFormValues,
          transferFormValues,
        });
      default:
        return {
          actions: null,
          mainContent: null,
          secondaryContent: null,
        };
    }
  }, [step, certificate, transferFormValues, isBusy]);

  return {
    ...getComponents(),
  };
};
