import * as React from "react";
import {
  Card,
  Button,
  Typography,
  CardActions,
  CardContent,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { LoadingTitleContainer } from "./Wallet.elements";
import { TooltipCaption } from "library/components/atoms/TooltipCaption/TooltipCaption";

export type WalletHeaderCard = {
  title: string;
  loading?: boolean;
  caption?: React.ReactNode;
  subTitle: string;
  hasTooltip?: React.ReactNode;
  titleCaption?: string;
};

export const WalletHeaderCard = ({
  title,
  loading,
  caption,
  subTitle,
  hasTooltip,
  titleCaption,
}: WalletHeaderCard) => {
  return (
    <Card
      sx={{ width: "100%", minWidth: 175, borderRadius: "12px" }}
      elevation={1}
    >
      <CardContent sx={{ pb: "16px !important" }}>
        <Typography
          sx={{ fontSize: 18 }}
          color="text.secondary"
          gutterBottom
          variant="h4"
        >
          {subTitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: 48, lineHeight: "1" }}
          component="div"
        >
          {loading ? (
            <LoadingTitleContainer>
              <Skeleton variant="text" />
            </LoadingTitleContainer>
          ) : hasTooltip ? (
            <Tooltip title={hasTooltip} arrow placement="top-start">
              <TooltipCaption
                style={{ display: "flex", alignItems: "flex-end", gap: 8 }}
              >
                {title}{" "}
                {titleCaption && (
                  <Typography variant="caption">{titleCaption}</Typography>
                )}
              </TooltipCaption>
            </Tooltip>
          ) : (
            <>
              {title}{" "}
              {titleCaption && (
                <Typography variant="caption">{titleCaption}</Typography>
              )}
            </>
          )}
        </Typography>
        <Typography
          sx={{ mt: 1.3, mb: 0 }}
          color="GrayText"
          variant="caption"
          component="div"
        >
          {loading ? <Skeleton variant="text" /> : caption}
        </Typography>
      </CardContent>
    </Card>
  );
};
