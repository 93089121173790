import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {
  Box,
  Menu,
  Divider,
  Toolbar,
  MenuItem,
  Container,
  Typography,
  CssBaseline,
} from "@mui/material";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";
import LogoSvg from "../../components/atoms/LogoSvg";
import Copyright from "../../components/atoms/Copyright-unauth";
import { UserWalletHeaderDropdownController } from "library/features/UserWalletHeaderDropdown/UserWalletHeaderDropdownController";
import AppBarDropdownMenu from "../../components/molecules/AppBarDropdownMenu/AppBarDropdownMenu";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PageLayoutUnauth = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const [userMenuAnchor, set_userMenuAnchor] = React.useState(null);
  const user = useGetCurrentUser();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Box sx={{ width: "150px", marginRight: "auto", marginLeft: "12px" }}>
            <LogoSvg href={"https://certseal.ca/"} />
          </Box>

          {user && user.id ? (
            <>
              <UserWalletHeaderDropdownController />
              <AppBarDropdownMenu />
            </>
          ) : (
            <>
              <MenuItem>
                <a href="/register">Register an account</a>
              </MenuItem>
              <Divider
                sx={{ listStyle: "none", height: "24px" }}
                variant="middle"
                component="li"
                orientation="vertical"
              />
              <MenuItem
                href="https://google.ca"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <a href="/">Sign in</a>
              </MenuItem>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};

export default PageLayoutUnauth;
