import * as React from "react";
import { FacebookRounded, Instagram, Twitter } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";
import { Typography, Avatar, Link, IconButton } from "@mui/material";
import styled from "@emotion/styled";

const SocialIconsWrapper = styled.div({
  marginTop: 24,
});

export const ViewCertificateAboutArtist = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  const renderSocialLinks = () => {
    if (
      !certificate.minter?.social?.facebook &&
      !certificate.minter?.social?.twitter &&
      !certificate.minter?.social?.instagram
    ) {
      return null;
    }

    return (
      <SocialIconsWrapper>
        {certificate.minter?.social?.facebook && (
          <IconButton
            target="_blank"
            href={`https://facebook.com/${certificate.minter?.social?.facebook}`}
          >
            <FacebookRounded />
          </IconButton>
        )}

        {certificate.minter?.social?.twitter && (
          <IconButton
            target="_blank"
            href={`https://twitter.com/${certificate.minter?.social?.twitter}`}
          >
            <Twitter />
          </IconButton>
        )}

        {certificate.minter?.social?.twitter && (
          <IconButton
            target="_blank"
            href={`https://instagram.com/${certificate.minter?.social?.instagram}`}
          >
            <Instagram />
          </IconButton>
        )}
      </SocialIconsWrapper>
    );
  };
  return (
    <div>
      <Card
        title={
          <div>
            <Typography variant="body2">About The Artist</Typography>
            <Link
              variant="h5"
              href={`https://app.certseal.ca/profile/${certificate.minter.username}?previewAs=public`}
              target="_blank"
            >
              {certificate.minter.username}
            </Link>
          </div>
        }
        icon={
          <Avatar
            src={`https://ewr1.vultrobjects.com/cs-profilepictures/${certificate.minter._id}`}
            sx={{ width: 42, height: 42, cursor: "pointer" }}
            role="button"
          />
        }
      >
        <Typography variant="caption">Artist Statement</Typography>
        <Typography>
          {certificate.minter.bio ??
            "This author has not setup their bio just yet."}
        </Typography>

        {renderSocialLinks()}
      </Card>
    </div>
  );
};
