import { gql, useQuery } from "@apollo/client";

export interface GetEditionsQueryType {
  _id: string;
  dateCreated: Date;
  collectionName: string;
  collectionHidden: boolean;
}

export interface GetEditionsQueryData {
  getAllCollections: GetEditionsQueryType[];
}

export interface GetEditionsQueryVariables {
  userId: string;
}

export const getEditionsQuery = gql`
  query GetAllCollections($userId: ID!) {
    getAllCollections(id: $userId) {
      _id
      dateCreated
      collectionName
      collectionHidden
    }
  }
`;

export const useGetEditionsQuery = ({ id }: { id: string }) => {
  const result = useQuery<GetEditionsQueryData, GetEditionsQueryVariables>(
    getEditionsQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: { userId: id },
    }
  );

  return { ...result, collections: result?.data?.getAllCollections };
};
