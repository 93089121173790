import { gql, useQuery } from "@apollo/client";
import { CertificateStatus, CertType } from "library/shared/sharedTypes";

type GetAllCertificatesType = {
  _id: string;
  tags?: string[];
  minter: string;
  status: CertificateStatus;
  itemSku: string;
  itemName: string;
  isMinted: boolean;
  itemImage: any;
  isRevoked: boolean;
  dateCreated: Date;
  statementOA: string;
  currentOwner: string;
  itemDescription: string;
  additionalDetails?: any;
};

type GetUserCertificatesQueryData = {
  getAllCertificates: {
    certificates: GetAllCertificatesType[];
  };
};

type GetUserCertificatesQueryVariables = {
  userId: string;
};

export const getUserCertificates = gql`
  query GetAllCertificates($userId: ID) {
    getAllCertificates(params: { userId: $userId }) {
      certificates {
        _id
        status
        minter
        itemSku
        itemName
        isMinted
        itemImage
        isRevoked
        dateCreated
        currentOwner
        itemDescription
        additionalDetails
      }
    }
  }
`;

export const useGetUserCertificatesQuery = ({ id }: { id: string }) => {
  const result = useQuery<
    GetUserCertificatesQueryData,
    GetUserCertificatesQueryVariables
  >(getUserCertificates, {
    fetchPolicy: "cache-and-network",
    variables: { userId: id },
  });

  return {
    ...result,
    certificates: result?.data?.getAllCertificates.certificates,
  };
};
