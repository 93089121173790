import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { postResendEmailActivation } from "lib/api-client";
import { useSnackbar } from "notistack";
import * as React from "react";

export const ActivateUserEmailBanner = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [showActivationEmail, setShowActivationEmail] = React.useState(false);
  const [resendEmailValue, set_resendEmailValue] = React.useState("");

  const handleResendEmail = () => {
    setShowActivationEmail(false);
    if (!resendEmailValue || resendEmailValue === "") {
      return;
    }

    postResendEmailActivation(resendEmailValue)
      .then((response) => {
        enqueueSnackbar(
          "Sent! New activation email has been dispatched! If you do not have CertSeal as a contact, the email may end up in your junk folder",
          { variant: "success" }
        );
      })
      .catch((resendEmailErr) => {
        console.log(resendEmailErr);
        enqueueSnackbar(
          "Error! An error occured when trying to send the email. Please try again later. If the problem persists, please contact us as support@certseal.ca",
          { variant: "error" }
        );
      });
  };

  return (
    <>
      <Alert
        severity="info"
        variant="filled"
        action={
          <Button
            onClick={() => setShowActivationEmail(true)}
            variant="contained"
          >
            Resend Activation
          </Button>
        }
      >
        <AlertTitle>Verify your email</AlertTitle>
        Check your email for an activation link.{" "}
        <strong>Check the spam folder as well!</strong>
        <br />
        If you do not recieve an activation email within 15 minutes, click the
        button in this alert to resend an email.
      </Alert>

      <Dialog
        open={showActivationEmail}
        onClose={() => setShowActivationEmail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resend Activation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Did you miss our email? Enter in your email to resend the activation
            email so you can activate your account and login. If you are having
            issues please contact us at support@certseal.ca
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            autoFocus
            id="email"
            label="Enter email address"
            type="email"
            fullWidth
            value={resendEmailValue}
            onChange={(evt: any) => set_resendEmailValue(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActivationEmail(false)}>Cancel</Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              handleResendEmail();
              setShowActivationEmail(false);
            }}
          >
            Resend Activation
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
