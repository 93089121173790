import * as React from "react";
import { BadgeOutlined } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import { ArtCertificateDescription } from "library/routes/SingleCertificateView/components/ArtCertificateDescription";
import { CertificateHeaderActions } from "./CertificateHeaderActions";

export const ViewCertificateDetails = ({
  unauth,
  certificate,
}: {
  unauth?: boolean;
  certificate: GetSingleCertificateType;
}) => {
  const [descShowMore, setDescShowMore] = React.useState(false);
  const description = _e(certificate.itemDescription);

  const renderDescription = () => {
    if (!description) return null;

    return description?.length > 300 && !descShowMore
      ? description?.slice(0, 300) + "..."
      : description;
  };

  return (
    <Card
      title="Certificate"
      icon={<BadgeOutlined />}
      titleActions={
        <CertificateHeaderActions certificate={certificate} unauth={unauth} />
      }
    >
      <Typography>
        {renderDescription()}
        {description && description?.length > 300 && (
          <a
            style={{
              display: "block",
              fontSize: 14,
              color: "#666",
              cursor: "pointer",
            }}
            onClick={() => setDescShowMore(!descShowMore)}
          >
            {descShowMore ? "Show less ↑" : "Show more ↓"}
          </a>
        )}
      </Typography>

      {certificate.certType === "art" ? (
        <ArtCertificateDescription certificate={certificate} />
      ) : (
        <List>
          <ListItem disableGutters disablePadding>
            <ListItemText secondary={certificate.itemSku}>Sku #</ListItemText>
          </ListItem>

          <ListItem disableGutters disablePadding>
            <ListItemText secondary={certificate.certType}>Type</ListItemText>
          </ListItem>

          <ListItem disableGutters disablePadding>
            <ListItemText secondary={_e(certificate.itemDescription)}>
              Description
            </ListItemText>
          </ListItem>
        </List>
      )}
    </Card>
  );
};
