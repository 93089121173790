import styled from "@emotion/styled";

export const DashboardRouteCTAButtonWrapper = styled.div(({ theme }) => ({
  gap: 24,
  width: "100%",
  cursor: "pointer",
  border: "solid 1px #ddd",
  padding: "12px 24px",
  display: "flex",
  alignItems: "center",
  background: "white",
  borderRadius: 8,
  marginBottom: 24,
}));

export const DashboardRouteCTAButtonText = styled.div({
  gap: 4,
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const LatestTransferListWrapper = styled.div({
  gap: 14,
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "7px",
  borderBottom: "solid 1px #efefef",
  paddingBottom: "7px",
  "&:last-of-type": {
    borderBottom: 0,
  },
  justifyContent: "space-between",
});

export const LatestTransferListTitleWrapper = styled.a({
  gap: 8,
  display: "flex",
  alignItems: "center",
});

export const DashboardSectionTitleWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  marginBottom: 24,
  justifyContent: "space-between",
});

export const DashboardLatestCertificatesWrapper = styled.div({
  gap: 24,
  display: "flex",
  marginBottom: 48,
});
