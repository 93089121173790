import { gql, useQuery } from "@apollo/client";
import { GetSingleCertificateType } from "./getSingleCertificate";

type SingleCertificateQueryAuthData = {
  getSingleCertificateAuth: {
    certificate: GetSingleCertificateType;
    userIsTrueOwner: boolean;
  };
};

type SingleCertificateQueryVariables = {
  certificateId: string;
};

export const getSingleCertificateAuth = gql`
  query GetSingleCertificateAuth($certificateId: ID!) {
    getSingleCertificateAuth(id: $certificateId) {
      certificate {
        _id
        status
        minter {
          _id
          bio
          social {
            twitter
            facebook
            instagram
          }
          username
        }
        itemSku
        tokenId
        certType
        itemName
        isMinted
        itemImage
        dateCreated
        ipfsMetaUrl
        certTemplateId
        blockchainData
        itemDescription
        additionalDetails
      }
      userIsTrueOwner
    }
  }
`;

export const useSingleCertificateAuthQuery = ({ id }: { id: string }) => {
  const result = useQuery<
    SingleCertificateQueryAuthData,
    SingleCertificateQueryVariables
  >(getSingleCertificateAuth, {
    fetchPolicy: "cache-and-network",
    variables: { certificateId: id },
  });

  return {
    ...result,
    certificate: result?.data?.getSingleCertificateAuth.certificate,
    isUserTrueOwner: result?.data?.getSingleCertificateAuth.userIsTrueOwner,
  };
};
