import * as React from "react";
import { useSnackbar } from "notistack";
import { useHistory, useParams } from "react-router-dom";
import {
  ProfileHeader,
  ProfileContent,
  ProfileContentActions,
} from "./components/PublicProfile.elements";
import {
  getPublicProfile,
  getPublicCertsOwned,
  getPublicCertsMinted,
} from "../../../../../lib/api-client";
import type {
  UserCertificatesOwnedQueryType,
  UserProfileDetailsQueryDataType,
  UserCertificatesOwnedQueryTypeManyImages,
} from "./sharedTypes";
import { Typography, Grid, Select, MenuItem } from "@mui/material";
import CertificateCard from "library/components/atoms/CertificateCardProfile/CertificateCard";
import { _e } from "library/helpers/displayEscapedText";

export const PublicProfileController = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [userCerts, setUserCerts] = React.useState<
    | UserCertificatesOwnedQueryType[]
    | UserCertificatesOwnedQueryTypeManyImages[]
    | null
  >(null);
  const [userOwned, setUserOwned] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState("0");
  const [profileDetails, setProfileDetails] =
    React.useState<UserProfileDetailsQueryDataType | null>(null);

  const userDataList = React.useMemo(() => {
    if (selectedTab === "0") {
      return userCerts;
    } else if (selectedTab === "1") {
      return userOwned;
    }
  }, [userOwned, userCerts, selectedTab]);

  React.useEffect(() => {
    getPublicProfile(id)
      .then((res) => {
        setProfileDetails(res.data);

        // get certs
        getPublicCertsMinted(res.data._id)
          .then((res) => {
            setUserCerts(res.data.certificates);
          })
          .catch(() => {
            enqueueSnackbar("Certificates not found", { variant: "error" });
          });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("User not found", { variant: "error" });
      });
  }, []);

  React.useEffect(() => {
    switch (selectedTab) {
      case "1":
        if (!userOwned) {
          getPublicCertsOwned(profileDetails?._id)
            .then((res) => {
              console.log(res);
              setUserOwned(res.data.certificates);
            })
            .catch(() => {
              enqueueSnackbar("Certificates not found", { variant: "error" });
            });
        }
        break;
      default:
        break;
    }
  }, [selectedTab]);

  if (!profileDetails) {
    return <p>User not found</p>;
  }

  return (
    <>
      <ProfileHeader profileDetails={profileDetails} />
      <ProfileContent>
        <ProfileContentActions>
          <Typography variant="h5">Certificates</Typography>
          <div>
            <Typography sx={{ display: "inline-block", color: "#666", mr: 1 }}>
              Filter by:{" "}
            </Typography>
            <Select
              labelId="sort by"
              variant="standard"
              value={selectedTab}
              onChange={(evt) => setSelectedTab(evt.target.value)}
            >
              <MenuItem value="0">Currently own</MenuItem>
              {/* <MenuItem value="1">Created</MenuItem> */}
            </Select>
          </div>
        </ProfileContentActions>
        {userDataList && (
          <Grid container spacing={4}>
            {userDataList.map((cert, indx) => (
              <Grid item xs={12} sm={6} md={3}>
                <CertificateCard
                  key={indx}
                  status={cert.status}
                  onClick={() => history.push(`/verify/${cert._id}`)}
                  title={_e(cert.itemName)}
                  desc={
                    cert.itemDescription.length > 50
                      ? `${_e(cert?.itemDescription)?.slice(0, 50)}...`
                      : _e(cert.itemDescription)
                  }
                  img={
                    (cert as UserCertificatesOwnedQueryType).itemImage?.path ||
                    (cert as UserCertificatesOwnedQueryTypeManyImages)
                      .itemImage?.[0]?.path
                  }
                />
              </Grid>
            ))}
          </Grid>
        )}
      </ProfileContent>
    </>
  );
};
