import * as React from "react";
import debounce from "lodash.debounce";
import { Card } from "library/components/atoms/Card/Card";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import { useQueryParams } from "library/hooks/useQueryParams";
import { CertificateStatus } from "library/shared/sharedTypes";
import { SelectForRowInputField } from "library/components/atoms/SelectForRowInputField";
import { store } from "lib/store";
import {
  CertificateFilterMobileWrapper,
  MobileFiltersDropdownContainer,
  MobileSearchContainer,
} from "../components/CertificatesFilter.elements";
import { Tune } from "@mui/icons-material";
import { IconButton, Collapse } from "@mui/material";
import { useToggle } from "library/hooks/useToggle";
import { TransitionGroup } from "react-transition-group";

type StoreType = {
  state: {
    appName: string;
    currentUser: any;
    pageWidths: {
      isSM: boolean;
      isMobile: boolean;
    };
    toasts: never[];
  };
  dispatch: any;
};

export const CertificatesFiltersController = () => {
  const context = React.useContext(store) as unknown as StoreType;
  const isSM = context.state.pageWidths.isSM;
  const {
    value: mobileMenuOpen,
    setToggleOn: openMobileMenu,
    setToggleOff: closeMobileMenu,
  } = useToggle();

  const { currentParams, appendQueryParam, deleteQueryParam } = useQueryParams({
    pathname: "/certificates",
  });

  const updateSearchParam = debounce((value) => {
    if (!value || value === "") {
      deleteQueryParam("search");
    } else {
      appendQueryParam({
        key: "search",
        value,
      });
    }
  }, 1000);

  const renderSearchInput = ({ title }: { title?: string }) => (
    <RowInputField
      inputProps={{
        placeholder: "Search by certificate name",
        defaultValue: currentParams.search,
      }}
      onChange={(val: string) => {
        updateSearchParam(val);
      }}
      fieldName={title}
    />
  );

  const statusInput = (
    <RowInputField
      fieldName="Certificate status"
      customInput={
        <SelectForRowInputField
          id="bootstrap-input-type"
          value={currentParams?.status ?? ""}
          onChange={(event) => {
            if (event?.target?.value === "") {
              deleteQueryParam("status");
              return;
            }
            appendQueryParam({
              key: "status",
              value: event?.target?.value ?? undefined,
            });
          }}
        >
          <option value="">All</option>
          <option value={CertificateStatus.minted}>Minted</option>
          <option value={CertificateStatus.onhold}>Pending</option>
          <option value={CertificateStatus.approved}>Approved</option>
          <option value={CertificateStatus.revoked}>Revoked</option>
        </SelectForRowInputField>
      }
    />
  );

  if (isSM) {
    return (
      <>
        <CertificateFilterMobileWrapper>
          <MobileSearchContainer>{renderSearchInput({})}</MobileSearchContainer>
          <IconButton
            onClick={mobileMenuOpen ? closeMobileMenu : openMobileMenu}
          >
            <Tune />
          </IconButton>
        </CertificateFilterMobileWrapper>
        <TransitionGroup>
          {mobileMenuOpen && (
            <Collapse>
              <MobileFiltersDropdownContainer>
                {statusInput}
              </MobileFiltersDropdownContainer>
            </Collapse>
          )}
        </TransitionGroup>
      </>
    );
  }

  return (
    <>
      {renderSearchInput({})}
      {statusInput}
    </>
  );
};
