import styled from "@emotion/styled";

export const OnboardingSetupMainContentWrapper = styled.div({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const RoleButtonGroupItem = styled.div<{ active?: boolean }>(
  ({ theme, active }) => ({
    gap: 24,
    cursor: "pointer",
    padding: "12px 24px",
    display: "flex",
    alignItems: "center",
    background: active ? "#F2CA50" : "#f8f8f8",
    borderRadius: "8px",
    ":hover": {
      background: active ? "#f2cb55" : "#f6f6f6",
    },
  })
);

export const FormInputWrapperWithLabel = styled.div({
  gap: 4,
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

export const FormInputRowWrapper = styled.div({
  gap: 24,
  width: "100%",
  display: "flex",
  marginBottom: 24,
});
