import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { DashboardSectionTitleWrapper } from "../components/DashbaordRoute.elements";
import walletBg from "assets/img/walletBG.svg";
import logoIcon from "assets/img/logo_icon_light.svg";
import ethIcon from "assets/img/ethicon.png";
import dollarIcon from "assets/img/dollaricon.png";
import { CopyAll, Info, InfoOutlined } from "@mui/icons-material";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";
import { useSnackbar } from "notistack";
import { getEthUsdConversion } from "lib/api-client";
import { CurrencyConversionType } from "library/features/ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";
import { useGetOwnerBalances } from "library/features/UserWalletHeaderDropdown/data/useGetUserBalances";
import { formatMoney } from "library/helpers/moneyFormat";
import { ethers } from "ethers";
import { TooltipCaption } from "library/components/atoms/TooltipCaption/TooltipCaption";

const WalletWidgetContainer = styled.div({
  height: 175,
  padding: 24,
  background: `url(${walletBg})`,
  borderRadius: 12,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const TopContainer = styled.div({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
});

const BottomContainer = styled.div({
  display: "flex",
  overflow: "hidden",
  alignItems: "flex-start",
  flexDirection: "column",
});

const WalletAddressWrapper = styled.div({
  gap: 4,
  display: "flex",
  maxWidth: "100%",
  alignItems: "center",
});

const WalletDetailsWrapper = styled.div({
  gap: 8,
  display: "flex",
  marginTop: 16,
  flexDirection: "column",
});

const WalletDetailsRowWrapper = styled.div({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  "> p, > div": {
    width: "50%",
  },
});

const WalletDetailsRowPriceWrapper = styled.div({
  gap: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const WalletActionsWrapper = styled.div({
  gap: 16,
  display: "flex",
  flexWrap: "wrap",
  marginTop: 16,
});

export const WalletWidgetController = () => {
  const user = useGetCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { balances, loading: balancesLoading } = useGetOwnerBalances();
  const [conversion, setConversion] = React.useState<CurrencyConversionType>(
    {}
  );

  React.useEffect(() => {
    getEthUsdConversion().then((data: any) => {
      if (data) {
        setConversion(data);
      }
    });
  }, []);

  const hasEthBalance = React.useMemo(() => {
    return balances?.result.filter((bal) => bal.symbol === "ETH");
  }, [balances]);

  const convertedPrice = React.useMemo(() => {
    if (hasEthBalance?.length) {
      return ethers.utils.formatEther(hasEthBalance[0]?.balance?.hex);
    }
    return "0";
  }, [hasEthBalance]);

  return (
    <Box sx={{ mb: 6 }}>
      <DashboardSectionTitleWrapper>
        <Typography variant="h2">Wallet</Typography>
      </DashboardSectionTitleWrapper>
      <WalletWidgetContainer>
        <TopContainer>
          <img src={logoIcon} />
          <Typography variant="caption" color="white">
            Layer 2
          </Typography>
        </TopContainer>
        <BottomContainer>
          <Typography color="white">Wallet Address</Typography>
          <WalletAddressWrapper>
            <Typography
              color="white"
              variant="h6"
              lineHeight="1em"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {user?.wallet.pub}
            </Typography>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(
                  (user as UserCookieType)?.wallet.pub
                );
                enqueueSnackbar("Copied address to clipboard", {
                  anchorOrigin: { vertical: "top", horizontal: "center" },
                });
              }}
            >
              <CopyAll sx={{ fontSize: 12, color: "white" }} />
            </IconButton>
          </WalletAddressWrapper>
        </BottomContainer>
      </WalletWidgetContainer>
      <WalletDetailsWrapper>
        <WalletDetailsRowWrapper>
          <Typography>Current balance:</Typography>
          <WalletDetailsRowPriceWrapper>
            <img src={ethIcon} />
            <Tooltip title={convertedPrice} arrow placement="top">
              <TooltipCaption
                style={{ display: "flex", alignItems: "flex-end", gap: 8 }}
              >
                <Typography variant="h4">
                  {convertedPrice.length > 4
                    ? parseFloat(convertedPrice).toFixed(4)
                    : convertedPrice}
                </Typography>
                <Typography variant="body2">(ETH)</Typography>
              </TooltipCaption>
            </Tooltip>
          </WalletDetailsRowPriceWrapper>
        </WalletDetailsRowWrapper>

        <WalletDetailsRowWrapper>
          <Typography>Converted balance:</Typography>
          <WalletDetailsRowPriceWrapper>
            <img src={dollarIcon} />
            <Typography variant="h4">
              {conversion.ethereum &&
                `${formatMoney(
                  parseFloat(convertedPrice) * conversion.ethereum.usd
                )}`}
            </Typography>
            <Typography variant="body2">(USD)</Typography>
            <Tooltip
              title={
                "This is a 15 minute cached converted price from Ethereum to USD."
              }
              arrow
              placement="top"
            >
              <InfoOutlined sx={{ fontSize: 12, color: "#94A8BE" }} />
            </Tooltip>
          </WalletDetailsRowPriceWrapper>
        </WalletDetailsRowWrapper>

        {/* <WalletDetailsRowWrapper>
          <Typography>Earned royalties:</Typography>
          <WalletDetailsRowPriceWrapper>
            <Typography>$35.03</Typography>
          </WalletDetailsRowPriceWrapper>
        </WalletDetailsRowWrapper> */}
      </WalletDetailsWrapper>

      {/* <WalletActionsWrapper>
        <Button variant="outlined">Add funds</Button>
        <Button variant="outlined">Connect external wallet</Button>
      </WalletActionsWrapper> */}
    </Box>
  );
};
