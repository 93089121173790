import { Box, Skeleton } from "@mui/material";
import * as React from "react";

export const LatestTransfersCardLoadingStencil = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton height="40px" />
    </Box>
  );
};
