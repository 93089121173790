import { gql, useMutation } from "@apollo/client";

export type GetCCUrlType = {
  id: string;
  url: string;
  type: string;
  provider: string;
  wallet_address: string;
};

export interface GetCCUrlMutationData {
  GenerateOnRamp: boolean;
}

const getCCUrlMutation = gql`
  mutation {
    GenerateOnRamp
  }
`;

export const useGetCCUrlMutation = () => {
  return useMutation(getCCUrlMutation);
};
