import * as React from "react";
import { AccountTreeOutlined } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";
import { Typography } from "@mui/material";
import CertificateTimeline from "library/routes/SingleCertificateView/components/certificateTimeline/certificateTimeline";

export const ViewCertificateProvenanceTable = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Provenance" icon={<AccountTreeOutlined />}>
        <CertificateTimeline
          certificateId={certificate.tokenId}
          originalMinter={"Creator"}
        />
      </Card>
    </div>
  );
};
