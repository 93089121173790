import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import * as React from "react";
import moment from "moment";

export const UserExchangesController = ({
  userExchanges,
}: {
  userExchanges: any[];
}) => {
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Latest order requests
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userExchanges.map((exchange) => (
              <TableRow>
                <TableCell>{_e(exchange.id)}</TableCell>
                <TableCell>{exchange.status}</TableCell>
                <TableCell align="right">
                  {moment(exchange.updated_at).fromNow()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
