import * as React from "react";
import FullPageTakeover from "library/modules/Takeover/TakeoverController";
import { FullPageAction } from "library/modules/FullPageAction/FullPageAction";
import { Button } from "@mui/material";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { useGetAddAppraisalSteps } from "./hooks/useGetAddAppraisalSteps";
import { AppraisalReasonType } from "./sharedTypes";
import { useAddAppraisalMutation } from "./data/useAddAppraisalMutation";

type AddAppraisalTakeoverControllerType = {
  onClose: () => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
};

export type AddAppraisalFormValuesType = {
  appraisedBy: string;
  appraisalDate: string;
  appraisalNotes?: string;
  appraisedValue?: number;
  appraisalReason: AppraisalReasonType;
  appraisalDocuments?: any;
};

export const AddAppraisalTakeoverController = ({
  onClose,
  onSuccess,
  certificate,
}: AddAppraisalTakeoverControllerType) => {
  const [transferFormValues, setTransferFormValues] =
    React.useState<AddAppraisalFormValuesType>({
      appraisedBy: "",
      appraisalDate: "",
      appraisalReason: AppraisalReasonType.OTHER,
    });

  const [addAppraisalMutation, { loading: addAppraisalMutationLoading }] =
    useAddAppraisalMutation();

  const handleAddAppraisal = () => {
    addAppraisalMutation({
      variables: {
        addCertificateAppraisalInputValues: {
          appraisedBy: transferFormValues.appraisedBy,
          certificateId: certificate._id,
          appraisalDate: Date.parse(transferFormValues.appraisalDate),
          appraisedValue: transferFormValues.appraisedValue,
          appraisalNotes: transferFormValues.appraisalNotes,
          appraisalReason: transferFormValues.appraisalReason,
          appraisalDocuments: transferFormValues.appraisalDocuments,
        },
      },
    })
      .then((response) => {
        console.log(response);
        onSuccess();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const updateFormValues = ({ name, value }: { name: string; value: any }) => {
    setTransferFormValues({
      ...transferFormValues,
      [name]: value,
    });
  };

  const { actions, mainContent, secondaryContent } = useGetAddAppraisalSteps({
    onClose,
    onSuccess,
    certificate,
    updateFormValues,
    transferFormValues,
    handleAddAppraisal,
  });

  return (
    <FullPageTakeover onClose={onClose}>
      <FullPageAction
        style={{ height: "calc(100% - 65px)" }}
        actions={actions}
        mainContent={mainContent}
        secondaryContent={secondaryContent}
      />
    </FullPageTakeover>
  );
};
