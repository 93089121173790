import { GetCertificateQueryType } from "library/data/getSingleCertificate";
import { DimensionTypes } from "../sharedTypes";

export const selectDefaultValues = ({
  template,
  editionId,
}: {
  template?: GetCertificateQueryType;
  editionId: string | null;
}) => {
  let defaultValues = {
    tags: [],
    notes: "",
    signed: false,
    framed: false,
    edition: editionId ? editionId : "",
    itemSku: "",
    category: "",
    itemName: "",
    signedBy: "",
    isEdition: editionId ? true : false,
    itemImage: [],
    artistName: "",
    dimensions: {
      h: 0,
      w: 0,
      d: 0,
      type: DimensionTypes.cm,
    },
    hasRoyalty: false,
    itemMedium: "",
    artworkYear: "",
    frameDetails: "",
    editionNumber: -1,
    royaltyPercent: 0,
    certTemplateId: 0,
    frameDimensions: {
      h: 0,
      w: 0,
      d: 0,
      type: DimensionTypes.cm,
    },
    itemDescription: "",
    paperSpecifications: "",
  };

  if (template) {
    const values = {
      ...template.additionalDetails,
      framed: template.additionalDetails.frameDimensions ? true : false,
      signed: template.additionalDetails.signedBy ? true : false,
      itemSku: template.itemSku,
      certTemplateId: template.certTemplateId,
      itemName: decodeURIComponent(template.itemName),
      signedBy: decodeURIComponent(template.additionalDetails.signedBy),
      artistName: decodeURIComponent(template.additionalDetails.artistName),
      hasRoyalty: template.additionalDetails.royaltyPercent > 0 ? true : false,
      itemDescription: decodeURIComponent(template.itemDescription),
    };

    defaultValues = { ...defaultValues, ...values };
  }

  return defaultValues;
};
