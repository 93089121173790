import Axios from "axios";
import moment from "moment";
import { getCurrentUserFromStorage } from "./helpers";

const prodEndpoint = "https://app.certseal.ca";
const devEndpoint = "https://localhost:3001";

export const ENDPOINT =
  process.env.NODE_ENV === "development" ? devEndpoint : prodEndpoint;
const IMXENDPOINT =
  process.env.NODE_ENV === "development"
    ? "https://api.sandbox.x.immutable.com"
    : "https://api.x.immutable.com";
const CONTRACT_ADDRESS =
  process.env.NODE_ENV === "development"
    ? "0x3188907577c5C8493cAF29b6FA2dF25fB2Fa751D"
    : "0x608F46C0B28C82c8d17bD5922Ec5bb820B0a63b8";

export const authHeader = () => {
  const user = getCurrentUserFromStorage();
  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { "x-access-token": user.accessToken };
  } else {
    return {};
  }
};

const apiClient = Axios.create({
  baseURL: ENDPOINT,
  headers: {
    ...authHeader(),
    "X-MVP-App-Header": "mvpApp",
  },
});

export const prefixUrl = ENDPOINT;
export const awsPrefix = "https://ewr1.vultrobjects.com";

export const postLoginRequest = ({ email, password }) => {
  return apiClient
    .post("/api/auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const getUpdatedUserInfo = () => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient
    .get("/api/user/get", { params: { userId: user.id } })
    .then((response) => {
      if (response.data.credits) {
        localStorage.setItem(
          "user",
          JSON.stringify({ ...user, ...response.data })
        );
      }

      return response.data;
    });
};

export const getUserTransactionHistory = () => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.get("/api/user/get/transactions", {
    params: { userId: user.id },
  });
};

export const postRegisterRequest = (registerDetails) => {
  return apiClient.post("/api/auth/signup", registerDetails);
};

export const authenticateWithGoogle = (token) => {
  return apiClient.post("/api/auth/verifyGoogleToken", { token });
};

export const imageUpload = (formData) => {
  return apiClient.post("/api/img/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createSimpleCOA = (formData) => {
  return apiClient.post("/api/cert/simple/create", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createAutographCOA = (formData) => {
  return apiClient.post("/api/cert/autograph/create", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getUserCertificates = ({ limit, page } = {}) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.get(`/api/certs/get/all`, {
    params: { userId: user.id, limit, page },
  });
};

export const getSingleUserCertificate = (certificateId) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.get(`/api/certs/get/single`, {
    params: { userId: user.id, certificateId },
  });
};

export const getSingleUserCertificateTimeline = (certificateId) => {
  return apiClient.get(`/api/certs/get/single/timeline`, {
    params: { certificateId },
  });
};

export const getunAuthedSingleUserCertificate = (certificateId) => {
  return apiClient.get(`/api/cert/verify`, { params: { certificateId } });
};

export const mintCertificate = (mintDetails, type = "simple") => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.post(`/api/cert/mint/${type}`, {
    ...mintDetails,
    userId: user.id,
    certType: type,
  });
};

export const getAdminRoute = () => {
  return apiClient.get("/api/admin");
};

export const getAdminGetUsers = (pageNum = 0) => {
  return apiClient.get("/api/admin/users/all", { params: { pageNum } });
};

export const updateCertificateStatus = (statusData) => {
  return apiClient.post("/api/admin/certificate/updateStatus", statusData);
};

export const postCreatePaymentIntent = (amount) => {
  return apiClient.post("/api/stripe/create-payment-intent", { amount });
};

export const postAddUserTransactionIntent = (transactionData) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.post("/api/user/transaction/add", {
    ...transactionData,
    userId: user.id,
  });
};

export const postUpdateUserCredits = (creditData) => {
  return apiClient.post("/api/admin/user/update/credits", creditData);
};

export const postActivateUser = (confirmationCode) => {
  return apiClient.get(`/api/auth/activate/`, { params: { confirmationCode } });
};

export const postResendEmailActivation = (email) => {
  return apiClient.post("/api/auth/resendActivation", { email });
};

export const postRequestPasswordRest = (email) => {
  return apiClient.post("/api/auth/passwordReset", { email });
};

export const postUpdateUserPassword = (params) => {
  return apiClient.post("/api/auth/resetUserPassword", params);
};

export const postToggleFavouriteCertificate = (certId) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }
  return apiClient.post("/api/cert/toggleFavourite", {
    certId,
    userId: user.id,
  });
};

export const postReportForCertificate = ({ certId, reason }) => {
  return apiClient.post("/api/cert/report", { certId, reason });
};

export const postTransferOwnership = ({ certId, email }) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }
  return apiClient.post("/api/cert/transfer", {
    email,
    certId,
    userId: user.id,
  });
};

export const acceptTransfer = ({ certId, recipient, token }) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.post("/api/cert/acceptTransfer", {
    token,
    certId,
    recipient,
    userId: user.id,
  });
};

export const uploadProfilePicture = (userProfilePicture) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  let formData = new FormData();
  formData.append("userId", user.id);
  formData.append("userProfilePicture", userProfilePicture);

  return apiClient.post("/api/user/uploadProfilePicture", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateProfileFields = (data) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  return apiClient.post("/api/user/updateProfile", {
    ...data,
    userId: user.id,
  });
};

export const getPublicProfile = (id) => {
  return apiClient.get(`/api/user/pubProfileDetails/${id}`);
};

export const getPublicCertsMinted = (id) => {
  return apiClient.get(`/api/certs/minted/${id}`);
};

export const getPublicCertsOwned = (id) => {
  return apiClient.get(`/api/certs/owned/${id}`);
};

export const getImxTokenInfoQuery = (id) => {
  return apiClient.get(`/api/certs/minted/info/${id}`);
};

export const getImxTokenTransactions = (id) => {
  return apiClient.get(`/api/certs/minted/transactions/${id}`);
};

export const getImxTokenActiveOrder = (id) => {
  return apiClient.get(`/api/certs/minted/activeOrder/${id}`);
};

export const uploadArtImages = ({ itemImage, certId }) => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  let formData = new FormData();
  formData.append("userId", user.id);
  formData.append("certId", certId);

  Object.values(itemImage).map((file, indx) => {
    formData.append("itemImage", file);
  });
  console.log(formData);

  return apiClient.post("/api/images/upload/art", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getEthUsdConversion = () => {
  const CONVERSION_CACHE_KEY = "conversion_cache";

  const getFromApi = async () => {
    try {
      const res = await apiClient.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      sessionStorage.setItem(
        CONVERSION_CACHE_KEY,
        JSON.stringify({
          data: res.data,
          timeStamp: moment(new Date()).add("15", "m").toDate(),
        })
      );

      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject("error");
    }
  };

  if (sessionStorage.getItem(CONVERSION_CACHE_KEY)) {
    const storageCache = sessionStorage.getItem(CONVERSION_CACHE_KEY);
    const storageJSON = storageCache ? JSON.parse(storageCache) : null;

    if (storageJSON.timeStamp && new Date(storageJSON.timeStamp) > new Date()) {
      return Promise.resolve(storageJSON.data);
    } else {
      return getFromApi();
    }
  } else {
    return getFromApi();
  }
};

export const getIMXLatestTransfersForTokenIds = async () => {
  const IMX_TRANSFERS_CACHE_KEY = "imx_transfers_cache";
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  const getFromApi = async () => {
    try {
      const imxApiClient = Axios.create({
        baseURL: IMXENDPOINT,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const options = {
        method: "GET",
        url: "/v1/transfers",
        params: {
          metadata: { Minter: [`${user.id}`] },
          order_by: "updated_at",
          token_address: CONTRACT_ADDRESS,
        },
        headers: { "Content-Type": "application/json" },
      };

      const res = await imxApiClient.request(options);

      sessionStorage.setItem(
        IMX_TRANSFERS_CACHE_KEY,
        JSON.stringify({
          data: res,
          timeStamp: moment(new Date()).add("15", "m").toDate(),
        })
      );

      return Promise.resolve(res);
    } catch (e) {
      Promise.reject("error");
    }
  };

  if (sessionStorage.getItem(IMX_TRANSFERS_CACHE_KEY)) {
    const storageCache = sessionStorage.getItem(IMX_TRANSFERS_CACHE_KEY);
    const storageJSON = storageCache ? JSON.parse(storageCache) : null;

    if (storageJSON.timeStamp && new Date(storageJSON.timeStamp) > new Date()) {
      return Promise.resolve(storageJSON.data);
    } else {
      return getFromApi();
    }
  } else {
    return getFromApi();
  }
};

export const getIMXLatestOrdersForTokenIds = async () => {
  const IMX_ORDERS_CACHE_KEY = "imx_orders_cache";

  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  const getFromApi = async () => {
    try {
      const imxApiClient = Axios.create({
        baseURL: IMXENDPOINT,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const options = {
        method: "GET",
        url: "/v1/orders",
        params: {
          sell_metadata: { Minter: [`${user.id}`] },
          order_by: "updated_at",
          status: "filled",
          sell_token_address: CONTRACT_ADDRESS,
        },
        headers: { "Content-Type": "application/json" },
      };

      const res = await imxApiClient.request(options);

      sessionStorage.setItem(
        IMX_ORDERS_CACHE_KEY,
        JSON.stringify({
          data: res,
          timeStamp: moment(new Date()).add("15", "m").toDate(),
        })
      );

      return Promise.resolve(res);
    } catch (e) {
      console.log("error", e);
      Promise.reject("error");
    }
  };

  if (sessionStorage.getItem(IMX_ORDERS_CACHE_KEY)) {
    const storageCache = sessionStorage.getItem(IMX_ORDERS_CACHE_KEY);
    const storageJSON = storageCache ? JSON.parse(storageCache) : null;

    if (storageJSON.timeStamp && new Date(storageJSON.timeStamp) > new Date()) {
      return Promise.resolve(storageJSON.data);
    } else {
      return getFromApi();
    }
  } else {
    return getFromApi();
  }
};

export const getIMXUserMintedAssets = async () => {
  const user = getCurrentUserFromStorage();

  if (!user || !user.id) {
    return Promise.reject();
  }

  try {
    const imxApiClient = Axios.create({
      baseURL: IMXENDPOINT,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const options = {
      method: "GET",
      url: "/v1/assets",
      params: {
        metadata: { Minter: [`${user.id}`] },
        order_by: "updated_at",
        collection: CONTRACT_ADDRESS,
      },
      headers: { "Content-Type": "application/json" },
    };

    const res = await imxApiClient.request(options);

    return Promise.resolve(res);
  } catch (e) {
    Promise.reject("error", e);
  }
};
