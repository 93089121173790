import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { useFlagsmith } from "flagsmith/react";
import { useGetCurrentUser, UserRoles } from "library/hooks/useGetCurrentUser";

export type RoutesRenderType = {
  path: string;
  exact: boolean;
  render: () => JSX.Element;
  unAuthRoute?: boolean;
};

export const RenderRoutes = ({
  routes,
  renderAuthenticatedRoutes,
}: {
  routes: RoutesRenderType[];
  renderAuthenticatedRoutes: (route: RoutesRenderType) => JSX.Element;
}) => {
  const currentUser = useGetCurrentUser();
  const flagsmith = useFlagsmith();

  if (currentUser) {
    flagsmith.identify(currentUser.id, {
      id: currentUser.id,
      isAdmin: !!currentUser?.roles?.includes(UserRoles.ADMIN),
      accountType: currentUser.accountType ?? "user",
    });
  }

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          children={
            route.unAuthRoute
              ? route.render()
              : renderAuthenticatedRoutes(route)
          }
        />
      ))}
    </Switch>
  );
};
