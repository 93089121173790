import * as React from "react";
import { Button, Typography, MenuItem } from "@mui/material";
import {
  TransferCertificateSelect,
  TransferCertificateMainContentWrapper,
} from "./TransferCertificate.elements";
import TransferCertificateImg from "library/../assets/img/transferCertificate1.png";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import {
  TransferFormValuesType,
  TransferType,
} from "../TransferCertificateTakeoverController";
import { postTransferOwnership } from "lib/api-client";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";

const validateTransferValues = (values: TransferFormValuesType) => {
  let errors = null;
  if (!values.email || values.email === "") {
    errors = "Please enter in the email for the new receipient";
  }
  if (!values.confirmEmail || values.confirmEmail === "") {
    errors = "Please confirm the email address";
  }
  if (values.email !== values.confirmEmail) {
    errors = "Please ensure the emails match";
  }
  return errors;
};

export const TransferCertificateStep2 = ({
  isBusy,
  onClose,
  prevStep,
  nextStep,
  setIsBusy,
  onSuccess,
  certificate,
  updateFormValues,
  transferFormValues,
}: {
  isBusy: boolean;
  onClose: () => void;
  prevStep: () => void;
  nextStep: () => void;
  setIsBusy: (value: boolean) => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
  updateFormValues: ({ name, value }: { name: string; value: any }) => void;
  transferFormValues: TransferFormValuesType;
}) => {
  const handleTransferOwnership = () => {
    const validateFormValues = validateTransferValues(transferFormValues);

    if (validateFormValues) {
      alert(validateFormValues);
      return;
    }

    setIsBusy(true);

    postTransferOwnership({
      email: transferFormValues.email,
      certId: certificate._id.toString(),
    })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.data?.message.includes("has active order")) {
            alert(
              "There is an active order. You cannot transfer certificates when there is an active listing. Please remove the listing from blockchain before transfering your certificate."
            );
            onClose?.();
            return;
          }
        }

        setIsBusy(false);
        alert("An error occured, please try again.");
      });
  };

  const mainContent = (
    <TransferCertificateMainContentWrapper>
      <Typography variant="h2">Details</Typography>
      <Typography>
        Are you sure you want to transfer this certificate? This process cannot
        be undone!
      </Typography>

      <div>
        <RowInputField
          fieldName="Transfer type"
          helperText="This helps us better display provenance"
          customInput={
            <TransferCertificateSelect
              id="bootstrap-input-type"
              value={transferFormValues.transferType}
              onChange={(evt) =>
                updateFormValues({
                  name: "transferType",
                  value: evt?.target?.value ?? "",
                })
              }
            >
              <option value={TransferType.TRANSFER}>Transfer</option>
              <option value={TransferType.SELL}>Sell</option>
              <option value={TransferType.DONATE}>Donate</option>
            </TransferCertificateSelect>
          }
        />

        <RowInputField
          inputProps={{ placeholder: "example@network.com" }}
          value={transferFormValues.email}
          onChange={(val: string) =>
            updateFormValues({ name: "email", value: val })
          }
          fieldName="Email address"
          helperText="Enter in the email address you want to send the certificate to. Please ensure you input the correct email."
        />

        <RowInputField
          inputProps={{ placeholder: "example@network.com" }}
          value={transferFormValues.confirmEmail}
          onChange={(val: string) =>
            updateFormValues({ name: "confirmEmail", value: val })
          }
          fieldName="Confirm email address"
          helperText="Please ensure you input the correct email."
        />
      </div>
    </TransferCertificateMainContentWrapper>
  );

  const secondaryContent = <img src={TransferCertificateImg} />;

  const actions = (
    <>
      <Button variant="outlined" onClick={prevStep}>
        ← Back
      </Button>
      <Button
        color="error"
        variant="contained"
        onClick={handleTransferOwnership}
        disabled={isBusy}
      >
        Transfer certificate ownership
      </Button>
    </>
  );

  return {
    actions,
    mainContent,
    secondaryContent,
  };
};
