import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import welcomeCardProfile from "assets/img/welcome_finish_profile.png";
import welcomeCardCertificate from "assets/img/welcome_create_cert.png";

const GettingStartedCardContainer = styled.div({
  gap: 24,
  height: "100%",
  border: "solid 1px #333",
  padding: 16,
  display: "flex",
  background: "white",
  flexDirection: "column",
});

const ItemImage = styled.img({
  maxHeight: 160,
});

export const GettingStartedCardCertificate = ({}) => {
  return (
    <GettingStartedCardContainer>
      <ItemImage src={welcomeCardCertificate} />
      <Typography variant="h3">Create a certificate</Typography>
      <Typography>
        Create a certificate for free to get started. Remember to take pictures
        of your artwork and gather any details required for your certificate of
        authenticity beforehand.
      </Typography>
      <Link
        to="/create/art"
        style={{ marginTop: "auto", alignSelf: "flex-end" }}
      >
        <Button variant="contained">Create a certificate</Button>
      </Link>
    </GettingStartedCardContainer>
  );
};

export const GettingStartedCardProfile = ({}) => {
  return (
    <GettingStartedCardContainer>
      <ItemImage src={welcomeCardProfile} />
      <Typography variant="h3">Complete your profile</Typography>
      <Typography>
        Complete your profile by adding a profile picture, artist statement,
        links to your personal website/social media.
      </Typography>
      <Link to="/profile" style={{ marginTop: "auto", alignSelf: "flex-end" }}>
        <Button variant="contained">Complete profile</Button>
      </Link>
    </GettingStartedCardContainer>
  );
};
