import { ThemeOptions } from "@mui/material/styles";

export const appTheme: ThemeOptions = {
  palette: {
    primary: {
      main: "#03071E",
      light: "#2c3045",
      dark: "#000",
    },
    secondary: {
      main: "#FFEE32",
      contrastText: "#333",
      dark: "#c8bc00",
      light: "#ffff6c",
    },
    background: {
      paper: "#ffffff",
      default: "#F1E4E8",
    },
    divider: "#F1E4E8",
    text: {
      secondary: "#57737a",
      primary: "#333",
      disabled: "#85BDBF",
    },
    error: {
      main: "#960200",
    },
    info: {
      main: "#2D82B7",
    },
    warning: {
      main: "#CE6C47",
    },
    success: {
      main: "#0CCE6B",
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: "#fff",
          color: "#333",
        },
      },
      defaultProps: {
        color: "inherit",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          padding: "8px 24px",
        },
        containedPrimary: {
          color: "#FFEE32",
        },
        outlinedSecondary: {
          color: "#c8bc00",
          borderColor: "#c8bc00",
        },
        textPrimary: {
          fontStyle: "italic",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          "&$checked": {
            color: "#03071E",
          },
        },
        colorSecondary: {
          "&$checked": {
            color: "#03071E",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlinedSecondary: {
          color: "#03071E",
          borderColor: "#c8bc00",
        },
        deleteIconOutlinedColorSecondary: {
          color: "#c8bc00",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          "&$checked": {
            color: "#03071E",
          },
        },
        colorSecondary: {
          "&$checked": {
            color: "#03071E",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        action: {
          color: "#FFEE32",
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "'Bitter', serif",
      fontWeight: 700,
      fontSize: 28,
    },
    h2: {
      fontFamily: "'Bitter', serif",
      fontWeight: 500,
      fontSize: 22,
    },
    h3: {
      fontFamily: "'Bitter', serif",
      fontWeight: 400,
      fontSize: 18,
    },
    h4: {
      fontFamily: "'Bitter', serif",
      fontWeight: 500,
      fontSize: 16,
    },
    h5: {
      fontFamily: "'Bitter', serif",
      fontWeight: 300,
      fontSize: 14,
    },
    h6: {
      fontFamily: "'Bitter', serif",
      fontWeight: 300,
      fontSize: 12,
    },
  },
};
