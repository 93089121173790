import * as React from "react";
import moment from "moment";
import { Card } from "library/components/atoms/Card/Card";
import { LatestTransfersCardLoadingStencil } from "../components/LatestTransfersCard";
import { Autorenew, Sell } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
import {
  LatestTransferListWrapper,
  LatestTransferListTitleWrapper,
  DashboardSectionTitleWrapper,
} from "../components/DashbaordRoute.elements";
import { TooltipCaption } from "library/components/atoms/TooltipCaption/TooltipCaption";
import { useGetAllTransfersInIMX } from "library/hooks/useGetAllTransfersInIMX";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";

export const LatestTransfersCardController = ({ id }: { id: string }) => {
  const user = useGetCurrentUser();

  if (user?.accountType === "individual") {
    // return early for individual accounts as they wont be able to create certificates
    return null;
  }
  const { isErrorTransfers, isLoadingTransfers, latestTransactions } =
    useGetAllTransfersInIMX({ userId: id });

  if (isLoadingTransfers) {
    return (
      <Card title="Latest transactions">
        <LatestTransfersCardLoadingStencil />
      </Card>
    );
  }

  if (isErrorTransfers) {
    return null;
  }

  if (!latestTransactions.length) {
    return (
      <Card title="Latest transactions">
        None of your certificates have had recent transfers or sales. This
        section will be updated to reflect and transfers or sales made in the
        future.
      </Card>
    );
  }

  return (
    <>
      <DashboardSectionTitleWrapper>
        <Typography variant="h2">Latest transactions</Typography>
      </DashboardSectionTitleWrapper>
      {latestTransactions
        .slice(0, 4)
        .map((latestTransaction: any, indx: number) => {
          const price = !!latestTransaction.order_id
            ? latestTransaction?.buy?.data?.quantity_with_fees
            : null;
          const weiethprice = price ? price / 1000000000000000000 : null;

          const RenderTitle = () => (
            <LatestTransferListTitleWrapper
              href={`/verify/${latestTransaction.certId}`}
              target="blank"
            >
              {latestTransaction.order_id ? (
                <Sell sx={{ fontSize: "16px" }} />
              ) : (
                <Autorenew sx={{ fontSize: "16px" }} />
              )}
            </LatestTransferListTitleWrapper>
          );

          return (
            <LatestTransferListWrapper key={indx}>
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
                <RenderTitle />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <LatestTransferListTitleWrapper
                    href={`/verify/${latestTransaction.certId}`}
                    target="blank"
                  >
                    <Typography variant="h4" fontWeight={400}>
                      {_e(latestTransaction?.certTitle)}
                    </Typography>
                  </LatestTransferListTitleWrapper>
                  <LatestTransferListTitleWrapper
                    href={`/verify/${latestTransaction.certId}`}
                    target="blank"
                  >
                    <Typography variant="body2" color="GrayText">
                      {latestTransaction.user}
                    </Typography>
                  </LatestTransferListTitleWrapper>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <Typography variant="caption" color="GrayText">
                  {moment(latestTransaction.timestamp).fromNow()}
                </Typography>
                {weiethprice && (
                  <Typography variant="caption">
                    sold for {weiethprice} ETH
                  </Typography>
                )}
              </Box>
            </LatestTransferListWrapper>
          );
        })}
    </>
  );
};
