import * as React from "react";
import { Button, Typography } from "@mui/material";
import { AddAppraisalMainContentWrapper } from "./AddAppraisal.elements";
import ProvenanceImgSrc from "library/../assets/img/provenance.png";

export const AddAppraisalStep1 = ({
  nextStep,
  certificateName,
}: {
  nextStep: () => void;
  certificateName: string;
}) => {
  const mainContent = (
    <AddAppraisalMainContentWrapper>
      <Typography variant="h2">Add new appraisal</Typography>
      <Typography variant="body1" fontSize={18}>
        Add a new appraisal for your artwork. Please ensure this information is
        true and correct.
      </Typography>
    </AddAppraisalMainContentWrapper>
  );

  const secondaryContent = <img src={ProvenanceImgSrc} />;

  const actions = (
    <Button variant="contained" onClick={nextStep}>
      Continue
    </Button>
  );

  return {
    actions,
    mainContent,
    secondaryContent,
  };
};
