import * as React from "react";
import {
  ChevronLeft,
  ChevronRight,
  Person,
  Brush,
  Museum,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  Input,
  OutlinedInput,
  Switch,
  Typography,
} from "@mui/material";
import { UserCookieType } from "library/hooks/useGetCurrentUser";
import {
  RoleButtonGroupItem,
  FormInputRowWrapper,
  FormInputWrapperWithLabel,
} from "../components/OnboardingSetup.elements";
import { useSetUserRoleMutation } from "../data/useSetUserRoleMutation";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import { useFinishOnboardingMutation } from "../data/useFinishOnboardingMutation";
import { OnboardSteps } from "../OnboardingSetupController";
import { UserFormType } from "../controllers/OnboardingSetupMainContentController";

enum UserRoleType {
  artist = "artist",
  company = "company",
  individual = "individual",
}

export const SelectOnboardingStep = ({
  step,
  user,
  userRole,
  userForm,
  setUserForm,
  setUserRole,
  prevStep,
  nextStep,
}: {
  user: UserCookieType | null;
  step: OnboardSteps;
  userRole?: UserRoleType;
  userForm: UserFormType;
  setUserForm: (userForm: UserFormType) => void;
  setUserRole: (userRole: UserRoleType) => void;
  nextStep: () => void;
  prevStep: () => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [setUserRoleMutation, { loading: setUserRoleLoading }] =
    useSetUserRoleMutation();

  const [
    finishOnboardingMutation,
    { loading: finishOnboardingMutationLoading },
  ] = useFinishOnboardingMutation();

  let content = null;
  let actions: React.ReactNode[] = [];

  const handleUpdateUserRole = () => {
    setUserRoleMutation({ variables: { setUserRoleInput: { userRole } } })
      .then(() => {
        nextStep();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const isFinishButtonDisabled =
    !userForm.firstName || !userForm.lastName || !userForm.dateOfBirth;

  const handleFinishOnboarding = () => {
    console.log(userForm);

    finishOnboardingMutation({
      variables: {
        finishOnboardingInput: {
          website: userForm.website,
          firstName: userForm.firstName,
          lastName: userForm.lastName,
          middleName: userForm.middleName,
          dateOfBirth: userForm.dateOfBirth,
          artistName: userForm.artistName,
          phoneNumber: userForm.phoneNumber,
          address: {
            city: userForm.city,
            postal: userForm.postal,
            address: userForm.address,
            country: userForm.country,
            province: userForm.province,
          },
          profileName: userForm.profileName,
          settings: {
            allowContact: userForm.displayEmailPublic,
            displayEmailPublic: userForm.allowUsersToContact,
          },
        },
      },
    })
      .then(() => {
        location.reload();
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  switch (step) {
    case OnboardSteps.START: {
      content = (
        <>
          <Typography variant="h1">Let's kickstart your journey!</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography>
              To begin, let's create your profile. Rest assured, we prioritize
              your privacy and do not disclose any of your information to third
              parties. You have complete control over which parts of your
              profile you want to keep private.
            </Typography>
          </Box>
        </>
      );

      actions = [
        <Button
          variant="contained"
          onClick={nextStep}
          endIcon={<ChevronRight />}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.SELECT_ROLE: {
      content = (
        <>
          <Typography variant="h1">How will you use CertSeal?</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography sx={{ mb: 2 }}>
              To get started first tell us about your role
            </Typography>

            <RoleButtonGroupItem
              role="button"
              active={userRole === UserRoleType.individual}
              onClick={() => setUserRole(UserRoleType.individual)}
            >
              <Person />
              <div>
                <Typography variant="h6">
                  I am a collector / Investor
                </Typography>
                <Typography>
                  I don't need to create certificates, I just collect
                </Typography>
              </div>
            </RoleButtonGroupItem>

            <RoleButtonGroupItem
              role="button"
              active={userRole === UserRoleType.artist}
              onClick={() => setUserRole(UserRoleType.artist)}
            >
              <Brush />
              <div>
                <Typography variant="h6">I am an Artist</Typography>
                <Typography>
                  I plan to create certificates for my own artwork
                </Typography>
              </div>
            </RoleButtonGroupItem>

            <RoleButtonGroupItem
              role="button"
              active={userRole === UserRoleType.company}
              style={{
                opacity: "0.5",
                cursor: "not-allowed",
              }}
            >
              <Museum />
              <div>
                <Typography variant="h6">
                  I am an Agent / Gallery Manager
                </Typography>
                <Typography>
                  I plan to create certificates for artists on behalf of my
                  organization
                </Typography>
                <Typography variant="caption">
                  Currently unavailable.
                </Typography>
              </div>
            </RoleButtonGroupItem>
          </Box>
        </>
      );
      actions = [
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>,
        <Button
          variant="contained"
          onClick={handleUpdateUserRole}
          disabled={userRole === undefined || isLoading || setUserRoleLoading}
          endIcon={<ChevronRight />}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.ARTIST_ACKNOWLEDGE: {
      content = (
        <>
          <Typography variant="h1">Welcome</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography>
              Welcome to CertSeal, exclusively built for artists like you! To
              comply with legal KYC requirements, we kindly request your
              cooperation in providing some personal information. By completing
              the KYC process, you contribute to a transparent and trustworthy
              community for artists to thrive.
            </Typography>
          </Box>
        </>
      );

      actions = [
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>,
        <Button
          variant="contained"
          onClick={nextStep}
          endIcon={<ChevronRight />}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.PROFILE_INFORMATION: {
      const maxDateValue = new Date().toISOString().split("T")[0];
      content = (
        <>
          <Typography variant="h1">Profile information</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography>Tell us a little about yourself</Typography>

            <Box>
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="firstName" required>
                    First name
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="firstName"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Jane"
                    value={userForm.firstName}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        firstName: e.currentTarget.value,
                      })
                    }
                  />
                  <FormHelperText>Why do we need this?</FormHelperText>
                </FormInputWrapperWithLabel>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="lastName" required>
                    Last name
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="lastName"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Smith"
                    value={userForm.lastName}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        lastName: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Middle name */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="middleName">
                    Middle name <small>(optional)</small>
                  </FormLabel>
                  <OutlinedInput
                    id="middleName"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Jane"
                    value={userForm.middleName}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        middleName: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Date of birth */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="dateOfBirth" required>
                    Date of birth
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="dateOfBirth"
                    type={"date"}
                    size="small"
                    fullWidth
                    inputProps={{ max: maxDateValue }}
                    placeholder="Jane"
                    value={userForm.dateOfBirth}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        dateOfBirth: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Artist name */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="artistName">
                    Artist name <small>(optional)</small>
                  </FormLabel>
                  <OutlinedInput
                    id="artistName"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Beeple"
                    value={userForm.artistName}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        artistName: e.currentTarget.value,
                      })
                    }
                  />
                  <FormHelperText>
                    Do you have an artist name you go by?
                  </FormHelperText>
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>
            </Box>
          </Box>
        </>
      );

      actions = [
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>,
        <Button
          variant="contained"
          onClick={nextStep}
          endIcon={<ChevronRight />}
          disabled={isFinishButtonDisabled}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.CONTACT_INFORMATION: {
      content = (
        <>
          <Typography variant="h1">Contact information</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography>How will we contact you</Typography>

            <Box>
              {/* Phone number */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="phoneNumber">
                    Phone number
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="phoneNumber"
                    type={"phone"}
                    size="small"
                    fullWidth
                    placeholder="123 456 7890"
                    value={userForm.phoneNumber}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        phoneNumber: e.currentTarget.value,
                      })
                    }
                  />
                  <FormHelperText>
                    We will use this number for multi-factor authentication
                  </FormHelperText>
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Address */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="address">
                    Address
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="address"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="123 king street N."
                    value={userForm.address}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        address: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Country */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="country">
                    Country
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="country"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Canada"
                    value={userForm.country}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        country: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="province">
                    Province
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="province"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Ontario"
                    value={userForm.province}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        province: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* Province City */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="city">
                    City
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="city"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="Toronto"
                    value={userForm.city}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        city: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
                <FormInputWrapperWithLabel>
                  <FormLabel required htmlFor="postal">
                    Postal
                  </FormLabel>
                  <OutlinedInput
                    required
                    id="postal"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="N2N 1N1"
                    value={userForm.postal}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        postal: e.currentTarget.value,
                      })
                    }
                  />
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>
            </Box>
          </Box>
        </>
      );

      actions = [
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>,
        <Button
          variant="contained"
          onClick={nextStep}
          endIcon={<ChevronRight />}
          disabled={isFinishButtonDisabled}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.PROFILE_SETTINGS: {
      content = (
        <>
          <Typography variant="h1">Profile settings</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography>Confirm your profile options</Typography>

            <Box>
              {/* Profile name */}
              <FormInputRowWrapper>
                <FormInputWrapperWithLabel>
                  <FormLabel htmlFor="profileName">
                    Website <small>(optional)</small>
                  </FormLabel>
                  <OutlinedInput
                    id="profileName"
                    type={"text"}
                    size="small"
                    fullWidth
                    placeholder="https://www.beeple-crap.com/"
                    value={userForm.website}
                    onChange={(e: any) =>
                      setUserForm({
                        ...userForm,
                        website: e.currentTarget.value,
                      })
                    }
                  />
                  <FormHelperText>
                    This will show up publicly in your profile
                  </FormHelperText>
                </FormInputWrapperWithLabel>
              </FormInputRowWrapper>

              {/* email public */}
              <FormInputRowWrapper>
                <FormLabel required htmlFor="profileName">
                  Display email address publicly
                </FormLabel>
                <Switch
                  onChange={(evt) =>
                    setUserForm({
                      ...userForm,
                      displayEmailPublic: evt.target.checked,
                    })
                  }
                />
              </FormInputRowWrapper>

              {/* email public */}
              <FormInputRowWrapper>
                <FormLabel required htmlFor="profileName">
                  Allow users to contact me through CertSeal
                </FormLabel>
                <Switch
                  onChange={(evt) =>
                    setUserForm({
                      ...userForm,
                      allowUsersToContact: evt.target.checked,
                    })
                  }
                />
              </FormInputRowWrapper>
            </Box>
          </Box>
        </>
      );

      actions = [
        <Button
          variant="outlined"
          onClick={prevStep}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>,
        <Button
          variant="contained"
          onClick={nextStep}
          endIcon={<ChevronRight />}
          disabled={isFinishButtonDisabled}
        >
          Continue
        </Button>,
      ];
      break;
    }

    case OnboardSteps.DONE: {
      content = (
        <>
          <Typography variant="h4">All done!</Typography>
          <Box
            sx={{
              mt: 2,
              gap: 2,
              display: "flex",
              flexDirection: "column",
              fontSize: "24px",
            }}
          >
            <Typography sx={{ fontSize: "24px", fontWeight: "lighter" }}>
              Thank you for taking the time to set up your profile. You can now
              create your first edition or certificate!
            </Typography>
          </Box>
        </>
      );

      actions = [
        <Button variant="contained" onClick={handleFinishOnboarding}>
          Done
        </Button>,
      ];
      break;
    }

    default:
      throw new Error("unkown step");
  }

  return {
    content,
    actions,
  };
};
