import type { ValidationError } from "library/shared/sharedTypes";

export const validateFormFields = (formValues: any) => {
  const validationErrors: ValidationError[] = [];
  // item name
  if (formValues.itemName === "") {
    validationErrors.push({
      key: "itemName",
      message: "Item name is required",
    });
  }

  // images
  if (!formValues?.itemImage.length || formValues?.itemImage.length > 5) {
    validationErrors.push({
      key: "itemImage",
      message: "Please add 1-5 images",
    });
  }

  // artist name
  if (formValues.artistName === "") {
    validationErrors.push({
      key: "artistName",
      message: "Artist name is required",
    });
  }

  // item medium
  if (formValues.itemMedium === "") {
    validationErrors.push({
      key: "itemMedium",
      message: "Item medium is required",
    });
  }

  // year complete
  if (formValues.artworkYear === "") {
    validationErrors.push({
      key: "artworkYear",
      message: "Year completed is required",
    });
  }

  // category
  if (formValues.category === "" || !formValues.category) {
    validationErrors.push({
      key: "category",
      message: "Year completed is required",
    });
  }

  // dimensions
  if (
    isNaN(formValues.dimensions.w) ||
    formValues.dimensions.w < 0 ||
    isNaN(formValues.dimensions.d) ||
    formValues.dimensions.d < 0 ||
    isNaN(formValues.dimensions.h) ||
    formValues.dimensions.h < 0
  ) {
    validationErrors.push({
      key: "dimensions",
      message: "Please enter a valid dimension value",
    });
  }

  // frame dimensions
  if (formValues.framed) {
    if (
      isNaN(formValues.frameDimensions.w) ||
      formValues.frameDimensions.w < 0 ||
      isNaN(formValues.frameDimensions.d) ||
      formValues.frameDimensions.d < 0 ||
      isNaN(formValues.frameDimensions.h) ||
      formValues.frameDimensions.h < 0
    ) {
      validationErrors.push({
        key: "frameDimensions",
        message: "Please enter a valid dimension value",
      });
    }
  }

  // signed
  if (formValues.signed) {
    if (formValues.signedBy === "") {
      validationErrors.push({
        key: "signedBy",
        message: "Signed by is required",
      });
    }
  }

  // Editions
  if (formValues.isEdition) {
    if (!formValues.edition || formValues.edition === "") {
      validationErrors.push({
        key: "editionName",
        message: "Edition is required",
      });
    }

    if (formValues.editionNumber < 0 || isNaN(formValues.editionNumber)) {
      validationErrors.push({
        key: "editionNumber",
        message: "Edition number is required",
      });
    }
  }

  // Royalties
  if (formValues.hasRoyalty) {
    if (
      formValues.royaltyPercent < 0 ||
      formValues.royaltyPercent === "" ||
      isNaN(formValues.royaltyPercent)
    ) {
      validationErrors.push({
        key: "royaltyPercent",
        message: "Please enter a valid royalty percentage between 0 - 5%",
      });
    }
  }

  return validationErrors;
};
