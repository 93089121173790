import { gql, useQuery } from "@apollo/client";
import { UnknownObject } from "library/shared/sharedTypes";

export type ActiveOrdersForCertInputValues = {
  tokenId: number;
  certificateId: string;
};

export type ActiveOrdersForCertWeb2Type = {
  userId: string;
  amount: number;
  status: string;
  currency: string;
  imxOrderId: number;
  dateCreated: Date;
  certificateId: string;
};

export type ActiveOrdersForCertType = {
  web2: ActiveOrdersForCertWeb2Type | null;
  web3: UnknownObject;
};

const ACTIVE_ORDERS_FOR_CERT = gql`
  query ActiveOrdersForCert(
    $activeOrderInputValues: ActiveOrdersForCertInputValues
  ) {
    ActiveOrdersForCert(activeOrderInputValues: $activeOrderInputValues) {
      web2 {
        status
        userId
        amount
        currency
        imxOrderId
        dateCreated
        certificateId
      }
      web3
    }
  }
`;

export const useActiveOrdersForCertQuery = ({
  tokenId,
  certificateId,
}: ActiveOrdersForCertInputValues) => {
  const query = useQuery(ACTIVE_ORDERS_FOR_CERT, {
    variables: {
      activeOrderInputValues: {
        tokenId,
        certificateId,
      },
    },
  });

  return {
    ...query,
    activeOrders: query?.data?.ActiveOrdersForCert,
  };
};
