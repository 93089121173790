import styled from "@emotion/styled";
import { Typography, Chip } from "@mui/material";
import { url } from "inspector";
import { _e } from "library/helpers/displayEscapedText";
import { Link } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";

const CertificateCardContainer = styled.div({
  width: "100%",
  border: "solid 1px black",
  boxSizing: "content-box",
  background: "white",
});

const CertificateCardContentWrapper = styled.div({
  gap: 16,
  display: "flex",
  padding: 8,
  overflow: "hidden",
  whiteSpace: "nowrap",
  flexDirection: "column",
});

const CertificateCardImageWrapper = styled.div<{ img: string }>(({ img }) => ({
  width: "100%",
  height: 200,
  background: `url(${img})`,
  backgroundSize: "cover",
  backgroundPosition: "50% 50%",
}));

const DateContainer = styled.div({
  gap: 4,
  color: "#8A8A8A",
  display: "flex",
  alignItems: "center",
});

export const CertificateCard = ({
  img,
  date,
  link,
  title,
  status,
}: {
  img: string;
  date: string;
  link: string;
  title: string;
  status: string;
}) => {
  return (
    <CertificateCardContainer>
      {/* img */}
      <Link to={link}>
        <CertificateCardImageWrapper img={img} />
      </Link>

      {/* content */}
      <CertificateCardContentWrapper>
        <div>
          <Link to={link}>
            <Typography variant="h3" overflow="hidden" textOverflow="ellipsis">
              {_e(title)}
            </Typography>
          </Link>
          <DateContainer>
            <EventIcon sx={{ fontSize: 12 }} />
            <Typography variant="caption" lineHeight="1em">
              {date}
            </Typography>
          </DateContainer>
        </div>
        <div>
          <Chip
            size="small"
            variant="outlined"
            color={status === "minted" ? "default" : "info"}
            label={status}
          />
        </div>
      </CertificateCardContentWrapper>
    </CertificateCardContainer>
  );
};
