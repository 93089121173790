import * as React from "react";
import { Button, Typography } from "@mui/material";
import { TransferCertificateMainContentWrapper } from "./TransferCertificate.elements";
import TransferCertificateImg from "library/../assets/img/transferCertificate2.png";

export const TransferCertificateStep1 = ({
  nextStep,
  certificateName,
}: {
  nextStep: () => void;
  certificateName: string;
}) => {
  const mainContent = (
    <TransferCertificateMainContentWrapper>
      <Typography variant="h2">Transfer Certificate</Typography>
      <Typography variant="body1" fontSize={18}>
        Transfering your certificate of authenticity is a happy moment,
        congrats! Your presence will forvever be recorded in blockchain!
        Wherever this certificate ends up, you will be part of its recorded
        history, its provenance.
      </Typography>
    </TransferCertificateMainContentWrapper>
  );

  const secondaryContent = <img src={TransferCertificateImg} />;

  const actions = (
    <Button variant="contained" onClick={nextStep}>
      Continue
    </Button>
  );

  return {
    actions,
    mainContent,
    secondaryContent,
  };
};
