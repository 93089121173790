import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Chip } from "@mui/material";

export default function CertificateCard({ img, title, desc, onClick, status }) {
  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={img}
          alt="certificate image"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {desc}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            marginTop: "auto",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Chip
            size="small"
            label={status}
            color={status === "minted" ? "success" : "default"}
          />
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
