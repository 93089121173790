import * as React from "react";

export type useToggleReturn = {
  value: boolean;
  setToggleOn: () => void;
  setToggleOff: () => void;
};

export const useToggle = (initialValue?: boolean): useToggleReturn => {
  const [value, setValue] = React.useState(!!initialValue);

  const setToggleOn = () => setValue(true);
  const setToggleOff = () => setValue(false);

  return {
    value,
    setToggleOn,
    setToggleOff,
  };
};
