import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import { UnknownObject } from "library/shared/sharedTypes";

enum PossibleDialogCloseReasons {
  backdrop = "backdropClick",
  escapeKey = "escapeKeyDown",
}

export const ViewCertificateCreateOrderConfirmation = ({
  open,
  isBusy,
  onClose,
  onConfirmOk,
}: {
  open: boolean;
  isBusy: boolean;
  onClose: () => void;
  onConfirmOk: () => void;
}) => {
  const handleOnClose = (
    event: UnknownObject | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: string
  ) => {
    if (isBusy || reason === PossibleDialogCloseReasons.backdrop) {
      return;
    }
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ alignItems: "center", display: "flex", gap: 1 }}
        id="alert-dialog-title"
      >
        <InfoOutlined />
        {isBusy ? "Please wait" : "Acknowledge before listing"}
      </DialogTitle>
      <DialogContent>
        {isBusy ? (
          <DialogContentText id="alert-dialog-description" sx={{ m: 2 }}>
            This could take a while as we complete the Blockchain transaction.
            Writting to the Blockchain is slow and takes time, please be
            patient, do not reload or navigate away from the page.
          </DialogContentText>
        ) : (
          <>
            <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
              Creating an order will list your certificate for sale on dozens of
              marketplaces in the Blockchain ecosystem. It's important to
              understand the implications of this. In some cases a buyer could
              purchase the certificate directly through the blockchain and not
              via CertSeal. This means that the buyer would be anonymous and
              neither CertSeal nor yourself, the owner, will have a way to
              communicate with the buyer.
            </DialogContentText>

            <DialogContentText id="alert-dialog-description">
              In this event, the certificate ownership will be disabled until
              the buyer creates an account with CertSeal and verifies their
              ownership.
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isBusy} onClick={handleOnClose}>
          Cancel
        </Button>
        <Button disabled={isBusy} onClick={onConfirmOk} autoFocus>
          {isBusy ? <CircularProgress size="1rem" /> : "I understand"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
