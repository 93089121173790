import { gql, useQuery } from "@apollo/client";
import { CertificateStatus, CertType } from "library/shared/sharedTypes";

type SingleCertificateMinterArtistType = {
  _id: string;
  bio: string;
  social: {
    twitter?: string;
    facebook?: string;
    instagram?: string;
  };
  username: string;
};

type SingleCertificateEditionType = {
  _id: string;
  editionName: string;
  editionHidden: boolean;
};

export type GetSingleCertificateTypeForVerify = {
  _id: string;
  status: CertificateStatus;
  locked?: boolean;
  minter: SingleCertificateMinterArtistType;
  itemSku?: string;
  tokenId: number;
  certType: CertType;
  itemName: string;
  isMinted: boolean;
  certImage?: any;
  favourite?: boolean;
  itemImage?: any;
  isRevoked?: boolean;
  dateMinted?: Date;
  dateCreated?: Date;
  statementOA?: string;
  ipfsMetaUrl?: any;
  currentOwner?: string;
  blockchainData?: any;
  certTemplateId?: number;
  itemDescription?: string;
  additionalDetails?: any;
};

type SingleCertificateQueryData = {
  getSingleCertificate: GetSingleCertificateTypeForVerify;
};

type SingleCertificateQueryVariables = {
  certificateId: string;
};

export const getSingleCertificate = gql`
  query GetSingleCertificate($certificateId: ID!) {
    getSingleCertificate(id: $certificateId) {
      _id
      status
      minter {
        _id
        bio
        social {
          twitter
          facebook
          instagram
        }
        username
      }
      itemSku
      tokenId
      certType
      itemName
      isMinted
      itemImage
      dateCreated
      ipfsMetaUrl
      certTemplateId
      blockchainData
      itemDescription
      additionalDetails
    }
  }
`;

export const useSingleCertificateForVerifyQuery = ({ id }: { id: string }) => {
  const result = useQuery<
    SingleCertificateQueryData,
    SingleCertificateQueryVariables
  >(getSingleCertificate, {
    fetchPolicy: "cache-and-network",
    variables: { certificateId: id },
  });

  return { ...result, certificate: result?.data?.getSingleCertificate };
};
