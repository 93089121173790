import * as React from "react";
import styled from "@emotion/styled";
import galleryImg from "../../../../../../assets/img/galley4.jpg";
import { Typography, Box, IconButton, Avatar } from "@mui/material";
import {
  LanguageRounded,
  FacebookRounded,
  Instagram,
  Twitter,
} from "@mui/icons-material";
import type { UserProfileDetailsQueryDataType } from "../sharedTypes";

const ProfileHeaderContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "#fff",
  borderBottom: "solid 2px #ddd",
});

const ProfileContentContainer = styled.div({
  maxWidth: 1200,
  margin: "0 auto",
});

const ProfileHeaderImageContainer = styled.div({
  height: 150,
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  marginBottom: "calc(-128px / 2)",
});

const ProfileImageContainer = styled.div({
  width: 128,
  height: 128,
  borderRadius: "50%",
  border: "solid 3px #fff",
  overflow: "hidden",
  background: "#fff",
  marginBottom: 24,
});

const ProfileUserDescription = styled.div({
  maxWidth: 700,
  marginBottom: 12,
});

const UserSocialIcons = (profileDetails: UserProfileDetailsQueryDataType) => (
  <Box sx={{ display: "flex", mb: 2 }}>
    {profileDetails.website && (
      <IconButton
        target="_blank"
        href={profileDetails.website.replace(/(\&amp;#x2F;)|(\&#x2F;)/g, "/")}
      >
        <LanguageRounded />
      </IconButton>
    )}
    {profileDetails.facebook && (
      <IconButton
        target="_blank"
        href={`https://facebook.com/${profileDetails.facebook}`}
      >
        <FacebookRounded />
      </IconButton>
    )}
    {profileDetails.instagram && (
      <IconButton
        target="_blank"
        href={`https://instagram.com/${profileDetails.instagram}`}
      >
        <Instagram />
      </IconButton>
    )}
    {profileDetails.twitter && (
      <IconButton
        target="_blank"
        href={`https://twitter.com/${profileDetails.twitter}`}
      >
        <Twitter />
      </IconButton>
    )}
  </Box>
);

export const ProfileHeader = ({
  profileDetails,
}: {
  profileDetails: UserProfileDetailsQueryDataType;
}) => {
  return (
    <ProfileHeaderContainer>
      <ProfileHeaderImageContainer>
        <img src={galleryImg} />
      </ProfileHeaderImageContainer>
      <ProfileContentContainer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <ProfileImageContainer>
          <Avatar
            sx={{ width: 128, height: 128 }}
            variant="rounded"
            src={`https://ewr1.vultrobjects.com/cs-profilepictures/${profileDetails._id}`}
          />
        </ProfileImageContainer>
        <Typography variant="h4">{profileDetails.firstName}</Typography>
        <Typography variant="subtitle1">New York, NY</Typography>
        <ProfileUserDescription>
          <Typography variant="body2" sx={{ mt: 2 }} align="center">
            {profileDetails?.bio}
          </Typography>
        </ProfileUserDescription>
        {UserSocialIcons(profileDetails)}
      </ProfileContentContainer>
    </ProfileHeaderContainer>
  );
};

export const ProfileContent = styled.div({
  maxWidth: 1200,
  margin: "24px auto",
});

export const ProfileContentActions = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "42px 0",
});
