import * as React from "react";
import { Breadcrumbs, Button, Grid, Typography } from "@mui/material";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { RouteHeader } from "library/components/molecules/RouteHeader/RouteHeader";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";
import { useQueryParams } from "library/hooks/useQueryParams";
import { CertificatesFiltersController } from "./controllers/CertificatesFiltersController";
import { useGetMyCertificatesQuery } from "./data/getMyCertificates";
import { MyCertificatesTableController } from "./controllers/MyCertificatesTableController";

export const MyCertificatesRevampController = () => {
  const user = useGetCurrentUser() as UserCookieType;
  const history = useHistory();
  const { currentParams } = useQueryParams({
    pathname: "/certificates",
  });
  const [page, setPage] = React.useState(0);

  const {
    count: numberOfCerts,
    error: getMyCertificatesError,
    loading: isCertificatesLoading,
    refetch: refetchCertificates,
    certificates: myCertificates,
  } = useGetMyCertificatesQuery({
    id: user.id,
    page,
    search: currentParams.search,
    status: currentParams.status,
    createdOnly: !!currentParams.createdOnly,
  });

  React.useEffect(() => {
    refetchCertificates();
  }, [page, currentParams.status]);

  if (!user) {
    return <p>error, if this persists, please contact an admin</p>;
  }

  if (getMyCertificatesError) {
    return <p>error, if this persists, please contact an admin</p>;
  }

  const canCreateCertificates =
    user.accountType === "artist" || user.accountType === "company";

  const headerAction = canCreateCertificates && (
    <Button variant="contained" onClick={() => history.push("/create/art")}>
      Create
    </Button>
  );

  return (
    <>
      <Breadcrumbs
        sx={{ mb: 1, fontSize: "12px" }}
        separator="›"
        aria-label="breadcrumb"
      >
        <RouterLink to="/">Dashboard</RouterLink>
        <Typography fontSize="12px" color="text.primary">
          Certificates
        </Typography>
      </Breadcrumbs>
      <RouteHeader title="Certificates" actions={headerAction} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={4}>
          {/* Table filters */}
          <CertificatesFiltersController />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          {!!currentParams.createdOnly && (
            <Typography variant="h5">Certificates that you created</Typography>
          )}
          <MyCertificatesTableController
            page={page}
            setPage={setPage}
            numberOfCerts={numberOfCerts}
            certificates={myCertificates}
            isLoading={isCertificatesLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
