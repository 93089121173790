import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { CssBaseline, Box, Toolbar, Container } from "@mui/material";

import LogoSvg from "../../components/atoms/LogoSvg";
import Copyright from "../../components/atoms/Copyright-unauth";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const PageLayoutUnauthFullwidth = ({ children }) => {
  const [open, setOpen] = React.useState(true);
  const [userMenuAnchor, set_userMenuAnchor] = React.useState(null);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Box sx={{ width: "150px", marginRight: "auto", marginLeft: "12px" }}>
            <LogoSvg href={"https://certseal.ca/get-a-quote"} />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <div>
          {children}
          <Copyright sx={{ pt: 4 }} />
        </div>
      </Box>
    </Box>
  );
};

export default PageLayoutUnauthFullwidth;
