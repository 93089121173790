import * as React from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useToggle } from "library/hooks/useToggle";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";
import { _e } from "library/helpers/displayEscapedText";
import { RouteHeader } from "library/components/molecules/RouteHeader/RouteHeader";
import { WalletPageController } from "./controllers/WalletPageController";
import { useGetAllTransfersInIMX } from "library/hooks/useGetAllTransfersInIMX";
import { AddFundsModal } from "./components/AddFundsModal";
import HeaderMoreDropdown from "./components/HeaderMoreDropdown";
import { useGetCCUrlMutation } from "./data/getCCurl";
import { OnRampTakeover } from "./controllers/OnRampTakeover";
import { ImmutableXClient, ImmutableMethodParams } from "@imtbl/imx-sdk";
import { getImxClient } from "lib/imx/link/imxLinkController";
import { useGetOwnerBalances } from "library/features/UserWalletHeaderDropdown/data/useGetUserBalances";
import { ethers } from "ethers";

export const WalletRouteController = () => {
  const user = useGetCurrentUser() as UserCookieType;

  const {
    value: showAddFundsModal,
    setToggleOn: showAddFundsModalOn,
    setToggleOff: showAddFundsModalOff,
  } = useToggle();

  const {
    value: showOnRampTakeover,
    setToggleOn: showOnRampTakeoverOn,
    setToggleOff: showOnRampTakeoverOff,
  } = useToggle();

  const [onRampUrl, setOnRampUrl] = React.useState<{
    id: string;
    url: string;
  }>();
  const [imxClient, setImxClient] = React.useState<ImmutableXClient>();
  const [userExchanges, setUserExchanges] = React.useState<any>();

  const { enqueueSnackbar } = useSnackbar();

  const { balances, loading: balancesLoading } = useGetOwnerBalances();
  const [useGenerateCCUrl, { loading: isCCUrlLoading }] = useGetCCUrlMutation();

  const { createdTokenIds, isLoadingTransfers, latestTransactions } =
    useGetAllTransfersInIMX({ userId: user.id });

  const handleOnAddFunds = React.useCallback(() => {
    showAddFundsModalOn();
  }, []);

  const l1Balance = React.useMemo(() => {
    const balance = balances?.l1Balance?.hex ?? 0;
    return ethers.utils.formatEther(balance);
  }, [balances]);

  React.useEffect(() => {
    getImxClient().then(({ client }) => {
      setImxClient(client);
      client
        .getExchanges({
          order_by: "created_at",
          direction: ImmutableMethodParams.ImmutableSortOrder.desc,
          page_size: 10,
          wallet_address: user.wallet.pub,
        })
        .then(({ result }) => setUserExchanges(result));
      client
        .getOrdersV3({
          order_by: "created_at",
          direction: ImmutableMethodParams.ImmutableSortOrder.desc,
          page_size: 10,
          sell_metadata: JSON.stringify({ User: ["61476dc31b8d162d98955b06"] }),
        })
        .then(({ result }) => console.log(result));
    });
  }, []);

  const handleOnCreditCard = React.useCallback(() => {
    useGenerateCCUrl()
      .then(({ data }) => {
        if (!data) {
          showAddFundsModalOff();
          enqueueSnackbar(
            "Something went wrong, please try again or contact an admin for support.",
            { variant: "error" }
          );
          return;
        }
        showAddFundsModalOff();

        const id = data.GenerateOnRamp.id;
        const url = data.GenerateOnRamp.url;
        setOnRampUrl({ url, id });
        showOnRampTakeoverOn();
      })
      .catch((err) => {
        console.log(err);
        showAddFundsModalOff();
        enqueueSnackbar(
          "Something went wrong, please try again or contact an admin for support.",
          { variant: "error" }
        );
      });
  }, []);

  return (
    <Box sx={{ maxWidth: 1400, m: "0 auto 42px" }}>
      <Breadcrumbs
        sx={{ mb: 1, fontSize: "12px" }}
        separator="›"
        aria-label="breadcrumb"
      >
        <RouterLink to="/">Dashboard</RouterLink>
        <RouterLink to="/profile">Profile</RouterLink>
        <Typography fontSize="12px" color="text.primary">
          Wallet
        </Typography>
      </Breadcrumbs>

      <RouteHeader
        title={"Wallet"}
        actions={
          <>
            <Button
              key="single button"
              variant="contained"
              onClick={handleOnAddFunds}
            >
              Fund wallet
            </Button>
            <HeaderMoreDropdown />
          </>
        }
      />

      <WalletPageController
        user={user}
        balances={balances}
        userExchanges={userExchanges}
        balancesLoading={balancesLoading}
        createdTokenIds={createdTokenIds}
        latestTransactions={latestTransactions}
        isLoadingTransfers={isLoadingTransfers}
      />

      {showAddFundsModal && (
        <AddFundsModal
          open={showAddFundsModal}
          loading={!!isCCUrlLoading}
          onClose={showAddFundsModalOff}
          l1Balance={l1Balance}
          onConfirmOk={showAddFundsModalOff}
          onCreditCard={handleOnCreditCard}
          walletAddress={user.wallet.pub}
        />
      )}

      {showOnRampTakeover && onRampUrl?.url && onRampUrl?.id && (
        <OnRampTakeover
          url={onRampUrl.url}
          onFail={showOnRampTakeoverOff}
          onClose={showOnRampTakeoverOff}
          onComplete={showOnRampTakeoverOff}
          transactionId={onRampUrl.id}
        />
      )}
    </Box>
  );
};
