import * as React from "react";
import { Box, Grid, Typography, Chip, Tooltip } from "@mui/material";
import { WidgetsOutlined } from "@mui/icons-material";

import { CustomCard } from "../../../components/atoms/CustomCard.js/CustomCard";

export const CertificateStatusCard = ({ status }) => {
  const statusHelpText = {
    approved:
      "Approved status means the certificate has been reviewed and approved by CertSeal to be minted to the blockchain. The owner of the certificate can mint the certificate to the blockchain anytime at their convenience.",
    onhold:
      "On hold status means the certificate required a member of CertSeal to review the certificate for quality assurance.",
    minted:
      "Minted means the certificate is minted to the blockchain and can be regarded as valid and authentic",
    revoked: "Revoked status means the certificate has been disapproved.",
  };
  const statusColor = {
    approved: "info",
    onhold: "default",
    minted: "success",
    revoked: "error",
  };

  return (
    <Grid container sx={{ marginTop: 3 }}>
      <Grid item xs={12}>
        <CustomCard>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WidgetsOutlined sx={{ marginRight: 1 }} />
                <Typography variant="h6">Blockchain Status</Typography>
              </Box>
              <Typography variant="caption">
                The status of the certificate is very important. If the
                certificate is not succesfully minted to the blockchain it
                should not be regarded as valid
              </Typography>
            </div>
            <Tooltip title={statusHelpText[status]} placement="top">
              <Typography sx={{ marginLeft: "24px" }} variant="subtitle2">
                <Chip color={statusColor[status]} label={status} />
              </Typography>
            </Tooltip>
          </Box>
        </CustomCard>
      </Grid>
    </Grid>
  );
};
