import React, { useContext, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import { Box, Grid, Link, Button, TextField, Typography } from "@mui/material";

import {
  postRegisterRequest,
  authenticateWithGoogle,
} from "../../../../lib/api-client";
import { store } from "../../../../lib/store";
import styled from "@emotion/styled";

const OrDivider = styled.div({
  width: "100%",
  display: "block",
  position: "relative",
  textAlign: "center",
  ":before": {
    top: "50%",
    width: "100%",
    height: "1px",
    content: "''",
    display: "block",
    position: "absolute",
    transform: "translateY(-50%)",
    background: "rgba(0,0,0,0.2)",
  },
});

const OrDividerSpan = styled.div({
  top: "50%",
  left: "50%",
  padding: "12px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  background: "white",
});

const RegisterForm = ({ email, token }) => {
  const { dispatch } = useContext(store);
  const [formErrors, set_formErrors] = useState({});
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleCredentialResponse = (response) => {
    authenticateWithGoogle(response.credential)
      .then((response) => {
        if (response.data.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          dispatch({ type: "updateCurrentUser", value: response.data.user });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Login failed", { variant: "error" });
      });
  };

  useEffect(() => {
    const buttonElement = document.getElementById("g_id_onload");
    google.accounts.id.initialize({
      context: "signup",
      client_id:
        "426817014803-i3og4me3sk166tl6g2919u1p1mc191os.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      buttonElement,
      { text: "signup_with", theme: "outline", size: "large", width: 400 } // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }, []);

  // Resets form errors for validation on the input fields
  const resetErrors = () => set_formErrors({});

  // Try to login after form submit pass validation
  const tryRegister = (registerDetails) => {
    postRegisterRequest(registerDetails)
      .then((response) => {
        // User has successfully registered
        // Handle reload / rerender here.
        enqueueSnackbar(
          "Account has been registered. Please check your email for the activation email",
          { variant: "success" }
        );

        if (response.data.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          dispatch({ type: "updateCurrentUser", value: response.data.user });

          setTimeout(() => {
            history.push(`/acceptTransfer${window.location.search}`);
            window.location.reload();
          }, 1000);
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (!err.response || !err.response.status) {
          console.log("Error", err);
          // unknown error
          return;
        }

        if (err.response.status === 400) {
          if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          }
          return;
        }

        if (err.response.status === 401) {
          if (err.response && err.response.data && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
          }
          return;
        }

        if (err.response && err.response.data && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
          return;
        }
      });
  };

  // Validate form fields
  const validateFormFields = (postData) => {
    let passedValidation = true;
    let errors = {};

    Object.keys(postData).map((postDataKey) => {
      if (postDataKey === "company") {
        return;
      }

      if (postData[postDataKey] === "") {
        errors = { ...errors, [postDataKey]: `${postDataKey} is required` };
        passedValidation = false;
      }
    });

    if (postData.password.length < 8) {
      errors.password = "Password must be 8 characters long";
    }

    if (postData.password !== postData.passwordConfirm) {
      errors.passwordConfirm = "Password confirm must match password";
    }

    set_formErrors(errors);
    return passedValidation;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Reset errors
    resetErrors();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const data = new FormData(event.currentTarget);
    const postData = {};
    for (const pair of data.entries()) {
      postData[pair[0]] = pair[1];
    }

    if (!validateFormFields(postData)) {
      setIsLoading(false);
      return;
    }

    if (email) {
      postData.email = email;
      if (token) {
        postData.token = token;
        postData.skip = true;
      }
    }

    console.log(postData);
    tryRegister(postData);
  };

  return (
    <>
      <div id="g_id_onload" width="100%"></div>
      <OrDivider>
        <OrDividerSpan>OR</OrDividerSpan>
      </OrDivider>
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <TextField
          sx={{ mt: 0 }}
          margin="normal"
          required={!email && "Email"}
          fullWidth
          id="email"
          label={"Email"}
          name="email"
          value={email}
          defaultValue={email}
          disabled={email}
          autoComplete="email"
          error={!!formErrors.email}
          helperText={formErrors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          error={!!formErrors.username}
          helperText={formErrors.username}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="password"
          error={!!formErrors.password}
          helperText={formErrors.password}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="passwordConfirm"
          label="Password Confirm"
          type="password"
          name="passwordConfirm"
          autoComplete="passwordConfirm"
          error={!!formErrors.passwordConfirm}
          helperText={formErrors.passwordConfirm}
        />

        <Button
          type="submit"
          disabled={isLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </Button>
      </Box>
    </>
  );
};

export default RegisterForm;
