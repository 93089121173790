import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';

import {
  Container,
  CssBaseline,
  Box,
  Typography,
} from '@mui/material';

import { useSnackbar } from 'notistack'; 


import Copyright from '../../components/atoms/Copyright-unauth';
import LogoSvg from '../../components/atoms/LogoSvg';
import { postActivateUser } from '../../../lib/api-client';

const ActivateUserRoute = (props) => {
  const urlParams = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    if (!urlParams.confirmationCode) {
      enqueueSnackbar('Unable to process confirmation code.');
      return;
    }

    postActivateUser(urlParams.confirmationCode).then((response) => {
      enqueueSnackbar('Success, your user account has been activated. We are redirecting you now.');
      history.push('/');
    }).catch((activationError) => {
      console.log(activationError);

      if (activationError.response && activationError.response.data && activationError.response.data.message) {
        enqueueSnackbar(`Error ${activationError.response.data.message}`, {variant: 'error'});
      } else {
        enqueueSnackbar('Error unknown error occured.', {variant: 'error'});
      }
    });
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{marginBottom: 6}}>
          <LogoSvg />
        </Box>
        <Typography component="h1" variant="h5">
          Please wait.
        </Typography>
        <Typography component="h4" align='center'>
        We are activating your account. We will redirect you automatically.
        </Typography>
        
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default ActivateUserRoute;