import * as React from "react";
import { RouteObjectType } from "./sharedTypes";
import { WalletRouteController } from "library/routes/Wallet/WalletRouteController";

export const walletRouteOptions: RouteObjectType[] = [
  {
    path: "/account/wallet",
    exact: true,
    hasSidebar: true,
    render: () => <WalletRouteController />,
  },
];
