import { gql, useQuery } from "@apollo/client";
import { DimensionTypes } from "library/routes/CreateCOA/art/sharedTypes";

enum ArtCertificateCategoryEnum {
  Print = "Print",
  Painting = "Painting",
  Drawing = "Drawing",
  Photography = "Photography",
  Video = "Video",
  Other = "Other",
}

interface ArtCertificateAdditionalDetails {
  artworkYear: string;
  artistName: string;
  category: ArtCertificateCategoryEnum;
  paperSpecifications: string;
  itemMedium: string;
  signed: boolean;
  signedBy: string;
  dimensions: {
    h: number;
    w: number;
    d: number;
    type: DimensionTypes;
  };
  framed: boolean;
  frameDimensions: {
    h: number;
    w: number;
    d: number;
    type: DimensionTypes;
  };
  frameDetails: string;
  notes: string;
  royaltyPercent: number;
}

export interface GetCertificateQueryType {
  _id: string;
  itemSku: string;
  itemName: string;
  itemDescription: string;
  additionalDetails: ArtCertificateAdditionalDetails;
  statementOA: string;
  certTemplateId: number;
}

export interface GetCertificateQueryData {
  getCertificate: GetCertificateQueryType;
}

export interface GetCertificateQueryVariables {
  id: string;
}

const getCertificateQuery = gql`
  query GetCertificate($id: ID!) {
    getCertificate(id: $id) {
      _id
      itemSku
      itemName
      itemDescription
      additionalDetails
    }
  }
`;

export const useGetCertificateQuery = ({ id }: { id: string }) => {
  const result = useQuery<
    GetCertificateQueryData,
    GetCertificateQueryVariables
  >(getCertificateQuery, {
    fetchPolicy: "cache-and-network",
    variables: { id: id },
  });

  return { ...result, certificate: result?.data?.getCertificate };
};
