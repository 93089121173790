import React from "react";

import LoginRoute from "../library/routes/Auth/Login.route";
import NotFound404 from "../library/routes/NotFound/NotFound";
import { VerifyCertificateUnAuthController } from "library/routes/VerifyCertificateUnAuth/VerifyCertificateUnAuthController";
import RegisterRoute from "../library/routes/Auth/Register.route";
import AdminViewRoute from "../library/routes/Auth/Admin/Admin.route";
import SimpleCOARoute from "../library/routes/CreateCOA/simple/simpleCOA.route";
import ProfileMainRoute from "../library/routes/Profile/Account.route";
import ActivateUserRoute from "../library/routes/Auth/activateUser.route";
import AutographCOARoute from "../library/routes/CreateCOA/autograph/autographCOA.route";
import PasswordResetRoute from "../library/routes/Auth/PasswordReset.route";
import MainCreateCertRoute from "../library/routes/CreateCOA/maincreateCOA.route";
import PasswordForgotRoute from "../library/routes/Auth/PasswordForget.route";
import PurchaseCreditsRoute from "../library/routes/PurchaseCredits/PurchaseCredits.route";
import { AcceptTransferUnauth } from "../library/routes/AcceptTransfer/AcceptTransfer.unauth";
import { AcceptTransfer } from "../library/routes/AcceptTransfer/AcceptTransfer";
import PageLayoutUnauthFullwidth from "../library/modules/PageLayout/PageLayoutUnauth.fullwidth";
import PageLayoutUnauth from "../library/modules/PageLayout/PageLayoutUnauth";
import { ViewCertificateRevamp } from "../library/routes/ViewCertificateRevamp/ViewCertificateRevamp";
import CreateArtCOAController from "../library/routes/CreateCOA/art/ArtCOA.controller";
import { PublicProfileController } from "../library/routes/Profile/routes/PublicProfile/PublicProfile.controller";
import { DashboardRouteController } from "library/routes/DashboardRevamp/DashboardRouteController";
import { MyCertificatesRevampController } from "library/routes/MyCertificatesRevamp/MyCertificatesRevampController";
import { walletRouteOptions } from "./routes/Wallet.routes";

const authRoutes = [
  {
    path: "/",
    exact: true,
    title: "Dashboard",
    render: () => <DashboardRouteController />,
  },
  {
    path: "/create/certificate",
    exact: true,
    title: "Create Certificates",
    render: () => <MainCreateCertRoute />,
  },
  {
    path: "/create/simple",
    exact: true,
    title: "Simple Certificate",
    render: () => <SimpleCOARoute />,
  },
  {
    path: "/create/autograph",
    exact: true,
    render: () => <AutographCOARoute />,
  },
  {
    path: "/create/art",
    exact: true,
    render: () => <CreateArtCOAController />,
    hasSidebar: false,
  },
  {
    path: "/certificates",
    exact: true,
    title: "My Certificates",
    render: () => <MyCertificatesRevampController />,
  },
  {
    path: "/certificate/:id",
    exact: true,
    title: "Certificate",
    render: () => <ViewCertificateRevamp />,
  },
  {
    path: "/profile",
    exact: true,
    render: () => <ProfileMainRoute />,
  },
  {
    path: "/profile/:id",
    exact: true,
    unAuthRoute: true,
    render: () => (
      <PageLayoutUnauthFullwidth>
        <PublicProfileController />
      </PageLayoutUnauthFullwidth>
    ),
  },
  {
    path: "/profile/billing",
    exact: true,
    render: () => <p>Billing</p>,
  },
  // {
  //   path: "/profile/transactions",
  //   exact: true,
  //   render: () => <TransactionHistoryRoute />,
  // },
  {
    path: "/profile/edit",
    exact: true,
    render: () => <p>edit</p>,
  },
  {
    path: "/purchase-credits",
    exact: true,
    render: () => <PurchaseCreditsRoute />,
  },
  {
    path: "/support",
    exact: true,
    render: () => <p>Support</p>,
  },
  {
    path: "/acceptTransfer",
    exact: true,
    render: () => <AcceptTransfer />,
  },
  {
    path: "/verify/:id",
    exact: true,
    unAuthRoute: true,
    render: () => (
      <PageLayoutUnauth>
        <VerifyCertificateUnAuthController />
      </PageLayoutUnauth>
    ),
  },
  {
    path: "/activate/:confirmationCode",
    exact: true,
    render: () => <ActivateUserRoute />,
  },
  ...walletRouteOptions,
  {
    path: "*",
    render: () => <NotFound404 />,
  },
];

const unAuthenticatedRoutes = [
  {
    path: "/",
    exact: true,
    unAuthRoute: true,
    render: () => <LoginRoute />,
  },
  {
    path: "/register",
    exact: true,
    unAuthRoute: true,
    render: () => <RegisterRoute />,
  },
  {
    path: "/acceptTransfer",
    exact: true,
    unAuthRoute: true,
    render: () => <AcceptTransferUnauth />,
  },
  {
    path: "/password_forget",
    exact: true,
    unAuthRoute: true,
    render: () => <PasswordForgotRoute />,
  },
  {
    path: "/passwordReset",
    exact: true,
    unAuthRoute: true,
    render: () => <PasswordResetRoute />,
  },
  {
    path: "/activate/:confirmationCode",
    exact: true,
    unAuthRoute: true,
    render: () => <ActivateUserRoute />,
  },
  {
    path: "/verify/:id",
    exact: true,
    unAuthRoute: true,
    render: () => (
      <PageLayoutUnauth>
        <VerifyCertificateUnAuthController />
      </PageLayoutUnauth>
    ),
  },
  {
    path: "/profile/:id",
    exact: true,
    unAuthRoute: true,
    render: () => (
      <PageLayoutUnauthFullwidth>
        <PublicProfileController />
      </PageLayoutUnauthFullwidth>
    ),
  },
  {
    path: "*",
    unAuthRoute: true,
    render: () => <NotFound404 />,
  },
];

const adminRoutes = [
  {
    path: "/admin",
    exact: true,
    render: () => <AdminViewRoute />,
  },
];

const getPageTitle = (routePath) => {
  const currentRoute = [...authRoutes, ...adminRoutes].find((route) => {
    return route.path === routePath;
  });
  return currentRoute?.title || "";
};

export { authRoutes, adminRoutes, getPageTitle, unAuthenticatedRoutes };
