import * as React from "react";
import moment from "moment";
import { Box, Tooltip, IconButton, Typography, MenuItem } from "@mui/material";
import styled from "@emotion/styled";
import { CreateArtCOAFormValuesTypes } from "../sharedTypes";
import { capitalizeFirstLetter } from "../../../../../lib/helpers";
import { FullscreenOutlined } from "@mui/icons-material/";
import { BootstrapSelect } from "library/components/atoms/BootstrapInput/BootstrapInput";
import { FormFieldInput } from "library/components/atoms/FormFieldInput/FormFieldInput";
import { UpdateFormValuesType, PreviewSVGContainer } from "./ArtCOA.elements";
import ArtCertificateTemplate from "library/components/atoms/Certificates/art.certificate";

type CreateArtCOAPreviewTypes = {
  formValues?: CreateArtCOAFormValuesTypes;
  updateFormValues: (key: string, value: UpdateFormValuesType) => void;
};

const PreviewContainer = styled.div({
  position: "sticky",
  top: 12,
});

const GalleryContainer = styled.div({
  border: "1px solid #ddd",
  borderRadius: "4px",
  background: "#fff",
  overflow: "hidden",
});

const CreateArtCOAPreview = ({
  formValues,
  updateFormValues,
}: CreateArtCOAPreviewTypes) => {
  const handleViewFullSize = () => true;
  const [templateNum, setTemplateNum] = React.useState(0);

  return (
    <>
      <FormFieldInput
        fieldName="Template style"
        helperText="You wont be able to change this later on. More styles will be added in the future, if you have a custom certificate you would like to use, get in touch with support and we will help you."
        name="templateid"
        onChange={() => null}
        customInput={
          <BootstrapSelect
            style={{ marginTop: 0 }}
            id="bootstrap-input-proofType"
            value={templateNum}
            onChange={(evt: any) => {
              setTemplateNum(parseInt(evt.target.value));
              updateFormValues("certTemplateId", parseInt(evt.target.value));
            }}
          >
            <MenuItem value="0">Default</MenuItem>
            <MenuItem value="1">Classic 1</MenuItem>
            <MenuItem value="2">Classic 2</MenuItem>
            <MenuItem value="3">Classic 3 (Vertical)</MenuItem>
          </BootstrapSelect>
        }
      />
      <PreviewContainer>
        <GalleryContainer>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="View full sized image">
              <IconButton
                onClick={handleViewFullSize}
                aria-label="view full sized image in external window"
              >
                <FullscreenOutlined />
              </IconButton>
            </Tooltip>
            <Typography
              variant="caption"
              sx={{ marginLeft: "auto", fontStyle: "italic", marginRight: 2 }}
            >
              PREVIEW
            </Typography>
          </Box>
          <PreviewSVGContainer>
            <ArtCertificateTemplate
              templateNum={templateNum}
              itemImage={
                formValues?.itemImage[0] &&
                URL.createObjectURL(formValues?.itemImage[0])
              }
              itemMedium={formValues?.itemMedium}
              certificateName={formValues?.itemName}
              certificateDate={moment().format("DD MMM YYYY")}
              certificateType={capitalizeFirstLetter("art")}
              id={"Will be available after creation"}
              artistName={formValues?.artistName}
              category={formValues?.category}
              dimensions={`${formValues?.dimensions.w} x ${formValues?.dimensions.h} x ${formValues?.dimensions.d} ${formValues?.dimensions.type}`}
            />
          </PreviewSVGContainer>
        </GalleryContainer>
      </PreviewContainer>
      {templateNum === 3 && (
        <Typography variant="caption">
          Please note, vertical layout certificates will be full height. We are
          only showing it small here to fit the preview container.
        </Typography>
      )}
    </>
  );
};

export default CreateArtCOAPreview;
