import * as React from "react";
import { Autocomplete, Box, MenuItem, Button } from "@mui/material";
import styled from "@emotion/styled";
import CreateArtCOAForm from "./CreateArtCOAForm";
import CreateArtCOAPreview from "./CreateArtCOAPreview";
import {
  DimensionTypes,
  CreateArtCOAFormTypes,
  CreateArtCOAFormValuesTypes,
} from "../sharedTypes";
import {
  BootstrapInput,
  BootstrapSelect,
} from "../../../../components/atoms/BootstrapInput/BootstrapInput";
import { RowInputField } from "../../components/RowInputField";
import { FullPageAction } from "library/modules/FullPageAction/FullPageAction";
import { ValidationError } from "library/shared/sharedTypes";
import { FullPageActionWrapperStyles } from "library/modules/FullPageAction/components/FullPageAction.elements";

const CreateCertificatePreview = styled.div({
  width: "90%",
  padding: 12,
  position: "sticky",
  top: "12px",
});

const CreateCertificateForm = styled.div({
  width: "100%",
  padding: 12,
  overflowY: "auto",
});

export const PreviewSVGContainer = styled.div({
  svg: {
    maxWidth: "100%",
    maxHeight: "427px",
  },
});

export type UpdateFormValuesType =
  | string
  | File
  | File[]
  | number
  | boolean
  | {
      w: number;
      h: number;
      d: number;
      type: DimensionTypes;
    };

type CreateArtCertificatePresentationTypes = {
  isBusy?: boolean;
  loading?: boolean;
  formValues: CreateArtCOAFormValuesTypes;
  validationErrors: ValidationError[];
  updateFormValues: (key: string, value: UpdateFormValuesType) => void;
  userEditionsOptions: React.ReactNode;
  handleOnCreateCertificateHandler: () => void;
};

export const CreateArtCertificatePresentation = ({
  isBusy,
  loading,
  formValues,
  updateFormValues,
  validationErrors,
  userEditionsOptions,
  handleOnCreateCertificateHandler,
}: CreateArtCertificatePresentationTypes) => {
  return (
    <FullPageAction
      heightStyle={FullPageActionWrapperStyles.alternative}
      actions={
        <Button
          disabled={loading || isBusy}
          variant="contained"
          onClick={handleOnCreateCertificateHandler}
        >
          Create
        </Button>
      }
      mainContent={
        <CreateCertificateForm>
          <CreateArtCOAForm
            formValues={formValues}
            validationErrors={validationErrors}
            updateFormValues={updateFormValues}
            userEditionsOptions={userEditionsOptions}
          />
        </CreateCertificateForm>
      }
      secondaryContent={
        <CreateCertificatePreview>
          <CreateArtCOAPreview
            formValues={formValues}
            updateFormValues={updateFormValues}
          />
        </CreateCertificatePreview>
      }
    />
  );
};

export const DimensionsInput = ({
  formValues,
  updateFormValues,
}: CreateArtCOAFormTypes) => (
  <Box
    sx={{
      marginTop: "24px",
      display: "flex",
      gap: "0 12px",
      flexWrap: { xs: "wrap", sm: "wrap", md: "unset" },
    }}
  >
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="width"
      required
      value={formValues.dimensions.w}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("dimensions", {
          ...formValues.dimensions,
          w: parseFloat(val),
        })
      }
    />
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="height"
      required
      value={formValues.dimensions.h}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("dimensions", {
          ...formValues.dimensions,
          h: parseFloat(val),
        })
      }
    />
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="depth"
      required
      value={formValues.dimensions.d}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("dimensions", {
          ...formValues.dimensions,
          d: parseFloat(val),
        })
      }
    />
    <RowInputField
      fieldName="Units"
      customInput={
        <BootstrapSelect
          id="bootstrap-input-proofType"
          value={formValues.dimensions.type}
          onChange={(evt: any) =>
            updateFormValues("dimensions", {
              ...formValues.dimensions,
              type: evt.target.value as DimensionTypes,
            })
          }
        >
          <MenuItem value="ft">ft</MenuItem>
          <MenuItem value="cm">cm</MenuItem>
          <MenuItem value="inch">inch</MenuItem>
        </BootstrapSelect>
      }
    />
  </Box>
);

export const FrameDimensionsInput = ({
  formValues,
  updateFormValues,
}: CreateArtCOAFormTypes) => (
  <Box
    sx={{
      marginTop: "24px",
      display: "flex",
      gap: "0 12px",
      flexWrap: { xs: "wrap", sm: "wrap", md: "unset" },
    }}
  >
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="width"
      required
      value={formValues.frameDimensions.w}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("frameDimensions", {
          ...formValues.frameDimensions,
          w: parseFloat(val),
        })
      }
    />
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="height"
      required
      value={formValues.frameDimensions.h}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("frameDimensions", {
          ...formValues.frameDimensions,
          h: parseFloat(val),
        })
      }
    />
    <RowInputField
      sx={{ display: "flex", flexWrap: "wrap" }}
      fieldName="depth"
      required
      value={formValues.frameDimensions.d}
      inputProps={{
        type: "number",
      }}
      onChange={(val: string) =>
        updateFormValues("frameDimensions", {
          ...formValues.frameDimensions,
          d: parseFloat(val),
        })
      }
    />
    <RowInputField
      fieldName="Units"
      customInput={
        <BootstrapSelect
          id="bootstrap-input-proofType"
          value={formValues.frameDimensions.type}
          onChange={(evt: any) =>
            updateFormValues("frameDimensions", {
              ...formValues.frameDimensions,
              type: evt.target.value as DimensionTypes,
            })
          }
        >
          <MenuItem value="ft">ft</MenuItem>
          <MenuItem value="cm">cm</MenuItem>
          <MenuItem value="inch">inch</MenuItem>
        </BootstrapSelect>
      }
    />
  </Box>
);

export const TagInput = () => (
  <Autocomplete
    multiple
    id="tags-standard"
    options={[]}
    getOptionLabel={(option: any) => option?.title}
    renderInput={(params) => (
      <BootstrapInput {...params} placeholder="Favorites" />
    )}
  />
);
