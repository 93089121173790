import { clearCurrentUserFromStorage } from "lib/helpers";
import { WalletType } from "library/data/getCurrentUser";

export enum UserRoles {
  USER = "ROLE_USER",
  ADMIN = "ROLE_ADMIN",
  MODERATOR = "ROLE_MODERATOR",
}

export type UserCookieType = {
  id: string;
  bio: string;
  email: string;
  roles: UserRoles[];
  wallet: WalletType;
  credits: number;
  timeLog: Date;
  website: string;
  username: string;
  firstName: string;
  accountType: string;
  accessToken: string;
};

export const useGetCurrentUser = (): null | UserCookieType => {
  const localStorageData: string | null = localStorage.getItem("user");

  if (!localStorageData) {
    return null;
  }

  const user = JSON.parse(localStorageData);
  if (user && user.accessToken) {
    const timeLog = new Date(user.timeLog);
    timeLog.setDate(timeLog.getDate() + 1);

    if (timeLog < new Date()) {
      clearCurrentUserFromStorage();
      document.location.href = "/";
      return null;
    } else {
      return user;
    }
  } else {
    return null;
  }
};
