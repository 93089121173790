import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { Link, useParams } from "react-router-dom";
import moment, { now } from "moment";

import { Button } from "@mui/material";

import { store } from "../../../../../lib/store";
import ApprovalModal from "./ApprovalModal";
import { getAdminRoute } from "../../../../../lib/api-client";

const ApprovalsTable = () => {
  const { state, dispatch } = useContext(store);

  const [isLoading, set_isLoading] = useState(true);
  const [allCerts, set_allCerts] = useState([]);
  const [modalVisible, set_modalVisible] = useState(false);
  const [activeCert, set_activeCert] = useState(null);

  useEffect(() => {
    getAdminRoute()
      .then((response) => {
        set_isLoading(false);
        set_allCerts(response.data.certificates);
      })
      .catch((err) => {
        if (!err.response) {
          console.log(err);
          return;
        }

        if (err.response.status && err.response.status === 403) {
          console.log(err);
          return;
        }
      });
  }, []);

  const viewCertificate = (indx) => {
    set_activeCert(allCerts[indx]);
    set_modalVisible(true);
  };

  const renderLoadingContent = () => {
    return (
      <>
        <p>loading</p>
      </>
    );
  };

  const renderRows = () => {
    if (isLoading) {
      return (
        <tr>
          <td style={{ padding: "32px 0", textAlign: "center" }} colSpan="5">
            Loading...
          </td>
        </tr>
      );
    }

    if (!allCerts.length) {
      return (
        <tr>
          <td style={{ padding: "32px 0", textAlign: "center" }} colSpan="5">
            Looks like you don't have any certificates created just yet.
          </td>
        </tr>
      );
    }

    return allCerts.map((certificateData, indx) => (
      <tr key={indx}>
        <td>{certificateData.minter.username}</td>
        <td>{certificateData.itemName}</td>
        <td>{certificateData.status}</td>
        <td>{moment(certificateData.dateCreated).format("LL")}</td>
        <td>{certificateData.status}</td>
        <td>
          <Button iconType="inspect" onClick={() => viewCertificate(indx)}>
            View
          </Button>
        </td>
      </tr>
    ));
  };

  const renderCertificateView = () => (
    <>
      <h3>Admin dashboard</h3>

      <div id="admin-layer">
        <table className="certs-table">
          <thead>
            <tr>
              <th>Minter</th>
              <th>Name</th>
              <th>Status</th>
              <th>Date created</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </>
  );

  const closeModal = () => {
    set_modalVisible(false);
  };

  return (
    <>
      {isLoading ? renderLoadingContent() : renderCertificateView()}
      {modalVisible && (
        <ApprovalModal
          open={modalVisible}
          certificate={activeCert}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default ApprovalsTable;
