import { gql, useMutation } from "@apollo/client";

export interface GetUserSeenFlagsQueryData {
  SetUserRole: boolean;
}

export type SetUserRoleInput = {
  userRole: string;
};

const setUserRoleMutation = gql`
  mutation SetUserRole($setUserRoleInput: SetUserRoleInput) {
    SetUserRole(setUserRoleInput: $setUserRoleInput)
  }
`;

export const useSetUserRoleMutation = () => {
  return useMutation(setUserRoleMutation);
};
