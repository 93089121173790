import { gql, useQuery } from "@apollo/client";

export interface GetMyCertificatesQueryType {
  _id: string;
  status: string;
  itemSku: string;
  itemName: string;
  itemImage: any;
  dateCreated: Date;
}

export interface GetMyCertificatesQueryData {
  getAllCertificates: {
    count: number;
    certificates: GetMyCertificatesQueryType[];
  };
}

export interface GetMyCertificatesQueryVariables {
  page?: number;
  userId: string;
  limit?: number;
  search?: string;
  status?: string;
  createdOnly?: boolean;
}

export const getMyCertificatesQuery = gql`
  query GetMyCertificates(
    $page: Int
    $limit: Int
    $userId: ID!
    $search: String
    $status: String
    $createdOnly: Boolean
  ) {
    getAllCertificates(
      params: {
        page: $page
        limit: $limit
        userId: $userId
        search: $search
        status: $status
        createdOnly: $createdOnly
      }
    ) {
      certificates {
        _id
        status
        itemSku
        itemName
        itemImage
        dateCreated
      }
      count
    }
  }
`;

export const useGetMyCertificatesQuery = ({
  id,
  page,
  search,
  status,
  limit = 10,
  createdOnly,
}: {
  id: string;
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  createdOnly?: boolean;
}) => {
  const result = useQuery<
    GetMyCertificatesQueryData,
    GetMyCertificatesQueryVariables
  >(getMyCertificatesQuery, {
    fetchPolicy: "cache-and-network",
    variables: { userId: id, search, limit, page, status, createdOnly },
  });

  return {
    ...result,
    count: result?.data?.getAllCertificates.count,
    certificates: result?.data?.getAllCertificates.certificates,
  };
};
