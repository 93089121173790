import type { CreateArtCOAFormTypes } from "../sharedTypes";
import { Radio, MenuItem, FormControlLabel, RadioGroup } from "@mui/material";
import { BootstrapSelect } from "../../components/BootstrapInput";
import {
  DimensionsInput,
  FrameDimensionsInput,
} from "../components/ArtCOA.elements";

const genericFields = ({
  formValues,
  updateFormValues,
}: CreateArtCOAFormTypes) => [
  {
    required: true,
    itemKey: "itemName",
    fieldName: "Item Name",
    inputProps: {
      placeholder: "ie. Echelon Arrows",
    },
  },
  {
    required: true,
    itemKey: "itemImage",
    fieldName: "Item Image",
    fileUpload: true,
    helperText: "Upload up to 5 images showing the artwork, signature, etc",
    inputProps: {
      multiple: true,
      onChangeCustom: (files: File[]) => updateFormValues("itemImage", files),
    },
    error:
      formValues?.itemImage && formValues?.itemImage.length > 5
        ? "Error: Maximum of 5 images are allowed."
        : null,
  },
  {
    halfWidth: true,
    required: true,
    itemKey: "artistName",
    fieldName: "Artist Name",
    inputProps: {
      placeholder: "ie. John Doe",
    },
    helperText: "Name of the artist that created the artwork",
  },
  {
    halfWidth: true,
    itemKey: "itemSku",
    fieldName: "Item Identifier Number",
    inputProps: {
      placeholder: "ie. #201",
    },
    helperText: "An identifier number or code (Optional)",
  },
];

const descriptionFields = ({
  formValues,
  updateFormValues,
}: CreateArtCOAFormTypes) => [
  {
    halfWidth: true,
    required: true,
    itemKey: "itemMedium",
    fieldName: "Medium",
    inputProps: {
      placeholder: "ie. Lithograph",
    },
    helperText:
      "The material of the item, such as art canvas, lithograph, painting, photograph etc",
  },
  {
    halfWidth: true,
    required: true,
    itemKey: "artworkYear",
    fieldName: "Year Completed",
    inputProps: {
      type: "number",
      placeholder: "YYYY",
      onChangeCustom: (evt: any) =>
        updateFormValues("artworkYear", parseInt(evt.target.value)),
    },
  },
  {
    itemKey: "itemDescription",
    fieldName: "Item Description",
    inputProps: {
      rows: 4,
      multiline: true,
      sx: { resize: "vertical" },
      placeholder:
        "ie. weight: 10lbs | size: 30cm | color: black & blue with red trim",
    },
    error:
      formValues?.itemDescription && formValues?.itemDescription.length > 2000
        ? "Error: Maximum of 2000 characters are allowed."
        : null,
    helperText:
      "Describe the item in detail including attributes such as weight, size, colour, etc. Max length is 2000 characters",
  },
  {
    itemKey: "paperSpecifications",
    inputProps: {
      rows: 2,
      multiline: true,
      sx: { resize: "vertical" },
      error:
        formValues?.paperSpecifications &&
        formValues?.paperSpecifications.length > 1000
          ? "Error: Maximum of 1000 characters are allowed."
          : null,
      placeholder:
        "ie. 100% cotton paper which has a perfectly balanced soft grain that brings out the detail in every image",
    },
    fieldName: "Paper Specification",
    helperText: "Material of the paper/canvas. Max 1000 characters. (Optional)",
  },
  {
    halfWidth: true,
    itemKey: "notes",
    fieldName: "Special Instructions",
    inputProps: {
      placeholder: "ie. avoid direct sunlight (Optional)",
    },
    helperText:
      "Any additional information about the artwork such as care intructions. Max length is 300 characters",
  },
  {
    halfWidth: true,
    required: true,
    itemKey: "category",
    fieldName: "Category",
    inputProps: {},
    customInput: (
      <BootstrapSelect
        id="bootstrap-input-proofType"
        value={formValues.category}
        onChange={(evt: any) => updateFormValues("category", evt.target.value)}
      >
        <MenuItem value="Print">Print</MenuItem>
        <MenuItem value="Painting">Painting</MenuItem>
        <MenuItem value="Drawing">Drawing</MenuItem>
        <MenuItem value="Photography">Photography</MenuItem>
        <MenuItem value="Video">Video</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
      </BootstrapSelect>
    ),
  },
];

export const getCreateArtFormFields = ({
  formValues,
  updateFormValues,
  userEditionsOptions,
}: CreateArtCOAFormTypes & {
  userEditionsOptions: React.ReactNode;
}) => [
  ...genericFields({ formValues, updateFormValues }),
  ...descriptionFields({ formValues, updateFormValues }),

  {
    required: true,
    itemKey: "dimensions",
    fieldName: "Dimensions",
    inputProps: {
      placeholder: "YYYY",
    },
    customInput: (
      <DimensionsInput
        formValues={formValues}
        updateFormValues={updateFormValues}
      />
    ),
  },
  {
    required: true,
    itemKey: "framed",
    fieldName: "Is It Framed?",
    inputProps: {},
    customInput: (
      <RadioGroup row sx={{ mt: 2 }} name="row-radio-buttons-group">
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="No"
          checked={!formValues.framed}
          onChange={() => updateFormValues("framed", false)}
        />
        <FormControlLabel
          checked={formValues.framed}
          value={true}
          control={<Radio />}
          label="Yes"
          onChange={() => updateFormValues("framed", true)}
        />
      </RadioGroup>
    ),
  },
  {
    hidden: !formValues.framed,
    required: true,
    itemKey: "frameDimensions",
    fieldName: "Frame Dimensions",
    inputProps: {},
    customInput: (
      <FrameDimensionsInput
        formValues={formValues}
        updateFormValues={updateFormValues}
      />
    ),
  },
  {
    hidden: !formValues.framed,
    itemKey: "frameDetails",
    fieldName: "Frame Details",
    inputProps: {
      placeholder: "Material, condition, etc",
    },
  },
  {
    required: true,
    itemKey: "signed",
    fieldName: "Is It Signed?",
    inputProps: {},
    customInput: (
      <RadioGroup row sx={{ mt: 2 }} name="row-radio-buttons-group">
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="No"
          checked={!formValues.signed}
          onChange={() => updateFormValues("signed", false)}
        />
        <FormControlLabel
          checked={formValues.signed}
          value={true}
          control={<Radio />}
          label="Yes"
          onChange={() => updateFormValues("signed", true)}
        />
      </RadioGroup>
    ),
  },
  {
    hidden: !formValues.signed,
    itemKey: "signedBy",
    fieldName: "Signed By",
    inputProps: {
      placeholder: "ie. Jane Doe",
    },
  },
  {
    required: true,
    itemKey: "isEdition",
    fieldName: "Is It Part Of An Edition?",
    inputProps: {},
    customInput: (
      <RadioGroup row sx={{ mt: 2 }} name="row-radio-buttons-group">
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="No"
          checked={!formValues.isEdition}
          onChange={() => updateFormValues("isEdition", false)}
        />
        <FormControlLabel
          checked={formValues.isEdition}
          value={true}
          control={<Radio />}
          label="Yes"
          onChange={() => updateFormValues("isEdition", true)}
        />
      </RadioGroup>
    ),
  },
  {
    hidden: !formValues.isEdition,
    required: true,
    itemKey: "edition",
    fieldName: "Edition Name",
    inputProps: {
      placeholder: "Select one",
    },
    customInput: userEditionsOptions ? (
      <BootstrapSelect
        id="bootstrap-input-proofType"
        value={formValues.edition}
        onChange={(evt: any) => updateFormValues("edition", evt.target.value)}
      >
        {userEditionsOptions}
      </BootstrapSelect>
    ) : (
      <p>Please first create an edition</p>
    ),
    halfWidth: true,
  },
  {
    hidden: !formValues.isEdition,
    required: true,
    itemKey: "editionNumber",
    fieldName: "Edition Number",
    inputProps: {
      type: "number",
      min: "0",
      placeholder: "ie. 2",
      onChangeCustom: (evt: any) =>
        updateFormValues("editionNumber", parseInt(evt.target.value)),
    },
    halfWidth: true,
  },
  {
    required: true,
    itemKey: "hasRoyalty",
    fieldName: "Include Royalties?",
    inputProps: {},
    customInput: (
      <RadioGroup row sx={{ mt: 2 }} name="row-radio-buttons-group">
        <FormControlLabel
          value={false}
          control={<Radio />}
          label="No"
          checked={!formValues.hasRoyalty}
          onChange={() => updateFormValues("hasRoyalty", false)}
        />
        <FormControlLabel
          checked={formValues.hasRoyalty}
          value={true}
          control={<Radio />}
          label="Yes"
          onChange={() => updateFormValues("hasRoyalty", true)}
        />
      </RadioGroup>
    ),
  },
  {
    hidden: !formValues.hasRoyalty,
    required: true,
    itemKey: "royaltyPercent",
    fieldName: "Royalty Percent",
    inputProps: {
      type: "number",
      min: "0",
      max: "5",
      placeholder: "ie. 3",
      onChangeCustom: (evt: any) =>
        updateFormValues("royaltyPercent", parseFloat(evt.target.value)),
    },
    helperText: "Max 5%. Note: CertSeal has 3.5% royalty.",
  },
];
