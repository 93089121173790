import * as React from "react";

export const useSteps = ({ maxSteps }: { maxSteps: number }) => {
  const [step, setStep] = React.useState(0);

  const nextStep = () => {
    if (step === maxSteps) {
      return;
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step === 0) {
      return;
    }
    setStep(step - 1);
  };

  return {
    step,
    nextStep,
    prevStep,
  };
};
