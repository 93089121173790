import * as React from "react";
import OnboardingImage0 from "assets/img/onboarding_0.png";
import OnboardingImage1 from "assets/img/onboarding_1.png";
import OnboardingImage2 from "assets/img/onboarding_2.png";
import OnboardingImage3 from "assets/img/onboarding_3.png";
import OnboardingImage4 from "assets/img/onboarding_5.png";
import OnboardingImage5 from "assets/img/onboarding_6.png";
import OnboardingImage6 from "assets/img/onboarding_7.png";
import { FullPageAction } from "library/modules/FullPageAction/FullPageAction";
import { OnboardingSetupMainContentController } from "./controllers/OnboardingSetupMainContentController";
import { useStepsV2 } from "library/hooks/useStepsV2";

export enum OnboardSteps {
  START = "start",
  DONE = "done",
  SELECT_ROLE = "select_role",
  ARTIST_ACKNOWLEDGE = "artist_acknowledge",
  COLLECT_ACKNOWLEDGE = "collect_acknowledge",
  PROFILE_INFORMATION = "profile_info",
  CONTACT_INFORMATION = "contact_info",
  PROFILE_SETTINGS = "profile_settings",
}

export const OnboardingSetupController = () => {
  const OnboardOrderedSteps = [
    OnboardSteps.START,
    OnboardSteps.SELECT_ROLE,
    OnboardSteps.ARTIST_ACKNOWLEDGE,
    OnboardSteps.PROFILE_INFORMATION,
    OnboardSteps.CONTACT_INFORMATION,
    OnboardSteps.PROFILE_SETTINGS,
    OnboardSteps.DONE,
  ];

  const { currentStepIndex, currentStep, nextStep, prevStep } =
    useStepsV2(OnboardOrderedSteps);
  const [actions, setActions] = React.useState<React.ReactNode[]>([]);

  const images = [
    OnboardingImage0,
    OnboardingImage1,
    OnboardingImage4,
    OnboardingImage2,
    OnboardingImage6,
    OnboardingImage5,
    OnboardingImage3,
  ];

  const secondaryContent = (
    <img src={images[currentStepIndex]} style={{ width: "75%" }} />
  );

  return (
    <FullPageAction
      actions={actions}
      mainContent={
        <OnboardingSetupMainContentController
          step={currentStep}
          nextStep={nextStep}
          prevStep={prevStep}
          setActions={setActions}
        />
      }
      secondaryContent={secondaryContent}
    />
  );
};
