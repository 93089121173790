import styled from "@emotion/styled";

export const CertificateFilterMobileWrapper = styled.div({
  gap: 12,
  display: "flex",
  alignItems: "center",
});

export const MobileSearchContainer = styled.div({
  width: "100%",
  "> div": {
    marginBottom: 0,
  },
  "> div > div": {
    marginTop: 0,
  },
});

export const MobileFiltersDropdownContainer = styled.div({
  padding: 12,
  display: "flex",
  marginTop: 12,
  background: "white",
  borderRadius: 4,
  flexDirection: "row",
});
