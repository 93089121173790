import * as React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { CertificateCard } from "../components/CertificateCardV2";
import {
  DashboardSectionTitleWrapper,
  DashboardLatestCertificatesWrapper,
} from "../components/DashbaordRoute.elements";
import {
  UserCookieType,
  useGetCurrentUser,
} from "library/hooks/useGetCurrentUser";
import { useGetMyCertificatesQuery } from "library/routes/MyCertificatesRevamp/data/getMyCertificates";
import moment from "moment";

export const LatestCertificatesController = () => {
  const user = useGetCurrentUser();

  const canCreateCertificates = user?.accountType === "artist";

  const {
    error: createdTokenIdsError,
    loading: createdTokenIdsLoading,
    certificates,
  } = useGetMyCertificatesQuery({
    id: (user as UserCookieType).id,
    limit: 3,
    createdOnly: canCreateCertificates,
  });

  return (
    <>
      <DashboardSectionTitleWrapper>
        <Typography variant="h2">
          {canCreateCertificates ? "Latest certificates" : "My certificates"}
        </Typography>

        <Link to="/certificates">
          <Typography variant="caption">View all certificates</Typography>
        </Link>
      </DashboardSectionTitleWrapper>

      <DashboardLatestCertificatesWrapper>
        <Grid container spacing={3}>
          {certificates?.map((certificate) => (
            <Grid key="right" item lg={4} md={6} xs={12}>
              <CertificateCard
                key={certificate._id}
                img={
                  certificate.itemImage.path || certificate.itemImage[0].path
                }
                link={`/certificate/${certificate._id}`}
                date={moment(certificate.dateCreated).format("DD MMM, YYYY")}
                title={certificate.itemName}
                status={certificate.status}
              />
            </Grid>
          ))}
        </Grid>
      </DashboardLatestCertificatesWrapper>
    </>
  );
};
