import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Switch, Route } from "react-router-dom";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { theme as MuiTheme } from "../lib/mui-theme.ts";
import { appTheme as theme } from "../lib/appTheme.ts";
import "./styles.scss";
import { store } from "../lib/store";
import { authRoutes, unAuthenticatedRoutes, adminRoutes } from "../lib/routes";
import PageLayoutRevamp from "../library/modules/PageLayoutRevamp/PageLayout";
import { useGetUserSeenFlagsQuery } from "library/data/getUserSeenFlags";
import { OnboardingSetupController } from "library/features/OnboardingSetup/OnboardingSetupController";
import { useGetCurrentUserQuery } from "library/data/getCurrentUser";
import { RenderRoutes } from "./RenderRoutes";

function App() {
  const { state, dispatch } = useContext(store);
  const [user, set_user] = useState(null);
  const [authenticatedRoutes, set_authenticatedRoutes] = useState(authRoutes);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isSM = useMediaQuery({ maxWidth: 900 });

  const ONBOARDING_SEEN_FLAG = "ONBOARDING_SEEN_FLAG";
  const { seenFlags, loading: seenFlagsLoading } = useGetUserSeenFlagsQuery();
  const {
    user: userData,
    error: userLoadError,
    loading: userIsLoading,
  } = useGetCurrentUserQuery();

  useEffect(() => {
    set_user(state.currentUser);

    if (
      state.currentUser &&
      state.currentUser.roles &&
      state.currentUser.roles.includes("ROLE_ADMIN")
    ) {
      set_authenticatedRoutes([...adminRoutes, ...authRoutes]);
    }

    dispatch({
      type: "set page widths",
      value: {
        isDesktop,
        isTablet,
        isMobile,
        isSM,
      },
    });
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...state.currentUser,
        ...userData,
      })
    );
    dispatch({
      type: "updateCurrentUser",
      value: { ...state.currentUser, ...userData },
    });
  }, [userData]);

  const renderAuthenticatedRoutes = (route) => {
    const shouldShowOnboardingController =
      user && !seenFlags?.includes(ONBOARDING_SEEN_FLAG);

    if (
      shouldShowOnboardingController &&
      route.path !== "/acceptTransfer" &&
      !userLoadError
    ) {
      return (
        <PageLayoutRevamp hasSidebar={false} disableExit>
          <OnboardingSetupController />
        </PageLayoutRevamp>
      );
    } else {
      return (
        <PageLayoutRevamp hasSidebar={route.hasSidebar}>
          <route.render />
        </PageLayoutRevamp>
      );
    }
  };

  if (seenFlagsLoading || userIsLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="app">
      <FlagsmithProvider
        options={{
          environmentID: "LpZXH65dnQ835RjTZvGEiZ",
        }}
        flagsmith={flagsmith}
      >
        <EmotionThemeProvider theme={theme}>
          <ThemeProvider theme={MuiTheme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              {user === null ? (
                <RenderRoutes
                  routes={unAuthenticatedRoutes}
                  renderAuthenticatedRoutes={renderAuthenticatedRoutes}
                />
              ) : (
                <RenderRoutes
                  routes={authenticatedRoutes}
                  renderAuthenticatedRoutes={renderAuthenticatedRoutes}
                />
              )}
            </SnackbarProvider>
          </ThemeProvider>
        </EmotionThemeProvider>
      </FlagsmithProvider>
    </div>
  );
}

export default App;
