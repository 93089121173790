import * as React from "react";
import { Grid, Alert, AlertTitle, Button } from "@mui/material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { CertificateSlideshow } from "library/routes/SingleCertificateView/components/CertificateSlideshow";
import { ViewCertificateQuickActions } from "../components/ViewCertificateQuickActions";
import { ViewCertificateBlockchainDetails } from "../components/ViewCertificateBlockchainDetails";
import { ViewCertificateDetails } from "../components/ViewCertificateDetails";
import { ViewCertificateAboutArtist } from "../components/ViewCertificateAboutArtist";
import { ViewCertificateDocuments } from "../components/ViewCertificateDocuments";
import { ViewCertificateAppraisalHistory } from "../components/ViewCertificateAppraisalHistory";
import { ViewCertificateProvenanceTable } from "../components/ViewCertificateProvenanceTable";
import { CertificateStatusCard } from "library/routes/SingleCertificateView/components/CertificateStatusCard";
import { useSnackbar } from "notistack";
import { mintCertificate } from "lib/api-client";
import { ViewCertificateAppraisalHistoryController } from "library/features/ViewCertificateAppraisalHistory/ViewCertificateAppraisalHistoryController";
import { ViewCertificateCreateOrderCardController } from "library/features/ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";

export const ViewCertificateController = ({
  certificate,
  isUserTrueOwner,
}: {
  certificate: GetSingleCertificateType;
  isUserTrueOwner?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isMinting, set_isMinting] = React.useState(false);

  // Converts SVG to img
  const svgCertToPng = () => {
    const svg = document.getElementById("certificate-svg");
    const svgData = svg ? new XMLSerializer().serializeToString(svg) : null;
    return svgData;
  };

  const handleMintNFTResponse = (response: any) => {
    set_isMinting(false);
    enqueueSnackbar(
      "Certificate has been minted to the blockchain! You can view the hashes and addresses in the certificate details.",
      { variant: "success" }
    );
    location.reload();
    return;
  };

  const handleMintNFTError = (err: any) => {
    set_isMinting(false);
    console.log(err);

    if (err.response.data.message) {
      console.log(err.response.data.message);
      enqueueSnackbar(err.response.data.message, { variant: "error" });
      return;
    }

    enqueueSnackbar(
      "An error has occured trying to mint the certificate. The item may still be pending approval or may have been rejected.",
      { variant: "error" }
    );
    return;
  };

  const mintNFT = () => {
    if (!certificate.status || certificate.status !== "approved") {
      enqueueSnackbar(
        "Status of this item is pending approval or has been rejected. If this is an error, please contact us!",
        { variant: "error" }
      );
      return;
    }

    set_isMinting(true);

    const mintDetails = {
      id: certificate._id,
      svg: svgCertToPng(),
    };

    mintCertificate(mintDetails, certificate.certType)
      .then(handleMintNFTResponse)
      .catch(handleMintNFTError);
  };

  return (
    <Grid container spacing={3}>
      {certificate?.status === "approved" && (
        <Grid item xs={12}>
          <Alert
            sx={{ alignItems: "center" }}
            action={
              <Button
                color="success"
                variant="contained"
                size="small"
                disabled={isMinting}
                onClick={mintNFT}
              >
                Mint to blockchain
              </Button>
            }
          >
            <AlertTitle>Ready to mint!</AlertTitle>
            You certificate has been approved and can now be minted to the
            blockchain.
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={7} lg={8}>
        {/* Item Gallery */}
        <CertificateSlideshow certificate={certificate} />

        {/* Certificate status */}
        <CertificateStatusCard status={certificate.status} />

        {/* Description */}
        <ViewCertificateBlockchainDetails certificate={certificate} />

        {/* Provenance */}
        <ViewCertificateProvenanceTable certificate={certificate} />
      </Grid>
      <Grid item xs={12} sm={12} md={5} lg={4}>
        {/* About the artist */}
        {/* <ViewCertificateAboutArtist certificate={certificate} /> */}

        {/* Details */}
        <ViewCertificateDetails certificate={certificate} />

        {/* Orders / Royalties */}
        {certificate.status === "minted" && isUserTrueOwner && (
          <ViewCertificateCreateOrderCardController certificate={certificate} />
        )}

        {/* Quick actions */}
        {/* <ViewCertificateQuickActions certificate={certificate} /> */}

        {/* Appraisal History */}
        <ViewCertificateAppraisalHistoryController certificate={certificate} />

        {/* Documents */}
        <ViewCertificateDocuments certificate={certificate} />
      </Grid>
    </Grid>
  );
};
