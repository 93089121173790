import React, { useState } from "react";
import {
  Grow,
  Paper,
  Popper,
  Tooltip,
  MenuItem,
  MenuList,
  IconButton,
  ButtonGroup,
  ClickAwayListener,
} from "@mui/material";
import {
  Twitter,
  FlagOutlined,
  ShareOutlined,
  FavoriteOutlined,
  FacebookOutlined,
  MoreVertOutlined,
  ContentCopyOutlined,
  FavoriteBorderOutlined,
  LocalPrintshopOutlined,
  FormatColorReset,
} from "@mui/icons-material/";
import { postToggleFavouriteCertificate } from "lib/api-client";
import { ReportCertificateModal } from "library/routes/SingleCertificateView/Verify/ReportCertificateModal";

export const CertificateHeaderActions = ({ certificate, unauth }) => {
  const [likedByMe, setLikedByMe] = useState(certificate.favourite);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  // Share
  const [shareMenuOpen, setShareMenuOpen] = useState(false);
  const shareAnchorRef = React.useRef(null);

  // More
  const [moreMenu, setMoreMenu] = useState(false);
  const moreAnchorRef = React.useRef(null);

  const handlePrintScreen = () => {
    window.print();
    return true;
  };

  const handleClose = (event) => {
    if (
      (shareAnchorRef.current &&
        shareAnchorRef.current.contains(event.target)) ||
      (moreAnchorRef.current && moreAnchorRef.current.contains(event.target))
    ) {
      return;
    }

    setMoreMenu(false);
    setShareMenuOpen(false);
  };

  const toggleFavourite = () => {
    postToggleFavouriteCertificate(certificate._id);
    setLikedByMe(!likedByMe);
  };

  return (
    <>
      <ButtonGroup variant="outlined" size="small">
        {!unauth && (
          <Tooltip title="Favourite" placement="top">
            <IconButton aria-label="Favourite" onClick={toggleFavourite}>
              {likedByMe ? <FavoriteOutlined /> : <FavoriteBorderOutlined />}
            </IconButton>
          </Tooltip>
        )}
        {!unauth && (
          <Tooltip title="Print" placement="top">
            <IconButton aria-label="Print" onClick={handlePrintScreen}>
              <LocalPrintshopOutlined />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Share" placement="top">
          <IconButton
            aria-label="Share"
            ref={shareAnchorRef}
            onClick={() => {
              setShareMenuOpen(true);
              setMoreMenu(false);
            }}
          >
            <ShareOutlined />
          </IconButton>
        </Tooltip>
        {unauth && (
          <Tooltip title="More" placement="top">
            <IconButton
              aria-label="More"
              ref={moreAnchorRef}
              onClick={() => {
                setShareMenuOpen(false);
                setMoreMenu(true);
              }}
            >
              <MoreVertOutlined />
            </IconButton>
          </Tooltip>
        )}
      </ButtonGroup>

      {/* {Share Button Dropdown} */}
      <Popper
        open={shareMenuOpen}
        anchorEl={shareAnchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ zIndex: 10 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    onClick={(event) => {
                      navigator.clipboard.writeText(
                        `https://app.certseal.ca/verify/${certificate._id}`
                      );
                      setShareMenuOpen(false);
                    }}
                  >
                    <ContentCopyOutlined sx={{ marginRight: 1 }} /> Copy Link
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      window.open(
                        `https://twitter.com/intent/tweet?text=${encodeURI(
                          `Forgery proof CertSeal certificate of authenticity: https://app.certseal.ca/verify/${certificate._id}`
                        )}`
                      );
                      setShareMenuOpen(false);
                    }}
                  >
                    <Twitter color="info" sx={{ marginRight: 1 }} /> Share to
                    Twitter
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                          `https://app.certseal.ca/verify/${certificate._id}`
                        )}`
                      );
                      setShareMenuOpen(false);
                    }}
                  >
                    <FacebookOutlined color="info" sx={{ marginRight: 1 }} />{" "}
                    Share on Facebook
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* More menu */}
      <Popper
        open={moreMenu}
        anchorEl={moreAnchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ zIndex: 10 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  <MenuItem
                    onClick={(event) => {
                      setReportDialogOpen(true);
                      setShareMenuOpen(false);
                    }}
                  >
                    <FlagOutlined sx={{ marginRight: 1 }} /> Report
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {/* {Report Dialog} */}
      <ReportCertificateModal
        certId={certificate._id}
        open={reportDialogOpen}
        setOpen={setReportDialogOpen}
      />
    </>
  );
};
