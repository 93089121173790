const USDollarFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatMoney = (price: number) => {
  return USDollarFormat.format(price);
};

export const calculatePercentOfNumber = (number: number, percent: number) => {
  return (number / 100) * percent;
};
