import { gql, useMutation } from "@apollo/client";

const CANCEL_ORDER = gql`
  mutation CancelOrder($certificateId: ID!) {
    CancelOrder(certificateId: $certificateId)
  }
`;

export const useCancelOrderMutation = () => {
  return useMutation(CANCEL_ORDER);
};
