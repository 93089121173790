import * as React from "react";
import { Breadcrumbs, Box, Typography, Alert } from "@mui/material";
import { RouteHeader } from "library/components/molecules/RouteHeader/RouteHeader";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useSingleCertificateQuery } from "./data/getSingleCertificate";
import { _e } from "library/helpers/displayEscapedText";
import { ViewCertificateController } from "./controllers/ViewCertificateController";
import { handleViewCertificateInNewWindow } from "library/helpers/handleViewCertificateInNewWindow";
import { useGetPageActions } from "./hooks/useGetPageActions";
import { useToggle } from "library/hooks/useToggle";
import { TransferCertificateTakeoverController } from "library/features/TransferCertificateTakeover/TransferCertificateTakeoverController";
import { useSnackbar } from "notistack";
import { AddAppraisalTakeoverController } from "library/features/AddAppraisalTakeover/AddAppraisalTakeoverController";
import { useSingleCertificateAuthQuery } from "./data/getSingleCertificateAuth";
import { ViewCertificateSkeletons } from "./components/ViewCertificateSkeletons";

export const ViewCertificateRevamp = () => {
  const { id } = useParams<{ id: string }>();
  const {
    value: showTransferCertificateTakeover,
    setToggleOn: showTransferCertificateTakeoverOn,
    setToggleOff: showTransferCertificateTakeoverOff,
  } = useToggle();
  const {
    value: showAddAppraisalTakeover,
    setToggleOn: showAddAppraisalTakeoverOn,
    setToggleOff: showAddAppraisalTakeoverOff,
  } = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnTransferCertificate = React.useCallback(() => {
    showTransferCertificateTakeoverOn();
  }, []);

  const handleOnAddAppraisal = React.useCallback(() => {
    showAddAppraisalTakeoverOn();
  }, []);

  const handleOnViewCertificate = React.useCallback(
    () => handleViewCertificateInNewWindow(),
    [handleViewCertificateInNewWindow]
  );

  const {
    error: getCertificateError,
    loading: getCertificateLoading,
    certificate,
    isUserTrueOwner,
  } = useSingleCertificateAuthQuery({ id });

  const { actions } = useGetPageActions({
    certificate,
    isUserTrueOwner,
    handleOnAddAppraisal,
    handleOnViewCertificate,
    handleOnTransferCertificate,
  });

  if (getCertificateLoading) {
    return <ViewCertificateSkeletons />;
  }

  if (getCertificateError || !certificate) {
    return <h1>Error</h1>;
  }

  return (
    <Box sx={{ maxWidth: 1400, m: "0 auto 42px" }}>
      <Breadcrumbs
        sx={{ mb: 1, fontSize: "12px" }}
        separator="›"
        aria-label="breadcrumb"
      >
        <RouterLink to="/">Dashboard</RouterLink>
        <RouterLink to="/certificates">Certificates</RouterLink>
        <Typography fontSize="12px" color="text.primary">
          {_e(certificate.itemName)}
        </Typography>
      </Breadcrumbs>

      <RouteHeader
        title={_e(certificate.itemName) ?? "..."}
        actions={actions ?? []}
      />

      {certificate && certificate.status === "minted" && !isUserTrueOwner && (
        <Alert sx={{ mb: 4 }} variant="filled" severity="info">
          <Typography>
            This certificate is read-only mode because the ownership has
            changed. It may have sold, you will be able to see this certificate
            but you wont be able to interact with it.
          </Typography>
        </Alert>
      )}

      <ViewCertificateController
        certificate={certificate}
        isUserTrueOwner={isUserTrueOwner}
      />

      {showTransferCertificateTakeover && (
        <TransferCertificateTakeoverController
          onSuccess={() => {
            showTransferCertificateTakeoverOff();
            enqueueSnackbar(
              "Success! The new owner has been emailed a session token to accept the transfer. They will have 24 hours to accept.",
              { variant: "success" }
            );
            window.location.reload();
          }}
          onClose={showTransferCertificateTakeoverOff}
          certificate={certificate}
        />
      )}

      {showAddAppraisalTakeover && (
        <AddAppraisalTakeoverController
          onSuccess={() => {
            showAddAppraisalTakeoverOff();
            enqueueSnackbar("Success! Appraisal has been recorded.", {
              variant: "success",
            });
            window.location.reload();
          }}
          onClose={showAddAppraisalTakeoverOff}
          certificate={certificate}
        />
      )}
    </Box>
  );
};
