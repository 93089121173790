import * as React from "react";
import ArtCertificateSVG0 from "./art/Certificate_0";
import ArtCertificateSVG1 from "./art/Certificate_1";
import ArtCertificateSVG2 from "./art/Certificate_2";
import ArtCertificateSVG3 from "./art/Certificate_3";

type ArtCertificatePreviewSVGType = {
  id?: string;
  category?: string;
  itemImage?: string | null;
  artistName?: string;
  itemMedium?: string;
  certificateDate?: string;
  certificateName?: string;
  certificateType?: string;
  dimensions?: string;
};

export const ArtCertificateTemplate = ({
  templateNum,
  ...rest
}: ArtCertificatePreviewSVGType & {
  templateNum: number;
}) => {
  switch (templateNum) {
    case 0: {
      return <ArtCertificateSVG0 {...rest} />;
    }
    case 1: {
      return <ArtCertificateSVG1 {...rest} />;
    }
    case 2: {
      return <ArtCertificateSVG2 {...rest} />;
    }
    case 3: {
      return <ArtCertificateSVG3 {...rest} />;
    }
    default: {
      return <ArtCertificateSVG0 {...rest} />;
    }
  }
};

export default ArtCertificateTemplate;
