import { any } from "prop-types";

export type UseShouldShowBannerType = {
  currentUser: any;
};

export const useShouldShowEmailActivationBanner = ({
  currentUser,
}: UseShouldShowBannerType) => {
  if (currentUser && currentUser?.status === "Pending") {
    return { shouldShowEmailActivationBanner: true };
  }
  return { shouldShowEmailActivationBanner: false };
};
