export type UnknownObject = Record<string, any>;

export type ValidationError = {
  key: string;
  message: string;
};

export enum CertType {
  art = "art",
  simple = "simple",
  autographed = "autographed",
}

export enum CertificateStatus {
  onhold = "onhold",
  approved = "approved",
  minted = "minted",
  revoked = "revoked",
}

export enum DimensionTypes {
  ft = "ft",
  cm = "cm",
  inch = "inch",
}

export enum ArtCategory {
  Other = "Other",
  Print = "Print",
  Video = "Video",
  Drawing = "Drawing",
  Painting = "Painting",
  Photography = "Photography",
}

export interface CertificateType {
  _id: string;
  status?: CertificateStatus;
  locked?: boolean;
  minter?: string;
  itemSku?: string;
  tokenId?: number;
  certType?: CertType;
  itemName?: string;
  isMinted?: boolean;
  certImage?: any;
  favourite?: boolean;
  itemImage?: any;
  isRevoked?: boolean;
  dateMinted?: Date;
  dateCreated?: Date;
  statementOA?: string;
  ipfsMetaUrl?: any;
  currentOwner?: string;
  blockchainData?: any;
  certTemplateId?: number;
  itemDescription?: string;
  additionalDetails?: any;
}
