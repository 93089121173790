import * as React from "react";
import { FormatListBulletedOutlined } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";

export const ViewCertificateDocuments = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Documents" icon={<FormatListBulletedOutlined />}>
        <p>This certificate does not have any documents added to it yet.</p>
      </Card>
    </div>
  );
};
