import * as React from "react";
import { Box } from "@mui/material";
import { getCurrentUserFromStorage } from "lib/helpers";
import { ActivateUserEmailBanner } from "./components/ActivateUserEmailBanner";
import { useShouldShowEmailActivationBanner } from "./hooks/useShouldShowEmailActivationBanner";

export const GlobalAlertsController = () => {
  const currentUser = getCurrentUserFromStorage();

  const { shouldShowEmailActivationBanner } =
    useShouldShowEmailActivationBanner({
      currentUser,
    });

  if (shouldShowEmailActivationBanner) {
    return (
      <Box sx={{ marginBottom: 3 }}>
        <ActivateUserEmailBanner />
      </Box>
    );
  }

  return null;
};
