import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import {
  HomeOutlined,
  AccountBalanceWalletOutlined,
} from "@mui/icons-material";

export const ListItemLink = (props) => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

export const mainListItems = (canCreateCertificates) => {
  return (
    <div>
      <ListItemLink to="/" primary="Home" icon={<HomeOutlined />} />
      <ListItemLink
        to="/certificates"
        primary="Certificates"
        icon={<LayersOutlinedIcon />}
      />
      {canCreateCertificates && (
        <ListItemLink
          to="/account/wallet"
          primary="Wallet"
          icon={<AccountBalanceWalletOutlined />}
        />
      )}
    </div>
  );
};

export const secondaryListItems = (
  <div style={{ alignSelf: "flex-end" }}>
    <ListSubheader>Need Help?</ListSubheader>
    <ListItem>
      <Link
        style={{ fontSize: "14px", color: "#57737a" }}
        target="_blank"
        href="https://certseal.ca/faq"
      >
        FAQ
      </Link>
    </ListItem>
    <ListItem>
      <Link
        style={{ fontSize: "14px", color: "#57737a" }}
        target="_blank"
        href="https://certseal.ca/contact-us"
      >
        Contact Us
      </Link>
    </ListItem>
  </div>
);
