import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import moment, { now } from "moment";
import { FullscreenOutlined } from "@mui/icons-material/";
import { Box, Tooltip, IconButton } from "@mui/material";

import videoThumbnail from "../../../../assets/img/videothumbnail.png";
import CertificateInlineSVGDark from "../../../components/atoms/certificateInlineSVG.dark";
import ArtCertificateTemplate from "../../../components/atoms/Certificates/art.certificate";
import { capitalizeFirstLetter } from "../../../../lib/helpers";
import "../carousel.min.css";
import { _e } from "library/helpers/displayEscapedText";

export const CertificateSlideshow = ({ certificate }) => {
  const [selectedSlide, setSelectedSlide] = React.useState(0);

  const dimensions = certificate.additionalDetails?.dimensions;
  const dimensionsText = `${dimensions.w} x ${dimensions.h} x ${dimensions.d} ${dimensions.type}`;

  const returnGalleryImages = (certificate) => {
    let galleryImages = [];

    if (certificate.certType === "art") {
      galleryImages.push(
        ...certificate.itemImage.map((autoImg) => ({
          type: "image",
          src: autoImg.path,
        }))
      );
    } else {
      galleryImages.push({
        type: "image",
        src: certificate.itemImage.path,
      });
    }

    if (certificate.certType === "autographed") {
      if (certificate.additionalDetails?.autographImages) {
        galleryImages.push(
          ...certificate.additionalDetails?.autographImages?.map((autoImg) => ({
            type: "image",
            src: autoImg.path,
          }))
        );
      }

      galleryImages.push({
        type: certificate.additionalDetails?.proofType,
        src: certificate.additionalDetails?.proofAttachment?.path,
      });
    }

    return galleryImages;
  };

  const handleViewFullSize = () => {
    const galleryImages = returnGalleryImages(certificate);

    const mediaSource =
      selectedSlide == "0"
        ? document.getElementById("certificate").innerHTML
        : galleryImages[selectedSlide - 1]?.type === "video"
        ? `<video width='100%' controls src='${
            galleryImages[selectedSlide - 1].src
          }'>`
        : `<img width='100%' src='${galleryImages[selectedSlide - 1].src}'>`;

    var mywindow = window.open("", "_blank", "height=595,width=842");

    mywindow.document.write(
      "<html><head><title>" + document.title + "</title>"
    );
    mywindow.document.write("</head><body >");
    mywindow.document.write(mediaSource);
    mywindow.document.write("</body></html>");

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    return true;
  };

  return (
    <Box>
      <div id="certificate" style={{ display: "none" }}>
        {certificate.certType === "art" ? (
          <ArtCertificateTemplate
            id={certificate._id}
            templateNum={certificate.certTemplateId ?? 0}
            category={certificate.additionalDetails?.category ?? ""}
            dimensions={dimensionsText}
            itemImage={
              certificate.itemImage[0].path || certificate.itemImage.path || ""
            }
            artistName={_e(certificate.additionalDetails?.artistName ?? "")}
            itemMedium={certificate.additionalDetails?.itemMedium ?? ""}
            certificateDate={certificate.dateCreated}
            certificateName={_e(certificate.itemName)}
            certificateType={certificate.certType}
          />
        ) : (
          <CertificateInlineSVGDark
            itemImage={
              certificate.itemImage.path || certificate.itemImage[0].path
            }
            certificateName={certificate.itemName}
            certificateDate={moment(certificate.dateCreated).format("LL")}
            certificateSku={certificate.itemSku}
            certificateType={capitalizeFirstLetter(certificate.certType)}
            id={certificate._id}
            autographed={
              certificate.additionalDetails &&
              certificate.additionalDetails?.autographedBy
                ? certificate.additionalDetails?.autographedBy
                : "N/A"
            }
            status={capitalizeFirstLetter(certificate.status)}
          />
        )}
      </div>
      <div
        className="gallery-container"
        style={{
          border: "solid 1px #ddd",
          background: "white",
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="View full sized image">
            <IconButton
              onClick={handleViewFullSize}
              aria-label="view full sized image in external window"
            >
              <FullscreenOutlined />
            </IconButton>
          </Tooltip>
        </Box>
        <Carousel
          onChange={(indx) => setSelectedSlide(indx)}
          emulateTouch
          showArrows={true}
          infiniteLoop
          showThumbs={false}
          dynamicHeight={true}
        >
          {returnGalleryImages(certificate)?.map((mediaItem, mediaIndex) => {
            if (mediaItem.type === "video") {
              return (
                <div key={mediaIndex} className="slider-image-container">
                  <video
                    videoThumbnail={videoThumbnail}
                    controls
                    width={"100%"}
                    src={mediaItem.src}
                  ></video>
                </div>
              );
            }

            return (
              <div key={mediaIndex} className="slider-image-container">
                <img src={mediaItem.src} alt="item image" />
              </div>
            );
          })}
          <div className="slider-image-container">
            {certificate.certType === "art" ? (
              <ArtCertificateTemplate
                templateNum={certificate.certTemplateId}
                id={certificate._id}
                dimensions={dimensionsText}
                category={certificate.additionalDetails?.category ?? ""}
                itemImage={certificate.itemImage[0].path}
                artistName={decodeURIComponent(
                  certificate.additionalDetails?.artistName ?? ""
                )}
                itemMedium={certificate.additionalDetails?.itemMedium ?? ""}
                certificateDate={certificate.dateCreated}
                certificateName={decodeURIComponent(certificate.itemName)}
                certificateType={certificate.certType}
              />
            ) : (
              <CertificateInlineSVGDark
                itemImage={
                  certificate.itemImage.path || certificate.itemImage[0].path
                }
                certificateName={certificate.itemName}
                certificateDate={moment(certificate.dateCreated).format("LL")}
                certificateSku={certificate.itemSku}
                certificateType={capitalizeFirstLetter(certificate.certType)}
                id={certificate._id}
                autographed={
                  certificate.additionalDetails &&
                  certificate.additionalDetails?.autographedBy
                    ? certificate.additionalDetails?.autographedBy
                    : "N/A"
                }
                status={capitalizeFirstLetter(certificate.status)}
              />
            )}
          </div>
        </Carousel>
      </div>
    </Box>
  );
};
