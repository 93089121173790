import * as React from "react";
import { AttachMoney } from "@mui/icons-material";
import type { ApolloError } from "@apollo/client/errors";
import { _e } from "library/helpers/displayEscapedText";
import { Card } from "library/components/atoms/Card/Card";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import { BootstrapSelect } from "library/routes/CreateCOA/components/BootstrapInput";
import {
  CurrencyConversionType,
  CreateOrderCurrencyType,
} from "library/features/ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";
import {
  calculatePercentOfNumber,
  formatMoney,
} from "library/helpers/moneyFormat";

export const ViewCertificateCreateOrder = ({
  hasError,
  isLoading,
  conversion,
  artistRoyalty,
  selectedAmount,
  selectedCurrency,
  showConfirmModal,
  setSelectedAmount,
  setSelectedCurrency,
}: {
  isLoading: boolean;
  hasError?: ApolloError;
  conversion: CurrencyConversionType;
  artistRoyalty?: number;
  selectedAmount: number;
  showConfirmModal: () => void;
  selectedCurrency: CreateOrderCurrencyType;
  setSelectedAmount: (value: number) => void;
  setSelectedCurrency: (value: CreateOrderCurrencyType) => void;
}) => {
  if (isLoading) {
    <div style={{ marginTop: 24 }}>
      <Card title="Order Details" icon={<AttachMoney />}>
        <p>Loading...</p>
      </Card>
    </div>;
  }

  if (hasError) {
    <div style={{ marginTop: 24 }}>
      <Card title="Order Details" icon={<AttachMoney />}>
        <p>Encountered an error loading appraisal history.</p>
      </Card>
    </div>;
  }

  const handleOnPriceUpdate = (val: string) =>
    setSelectedAmount(parseFloat(val));

  const calculateTotalPriceForOrder = () => {
    if (!selectedAmount) return 0;

    const protocolFee = calculatePercentOfNumber(selectedAmount, 2);
    const certSealFee = calculatePercentOfNumber(selectedAmount, 3.5);
    const artistFee = artistRoyalty
      ? calculatePercentOfNumber(selectedAmount, artistRoyalty)
      : 0;

    return selectedAmount + protocolFee + certSealFee + artistFee;
  };

  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Order Details" icon={<AttachMoney />}>
        <Typography sx={{ mb: 2 }}>
          List this certificate for sale on the market.
        </Typography>

        <Box sx={{ mb: "2px" }}>
          <RowInputField
            fieldName="Currency"
            customInput={
              <BootstrapSelect
                id="bootstrap-input-proofType"
                value={selectedCurrency}
                onChange={(evt: any) => setSelectedCurrency(evt.target.value)}
              >
                <MenuItem value={CreateOrderCurrencyType.ETHEREUM}>
                  Ethereum (ETH)
                </MenuItem>
                <MenuItem value={CreateOrderCurrencyType.USD} disabled>
                  US Dollar ($)
                </MenuItem>
              </BootstrapSelect>
            }
          />
        </Box>

        <Box sx={{ mb: "2px" }}>
          <RowInputField
            sx={{ display: "flex", flexWrap: "wrap" }}
            fieldName="Price"
            required
            value={selectedAmount}
            inputProps={{
              type: "number",
              min: 0.00001,
            }}
            onChange={handleOnPriceUpdate}
            helperText={
              selectedAmount &&
              conversion.ethereum &&
              `Estimated conversion: ${formatMoney(
                selectedAmount * conversion.ethereum.usd
              )} (USD)`
            }
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">Royalty Fees</Typography>
          <Typography variant="caption">
            Buyer's price will include the following fees:
          </Typography>

          <ul
            style={{
              margin: "8px 0 16px",
              padding: "0 20px",
              fontSize: 14,
              display: "flex",
              gap: 4,
              flexDirection: "column",
            }}
          >
            <li>2% Protocol fee</li>
            <li>3.5% CertSeal fee</li>
            {!!artistRoyalty && <li>{artistRoyalty}% Artist fee</li>}
          </ul>
        </Box>

        <Box
          sx={{
            mb: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">Total:</Typography>
          <Box sx={{ textAlign: "right" }}>
            <Typography
              variant="h5"
              sx={{ ml: 2, whiteSpace: "break-spaces", wordBreak: "break-all" }}
            >
              {calculateTotalPriceForOrder()} <small>(ETH)</small>
            </Typography>
            <Typography variant="caption" color="GrayText">
              {conversion.ethereum &&
                `Estimated conversion: ${formatMoney(
                  calculateTotalPriceForOrder() * conversion.ethereum.usd
                )} (USD)`}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
          <Button variant="contained" onClick={showConfirmModal}>
            List for sale
          </Button>
        </Box>
      </Card>
    </div>
  );
};
