import { CopyAll, InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  CircularProgress,
} from "@mui/material";
import { useFeatureFlags } from "library/hooks/useFeatureFlags";
import { UnknownObject } from "library/shared/sharedTypes";
import { useSnackbar } from "notistack";

export const AddFundsModal = ({
  open,
  onClose,
  loading,
  l1Balance,
  onConfirmOk,
  onCreditCard,
  walletAddress,
}: {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  l1Balance: string;
  onConfirmOk: () => void;
  onCreditCard: () => void;
  walletAddress: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleOnClose = (
    event: UnknownObject | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: string
  ) => {
    onClose();
  };

  const flags = useFeatureFlags(["fund_by_credit_card"]);
  const isFundByCreditCardEnabled = !!flags.fund_by_credit_card?.enabled;

  console.log(flags);
  console.log(isFundByCreditCardEnabled);

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleOnClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ alignItems: "center", display: "flex", gap: 1 }}
        id="alert-dialog-title"
      >
        How to add funds to your wallet
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          In order to fund you wallet you will need to send Ethereum to the
          following address
        </DialogContentText>
        <Box
          sx={{
            mb: 2,
            gap: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            navigator.clipboard.writeText(walletAddress);
            enqueueSnackbar("Copied to clipboard");
          }}
          id="alert-dialog-description"
          textAlign="center"
        >
          <Typography variant="subtitle2">{walletAddress}</Typography>{" "}
          {<CopyAll fontSize="small" />}
        </Box>
        <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
          Once you send Ethereum to the above address, your layer one balance
          will appear below. It may take up to 20 minutes for the funds to
          appear. When you have Layer 1 balance available you can deposit the
          funds to your wallet.
        </DialogContentText>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ mb: 2 }}
          textAlign="center"
        >
          Layer 1 balance: {l1Balance} (Eth)
        </DialogContentText>
        <Divider />
        <Typography
          sx={{ textAlign: "center" }}
          color="GrayText"
          variant="caption"
          component="div"
        >
          We are working on a solution to offer purchasing certificates as well
          as funding wallets directly with a credit card payment in order to
          avoid the above scenario.
        </Typography>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Button disabled>
            <CircularProgress size="14px" />
          </Button>
        ) : (
          <>
            <Button
              disabled={loading}
              target="_blank"
              href="https://certseal.ca/kb/how-do-i-buy-sell-certificates/"
            >
              Learn more
            </Button>
            <Button
              disabled={!isFundByCreditCardEnabled}
              onClick={onCreditCard}
            >
              Fund by Credit Card
            </Button>
            <Button
              disabled={loading}
              color="info"
              onClick={onConfirmOk}
              autoFocus
            >
              Deposit to wallet
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
