import React from 'react'
import { Typography, Link } from '@mui/material/';

const Copyright = (props) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" target='_blank' href="https://certseal.ca/">
      CertSeal
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

export default Copyright;