import * as React from "react";
import { Box } from "@mui/material";

import { SideDrawer } from "../SideDrawer/SideDrawer";
import { PageWrapper } from "./components/PageLayout.elements";
import PageHeaderController from "../PageHeader/PageHeaderController";
import { useGetMobileBreakpoints } from "library/hooks/useGetMobileBreakpoints";
import { GlobalAlertsController } from "library/features/GlobalAlerts/GlobalAlertsController";

const PageLayout = ({
  children,
  hasSidebar = true,
  disableExit,
}: {
  children: React.ReactNode;
  hasSidebar?: boolean;
  disableExit?: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const { isMobile } = useGetMobileBreakpoints();

  return (
    <PageWrapper>
      {/* Header */}
      <PageHeaderController
        hasSidebar={hasSidebar}
        disableExit={disableExit}
        isDrawerOpen={open}
        setDrawerState={setOpen}
      />

      <Box sx={{ display: "flex" }}>
        {hasSidebar && (
          <SideDrawer isDrawerOpen={open} setDrawerState={setOpen} />
        )}

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              height: !hasSidebar ? "100%" : "auto",
              p: !hasSidebar ? 0 : isMobile ? 1 : 4,
            }}
          >
            {/* only show banners on main pages not full screen pages */}
            {hasSidebar && <GlobalAlertsController />}

            {children}
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default PageLayout;
