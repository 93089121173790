import styled from "@emotion/styled";

export const RouteHeaderWrapper = styled.div({
  width: "100%",
});

export const RouteHeaderTitleWrapper = styled.div({
  gap: 24,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const RouteHeaderTitleActionsWrapper = styled.div({
  display: "flex",
});
