import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import {
  Box,
  Grid,
  Link,
  Button,
  Dialog,
  Checkbox,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  DialogContentText,
} from "@mui/material";

import { store } from "../../../../lib/store";
import {
  postLoginRequest,
  authenticateWithGoogle,
  postResendEmailActivation,
} from "../../../../lib/api-client";

const LoginForm = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const { state, dispatch } = useContext(store);
  const [formErrors, set_formErrors] = useState({
    username: "",
    password: "",
  });
  const [showActivationEmail, setShowActivationEmail] = useState(false);
  const [resendEmailValue, set_resendEmailValue] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCredentialResponse = (response) => {
    authenticateWithGoogle(response.credential)
      .then((response) => {
        if (response.data.user) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          dispatch({ type: "updateCurrentUser", value: response.data.user });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        enqueueSnackbar("Login failed", { variant: "error" });
      });
  };

  useEffect(() => {
    const buttonElement = document.getElementById("g_id_onload");
    google.accounts.id.initialize({
      client_id:
        "426817014803-i3og4me3sk166tl6g2919u1p1mc191os.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      buttonElement,
      { theme: "outline", size: "large", width: 360 } // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
  }, []);

  // Resets form errors for validatio on the input fields
  const resetErrors = () => set_formErrors({ username: "", password: "" });

  // Try to login after form submit pass validation
  const tryLogin = (loginDetails) => {
    setIsLoading(true);
    postLoginRequest(loginDetails)
      .then((response) => {
        dispatch({ type: "updateCurrentUser", value: response });
        const redirectHref = queryParams.get("redirect");
        if (redirectHref) {
          window.location = redirectHref;
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        if (!err.response || !err.response.status) {
          console.log("Error", err);
          enqueueSnackbar(
            "Unkown error occured, please try again later or contact support.",
            {
              variant: "error",
            }
          );
          setIsLoading(false);
          return;
        }

        if (err.response.status === 404) {
          if (err.response && err.response.data && err.response.data.message) {
            if (
              err.response.data.message ===
              "Pending account activation. Please verify your email."
            ) {
              // pending account
              enqueueSnackbar(
                "Account is in pending status. Didn't get the email?",
                {
                  variant: "info",
                  action: () => (
                    <Button
                      onClick={() => setShowActivationEmail(true)}
                      variant="contained"
                    >
                      Resend Activation
                    </Button>
                  ),
                }
              );
            } else {
              // username or password wrong or unknown
              enqueueSnackbar("Invalid username or password.", {
                variant: "error",
              });
            }
          }
          setIsLoading(false);
          return;
        }

        if (err.response.status === 401) {
          if (err.response && err.response.data && err.response.data.message) {
            // username or password wrong
            enqueueSnackbar("Invalid username or password.", {
              variant: "error",
            });
          }
          setIsLoading(false);
          return;
        }
      });
  };

  const handleResendEmail = () => {
    setShowActivationEmail(false);
    if (!resendEmailValue || resendEmailValue === "") {
      return;
    }

    postResendEmailActivation(resendEmailValue)
      .then((response) => {
        enqueueSnackbar(
          "Sent! New activation email has been dispatched! If you do not have CertSeal as a contact, the email may end up in your junk folder",
          { variant: "success" }
        );
      })
      .catch((resendEmailErr) => {
        console.log(resendEmailErr);
        enqueueSnackbar(
          "Error! An error occured when trying to send the email. Please try again later. If the problem persists, please contact us as support@certseal.ca",
          { variant: "error" }
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Reset errors
    resetErrors();

    const data = new FormData(event.currentTarget);

    const email = data.get("email");
    const password = data.get("password");

    if (!email || email === "") {
      console.log(formErrors);
      set_formErrors({ ...formErrors, email: "Enter an email address" });
      return;
    }

    if (!password || password === "") {
      console.log(formErrors);
      set_formErrors({ ...formErrors, password: "Enter a password" });
      return;
    }

    tryLogin({ email, password });
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      sx={{
        width: { xs: "100%" },
        padding: { xs: 2 },
      }}
    >
      <TextField
        sx={{ mt: 0 }}
        margin="normal"
        required
        fullWidth
        id="email"
        type="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        error={!!formErrors.email}
        helperText={formErrors.email}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        error={!!formErrors.password}
        helperText={formErrors.password}
      />
      <Grid container alignItems={"center"}>
        <Grid item xs>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
        </Grid>
        <Grid item xs textAlign="right">
          <RouterLink to="password_forget">
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </RouterLink>
        </Grid>
      </Grid>
      <Button
        sx={{ mt: 3, mb: 2 }}
        type="submit"
        variant="contained"
        disabled={isLoading}
        fullWidth
      >
        Sign In
      </Button>
      <Box
        id="g_id_onload"
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
      ></Box>

      <Dialog
        open={showActivationEmail}
        onClose={() => setShowActivationEmail(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resend Activation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Did you miss our email? Enter in your email to resend the activation
            email so you can activate your account and login. If you are having
            issues please contact us at support@certseal.ca
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <TextField
            autoFocus
            id="email"
            label="Enter email address"
            type="email"
            fullWidth
            value={resendEmailValue}
            onChange={(evt) => set_resendEmailValue(evt.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowActivationEmail(false)}>Cancel</Button>
          <Button
            color="info"
            variant="contained"
            onClick={() => {
              handleResendEmail();
              setShowActivationEmail(false);
            }}
          >
            Resend Activation
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LoginForm;
