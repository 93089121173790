import React, { useContext, useState } from "react";
import { now } from "moment";
import { useSnackbar } from "notistack";

import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import { store } from "../../../../../lib/store";
import { updateCertificateStatus } from "../../../../../lib/api-client";

const ApprovalModal = (props) => {
  const { state, dispatch } = useContext(store);
  const [showJson, set_showJson] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateStatus = (status) => {
    updateCertificateStatus({ status, certificateId: props.certificate._id })
      .then((response) => {
        enqueueSnackbar("Certificate has been approved!");
        props.closeModal();
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured updating the certificate status.", {
          variant: "error",
        });
        return;
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.certificate.itemName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>ID: {props.certificate._id}</p>
          <p>Name: {props.certificate.itemName}</p>
          <p>Sku: {props.certificate.itemSku}</p>
          <p>Description: {props.certificate.Description}</p>
          <p>Status: {props.certificate.status}</p>
          <p>Certificate Type: {props.certificate.certType}</p>
          <p>
            <a target="_blank" href={props.certificate.itemImage.path}>
              Image Link
            </a>
          </p>
          <Button onClick={() => set_showJson(!showJson)}>
            {showJson ? "Hide" : "Show"} JSON
          </Button>
        </DialogContentText>
        <DialogContentText>
          {showJson && (
            <>
              <code>{JSON.stringify(props.certificate, null, 2)}</code>
              <Button
                color="error"
                onClick={() => handleUpdateStatus("revoked")}
              >
                Revoke Certificate
              </Button>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal}>Close</Button>
        <Button
          onClick={() => handleUpdateStatus("approved")}
          autoFocus
          color="primary"
          variant="contained"
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovalModal;
