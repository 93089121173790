import { useSteps } from "library/hooks/useSteps";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import * as React from "react";
import { AddAppraisalStep1 } from "../components/AddAppraisalStep1";
import { AddAppraisalStep2 } from "../components/AddAppraisalStep2";
import { AddAppraisalStep3 } from "../components/AddAppraisalStep3";
import { AddAppraisalFormValuesType } from "../AddAppraisalTakeoverController";

export const useGetAddAppraisalSteps = ({
  onClose,
  onSuccess,
  certificate,
  updateFormValues,
  transferFormValues,
  handleAddAppraisal,
}: {
  onClose: () => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
  updateFormValues: ({ name, value }: { name: string; value: any }) => void;
  transferFormValues: AddAppraisalFormValuesType;
  handleAddAppraisal: () => void;
}) => {
  const [isBusy, setIsBusy] = React.useState(false);
  const { step, nextStep, prevStep } = useSteps({ maxSteps: 3 });

  const getComponents = React.useCallback(() => {
    switch (step) {
      case 0:
        return AddAppraisalStep1({
          nextStep,
          certificateName: certificate.itemName,
        });
      case 1:
        return AddAppraisalStep2({
          isBusy,
          nextStep,
          prevStep,
          setIsBusy,
          onSuccess,
          certificate,
          updateFormValues,
          transferFormValues,
        });
      case 2:
        return AddAppraisalStep3({
          isBusy,
          onClose,
          nextStep,
          prevStep,
          setIsBusy,
          onSuccess,
          certificate,
          updateFormValues,
          transferFormValues,
          handleAddAppraisal,
        });
      default:
        return {
          actions: null,
          mainContent: null,
          secondaryContent: null,
        };
    }
  }, [step, certificate, transferFormValues, isBusy]);

  return {
    ...getComponents(),
  };
};
