import * as React from "react";
import { ViewCertificateAppraisalHistory } from "library/routes/ViewCertificateRevamp/components/ViewCertificateAppraisalHistory";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { useGetCertificateAppraisalsQuery } from "./data/getCertificateAppraisalHistory";

export const ViewCertificateAppraisalHistoryController = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  const {
    error: appraisalsError,
    loading: appraisalsLoading,
    appraisals,
  } = useGetCertificateAppraisalsQuery(certificate._id);

  return (
    <ViewCertificateAppraisalHistory
      hasError={appraisalsError}
      isLoading={appraisalsLoading}
      appraisals={appraisals}
    />
  );
};
