import * as React from "react";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";
import { OnboardingSetupMainContentWrapper } from "../components/OnboardingSetup.elements";
import { SelectOnboardingStep } from "../selectors/selectOnboardingStep";
import { OnboardSteps } from "../OnboardingSetupController";

enum UserRoleType {
  artist = "artist",
  company = "company",
  individual = "individual",
}

export type UserFormType = {
  city: string;
  postal: string;
  address: string;
  country: string;
  website?: string;
  lastName: string;
  province: string;
  firstName: string;
  middleName?: string;
  dateOfBirth?: Date;
  artistName?: string;
  phoneNumber: string;
  profileName: string;
  displayEmailPublic: boolean;
  allowUsersToContact: boolean;
};

export const OnboardingSetupMainContentController = ({
  step,
  nextStep,
  prevStep,
  setActions,
}: {
  step: OnboardSteps;
  nextStep: () => void;
  prevStep: () => void;
  setActions: (actions: React.ReactNode[]) => void;
}) => {
  const user = useGetCurrentUser();
  const [userRole, setUserRole] = React.useState<UserRoleType | undefined>();
  const [userForm, setUserForm] = React.useState<UserFormType>({
    city: "",
    postal: "",
    address: "",
    country: "",
    website: "",
    lastName: "",
    province: "",
    firstName: "",
    middleName: "",
    dateOfBirth: undefined,
    artistName: "",
    phoneNumber: "",
    profileName: "",
    displayEmailPublic: false,
    allowUsersToContact: false,
  });

  const { content, actions } = SelectOnboardingStep({
    user,
    step,
    userForm,
    userRole,
    nextStep,
    prevStep,
    setUserRole,
    setUserForm,
  });

  React.useEffect(() => {
    setActions(actions);
  }, [step, userRole, userForm]);

  return (
    <OnboardingSetupMainContentWrapper>
      <div style={{ width: "100%" }}>{content}</div>
    </OnboardingSetupMainContentWrapper>
  );
};
