import * as React from "react";
import { AttachMoney, InfoOutlined } from "@mui/icons-material";
import { _e } from "library/helpers/displayEscapedText";
import { Card } from "library/components/atoms/Card/Card";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { CurrencyConversionType } from "../ViewCertificateCreateOrderCardController";
import { ActiveOrdersForCertType } from "../data/useActiveOrdersForCertQuery";
import { formatMoney } from "library/helpers/moneyFormat";
import { ethers } from "ethers";
import { UnknownObject } from "library/shared/sharedTypes";

export const ViewCertificateCancelOrder = ({
  conversion,
  activeOrders,
  artistRoyalty,
  showConfirmModal,
}: {
  conversion: CurrencyConversionType;
  activeOrders: ActiveOrdersForCertType;
  artistRoyalty?: number;
  showConfirmModal: () => void;
}) => {
  const convertedLayer2Price = activeOrders.web3?.result[0]?.buy?.data?.quantity
    ?.hex
    ? parseFloat(
        ethers.utils.formatEther(
          activeOrders.web3?.result[0]?.buy?.data?.quantity?.hex
        )
      )
    : 0;

  const TooltipCaption = React.forwardRef((props, ref) => (
    <Box
      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
      {...props}
      ref={ref}
      color="GrayText"
    >
      <InfoOutlined fontSize="inherit" color="inherit" />
      <Typography
        sx={{ display: "flex", gap: "4px", lineHeight: 0 }}
        variant="caption"
      >
        This order contains fees
      </Typography>
    </Box>
  ));

  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Order Details" icon={<AttachMoney />}>
        <Typography sx={{ mb: 2 }}>
          Your certificate is listed for sale. You will be notified when there
          is an offer or purchase. Royalties are distributed immedietely.
        </Typography>

        <Box
          sx={{ mb: 4, display: "flex", gap: "4px", flexDirection: "column" }}
        >
          <Typography variant="h5">
            {convertedLayer2Price}
            <small
              style={{
                fontSize: 14,
                marginLeft: 4,
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              }}
            >
              (ETH)
            </small>
          </Typography>
          <Typography variant="caption" color="GrayText">
            {activeOrders &&
              conversion.ethereum &&
              `Estimated conversion: ${formatMoney(
                convertedLayer2Price * conversion.ethereum.usd
              )} (USD)`}
          </Typography>
        </Box>

        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ mb: 2 }}
            color="inherit"
            variant="contained"
            onClick={showConfirmModal}
          >
            Remove listing
          </Button>

          <Tooltip
            title={
              <ul style={{ paddingLeft: "14px" }}>
                {activeOrders?.web3?.result[0]?.fees.map(
                  (fee: UnknownObject, indx: number) => (
                    <li key={indx}>
                      {ethers.utils.formatEther(fee.amount)} (eth) for{" "}
                      {fee.type}
                    </li>
                  )
                )}
              </ul>
            }
            placement="bottom"
            arrow
            disableInteractive
          >
            <TooltipCaption />
          </Tooltip>
        </Box>
      </Card>
    </div>
  );
};
