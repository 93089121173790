import * as React from "react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const CardWrapper = styled.div({
  border: "solid 1px #ddd",
  padding: "8px 14px",
  background: "#fff",
  borderRadius: "4px",
  marginBottom: "24px",
});

const CardTitleWrapper = styled.div({
  gap: 8,
  display: "flex",
  alignItems: "center",
  marginBottom: 24,
  justifyContent: "space-between",
});

const CardTitleMain = styled.div({
  gap: 8,
  display: "flex",
  alignItems: "center",
});

type CardType = {
  icon?: React.ReactNode;
  title: React.ReactNode;
  children: React.ReactNode;
  titleActions?: React.ReactNode;
};

export const Card = ({ icon, title, titleActions, children }: CardType) => {
  const titleComponent = React.isValidElement(title) ? (
    title
  ) : (
    <Typography variant="h6">{title}</Typography>
  );

  return (
    <CardWrapper>
      <CardTitleWrapper>
        <CardTitleMain>
          {icon && icon}
          {titleComponent}
        </CardTitleMain>
        {titleActions && <div>{titleActions}</div>}
      </CardTitleWrapper>
      <div>{children}</div>
    </CardWrapper>
  );
};
