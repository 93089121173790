import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, AppBar, Toolbar, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import LogoSvg from "../../components/atoms/LogoSvg";
import AppBarDropdownMenu from "../../components/molecules/AppBarDropdownMenu/AppBarDropdownMenu";
import AppBarUpdatesDropdownMenu from "library/components/molecules/AppBarUpdatesDropdownMenu/AppBarUpdatesDropdownMenu";

const PageHeaderController = ({
  onClose,
  hasSidebar,
  disableExit,
  isDrawerOpen,
  setDrawerState,
}: {
  onClose?: () => void;
  hasSidebar?: boolean;
  disableExit?: boolean;
  isDrawerOpen: boolean;
  setDrawerState: (state: boolean) => void;
}) => {
  const history = useHistory();

  return (
    <AppBar
      id="full-page-header"
      elevation={0}
      position="relative"
      sx={{ borderBottom: "solid 1px #F1E4E8" }}
    >
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        {hasSidebar && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerState(!isDrawerOpen)}
            sx={{
              marginRight: "8px",
            }}
          >
            {isDrawerOpen ? <Close /> : <MenuIcon />}
          </IconButton>
        )}
        <Box sx={{ width: "150px", marginRight: "auto" }}>
          <LogoSvg />
        </Box>
        {hasSidebar ? (
          <>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <AppBarUpdatesDropdownMenu />
            </Box>

            <AppBarDropdownMenu />
          </>
        ) : !disableExit ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => (onClose ? onClose() : history.goBack())}
            sx={{
              marginRight: "8px",
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default PageHeaderController;
