import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./styles.scss";
import LogoSvg from "../../components/atoms/LogoSvg";
import LoginForm from "../../components/molecules/LoginForm/LoginForm";
import { Link } from "@mui/material";
import Copyright from "library/components/atoms/Copyright-unauth";

export default function SignIn() {
  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          className="img-grid"
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random?art)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
            }}
          >
            Every artwork deserves an ID
            <Typography
              style={{
                display: "block",
                fontSize: 18,
                marginTop: 12,
                textAlign: "center",
                lineHeight: "1.3em",
                letterSpacing: "0.8px",
              }}
            >
              Protect your value with digital certificates of authenticity you
              can trust!
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: 400 },
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 4,
              overflow: "hidden",
            }}
          >
            <Box>
              <LogoSvg />
            </Box>

            <Box textAlign="center">
              <Typography variant="h2">Sign In</Typography>
              <Typography>
                Welcome back! To continue, sign in to your CertSeal account
              </Typography>
            </Box>

            <LoginForm />

            <Box>
              <RouterLink to="/register">
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign up for free"}
                </Link>
              </RouterLink>
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
