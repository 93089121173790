import * as React from "react";
import { CircularProgress, Grid, Box, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { useSnackbar } from "notistack";
import { acceptTransfer, prefixUrl } from "../../../lib/api-client";

import confettiImg from "../../../assets/img/confetti.svg";

export function AcceptTransfer() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const certId = queryParams.get("certId");
    const token = queryParams.get("token");
    const recipient = queryParams.get("recipient");
    if (!certId || !token || !recipient) {
      setHasError(true);
      return;
    }

    acceptTransfer({ certId, token, recipient })
      .then(() => {
        enqueueSnackbar(
          "Success! The certificate has been transfered to you.",
          { variant: "success" }
        );
        setLoading(false);
        setTimeout(() => {
          window.location = "/certificates";
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setHasError(true);
        enqueueSnackbar("An error occured", { variant: "error" });
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        {hasError && (
          <p>
            An error occured with your request. This could mean the session has
            expired or the token is invalid. If this issue persists, please
            contact the owner of the certificate to retry the transfer on their
            end or get in touch with us at support@certseal.ca
          </p>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <p>Loading</p>
            <p>
              Please do not navigate away from this page. We will automatically
              redirect you when the process is complete.
            </p>
          </Box>
        )}
        {!loading && !hasError && (
          <>
            <img src={confettiImg}></img>
            <Typography sx={{ textAlign: "center" }} variant="h3">
              Success!
            </Typography>
            <p style={{ textAlign: "center" }}>
              Please wait while we redirect you...
            </p>
          </>
        )}
      </Grid>
    </Grid>
  );
}
