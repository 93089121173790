import * as React from "react";
import { FormatListBulletedOutlined } from "@mui/icons-material";
import type { GetSingleCertificateType } from "../data/getSingleCertificate";
import { Card } from "library/components/atoms/Card/Card";
import type { ApolloError } from "@apollo/client/errors";
import { GetCertificateAppraisalsData } from "library/features/ViewCertificateAppraisalHistory/data/getCertificateAppraisalHistory";
import { _e } from "library/helpers/displayEscapedText";

export const ViewCertificateAppraisalHistory = ({
  hasError,
  isLoading,
  appraisals,
}: {
  hasError?: ApolloError;
  isLoading: boolean;
  appraisals?: GetCertificateAppraisalsData[];
}) => {
  if (isLoading) {
    <div style={{ marginTop: 24 }}>
      <Card title="Appraisal History" icon={<FormatListBulletedOutlined />}>
        <p>Loading...</p>
      </Card>
    </div>;
  }

  if (hasError) {
    <div style={{ marginTop: 24 }}>
      <Card title="Appraisal History" icon={<FormatListBulletedOutlined />}>
        <p>Encountered an error loading appraisal history.</p>
      </Card>
    </div>;
  }

  if (!appraisals || !appraisals.length) {
    return (
      <div style={{ marginTop: 24 }}>
        <Card title="Appraisal History" icon={<FormatListBulletedOutlined />}>
          <p>This artwork does not have an appraisal history yet.</p>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Appraisal History" icon={<FormatListBulletedOutlined />}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th
                style={{ textAlign: "left", borderBottom: "solid 1px #d4d4d4" }}
              >
                Appraised By
              </th>
              <th
                style={{ textAlign: "left", borderBottom: "solid 1px #d4d4d4" }}
              >
                Appraised Date
              </th>
            </tr>
          </thead>

          <tbody>
            {appraisals.map((appraisal, indx) => (
              <tr key={`appraisal_${indx}`}>
                <td style={{ padding: "6px" }}>{_e(appraisal.appraisedBy)}</td>
                <td style={{ padding: "6px" }}>
                  {new Date(appraisal.appraisalDate).toDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
