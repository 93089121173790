import React from "react";

import {
  Box,
  Card,
  Typography,
  CardContent,
  CardActionArea,
  CardActions,
} from '@mui/material';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export const ButtonSelectRadio = ({ title, subtitle, selected, onClick }) => {
  
  return (
    <Card sx={{border: 'solid 1px #ddd', borderColor: selected ? '#e09145' : '#ddd'}} elevation={1}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography align="center" gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography align='center' variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </CardContent>
        <Box sx={{padding: '6px', background: '#f8f8f8'}}>
          <Typography sx={{display: 'flex', alignItems: 'center', justifyContent:'center'}} align='center' variant="body2" color="text.secondary">
            {selected ? (
              <>
                Selected
                <CheckOutlinedIcon sx={{marginLeft: '6px', fontSize: '13px'}} />
              </>
            ) : 'Select'}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  )
};
