import * as React from "react";
import { ReactModalPortal } from "library/components/atoms/ReactModalPortal/ReactModalPortal";
import FullPageTakeover from "library/modules/Takeover/TakeoverController";
import { Box, Typography } from "@mui/material";
import { IframeContainer } from "../components/OnRampTakeover.elements";
import { getImxClient } from "lib/imx/link/imxLinkController";
import { ImmutableXClient } from "@imtbl/imx-sdk";

type OnRampTakeoverProps = {
  url: string;
  onFail: () => void;
  onClose: () => void;
  onComplete: () => void;
  transactionId: string;
};

export const OnRampTakeover = ({
  url,
  onFail,
  onClose,
  onComplete,
  transactionId,
}: OnRampTakeoverProps) => {
  React.useEffect(() => {
    let timer: NodeJS.Timer;
    getImxClient().then(({ client }) => {
      timer = setInterval(() => {
        if (client) {
          client.getExchange(parseInt(transactionId)).then((response) => {
            if (response.status === "completed") {
              clearTimeout(timer);
              onComplete();
            }

            if (response.status === "failed") {
              clearTimeout(timer);
              onFail();
            }
          });
        }
      }, 15000);
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ReactModalPortal wrapperId="onrampwrapper">
      <FullPageTakeover onClose={onClose} scrollable>
        <Box
          sx={{
            margin: "24px auto",
            display: "flex",
            maxWidth: "900px",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Fund Your CertSeal Wallet
            </Typography>
            <Typography sx={{ mb: 2 }}>Powered by</Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
              <a target="_blank" href="https://moonpay.com">
                <img
                  src="https://www.moonpay.com/assets/logo-full-purple.svg"
                  style={{ maxWidth: 150 }}
                  title="Moonpay"
                />
              </a>
            </Typography>

            <Typography
              sx={{ mb: 2, maxWidth: 600 }}
              variant="caption"
              component="div"
            >
              MoonPay is our partner in providing a faster way to fund your
              wallet, directly via credit card payments. Some terms apply,
              please visit the MoonPay website to learn more.
            </Typography>
          </Box>

          <IframeContainer>
            <iframe src={url} />
          </IframeContainer>
        </Box>
      </FullPageTakeover>
    </ReactModalPortal>
  );
};
