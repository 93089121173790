import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router';

import LogoSvg from '../../components/atoms/LogoSvg';
import Copyright from '../../components/atoms/Copyright-unauth';
import {useSnackbar} from 'notistack';
import { postUpdateUserPassword } from '../../../lib/api-client';

const theme = createTheme();

export default function PasswordForgotRoute() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isSubmitted, set_isSubmitted] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSubmitted) {
      return;
    }

    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    
    const userPassword = data.get('password');
    const userPasswordConfirm = data.get('passwordConfirm');

    if (!userPassword || userPassword === '') {
      return;
    }

    if (userPassword !== userPasswordConfirm) {
      enqueueSnackbar('Passwords do not match.');
      return;
    }

    if (userPassword.length < 8 || userPassword.length > 20) {
      enqueueSnackbar('Password length must be at least 8 characters')
      return;
    }

    set_isSubmitted(true);

    const token = queryParams.get('token');
    const userId = queryParams.get('id');

    // password reset
    postUpdateUserPassword({userId, token, password: userPassword}).then(() => {
      enqueueSnackbar('Success, password has been reset! You may now login to your account', 'success');
    }).catch((updatePassError) => {
      if (updatePassError.response && updatePassError.response.data && updatePassError.response.data.message) {
        enqueueSnackbar(`Error!, ${updatePassError.response.data.message}`, {variant: 'error'});
      } else {
        enqueueSnackbar('Error, unkown error occured. Please contact an admin if this issue persists', {variant: 'error'});
      }
    });

    
  };

  const renderPasswordResetForm = () => {
    if (!queryParams || !queryParams.get('id') || !queryParams.get('token')) {
      return (<p>Unknown token parameter.</p>)
    }
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="password"
          autoFocus
          disabled={isSubmitted}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="passwordConfirm"
          label="Confirm Password"
          name="passwordConfirm"
          type="password"
          autoComplete="password"
          disabled={isSubmitted}
        />
        
        <Button
          type="submit"
          fullWidth
          disabled={isSubmitted}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Submit
        </Button>
      </Box>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{marginBottom: 6}}>
          <LogoSvg />
        </Box>
        <Typography component="h1" variant="h5">
          Reset your password.
        </Typography>
        <Typography component="h4" align='center'>
          Use the form below to reset your password. The token you generated will expire in 1 hours or after you reset your password
        </Typography>
        {renderPasswordResetForm()}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}