import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import LogoSvg from '../../components/atoms/LogoSvg';
import Copyright from '../../components/atoms/Copyright-unauth';
import {useSnackbar} from 'notistack';
import { postRequestPasswordRest } from '../../../lib/api-client';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function PasswordForgotRoute() {
  const [isSubmitted, set_isSubmitted] = React.useState(false);
  const [emailValidateError, set_emailValidateError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSubmitted) {
      return;
    }

    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    
    const email = data.get('email');

    if (!email || email === '' || !validateEmail(email)) {
      set_emailValidateError(true);
      return;
    }


    set_isSubmitted(true);

    postRequestPasswordRest(email).then((response) => {
      enqueueSnackbar('Success! An email will be dispatched if a user exists. Please check your email for further instructions.', { variant: 'success'});
    }).catch((passwordResetError) => {
      if (passwordResetError.response && passwordResetError.response.data && passwordResetError.response.data.message) {
        enqueueSnackbar(`Error, ${passwordResetError?.response?.data?.message}`, { variant: 'error'});
      } else {
        enqueueSnackbar(`Error, an unknown error occured, if this continues to happen please contact support.`, { variant: 'error'});
      }
    })
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{marginBottom: 6}}>
          <LogoSvg />
        </Box>
        <Typography component="h1" variant="h5">
          Locked out?
        </Typography>
        <Typography component="h4" align='center'>
          It's ok! It happens. To reset your password, please enter your email address for your account and we will send you a secure reset link.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            error={emailValidateError}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={isSubmitted}
          />
          
          <Button
            type="submit"
            fullWidth
            disabled={isSubmitted}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}