import {
  Link,
  ImmutableXClient,
  ImmutableOrderStatus,
  EthAddressBrand,
} from "@imtbl/imx-sdk";

const isDev = process.env.NODE_ENV === "development";

const linkAddress = "https://link.x.immutable.com";
const apiAddress = "https://api.x.immutable.com/v1";

// Goerli Testnet
const linkAddressSandbox = "https://link.sandbox.x.immutable.com";
const apiAddressSandbox = "https://api.sandbox.x.immutable.com/v1";

export const IMXLink = new Link(isDev ? linkAddressSandbox : linkAddress);

export const getImxClient = async () => {
  const client = await ImmutableXClient.build({
    publicApiUrl: isDev ? apiAddressSandbox : apiAddress,
  });

  return {
    client,
  };
};

export const getUserBalances = async (user: any) => {
  const { client } = await getImxClient();
  const balances = await client.getBalances({ user });
  return balances;
};
