import * as React from "react";
import { useHistory } from "react-router-dom";

import { store } from "lib/store";
import {
  Box,
  List,
  AppBar,
  Drawer,
  Divider,
  Toolbar,
  Container,
  IconButton,
  CssBaseline,
} from "@mui/material";
import LogoSvg from "@/components/atoms/LogoSvg";
import { Copyright } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  TakeoverWrapper,
  ScrollableTakeoverWrapper,
} from "./components/Takeover.elements";
import { FullPageAction } from "../FullPageAction/FullPageAction";
import PageHeaderController from "../PageHeader/PageHeaderController";

const FullPageTakeover = ({
  onClose,
  children,
  scrollable,
}: {
  onClose?: () => void;
  children: React.ReactNode;
  scrollable?: boolean;
}) => {
  return scrollable ? (
    <ScrollableTakeoverWrapper>
      <PageHeaderController
        onClose={onClose}
        hasSidebar={false}
        isDrawerOpen={false}
        setDrawerState={() => null}
      />
      {children}
    </ScrollableTakeoverWrapper>
  ) : (
    <TakeoverWrapper>
      <PageHeaderController
        onClose={onClose}
        hasSidebar={false}
        isDrawerOpen={false}
        setDrawerState={() => null}
      />
      {children}
    </TakeoverWrapper>
  );
};

export default FullPageTakeover;
