import * as React from "react";
import { Button, Typography, MenuItem } from "@mui/material";
import { AddAppraisalMainContentWrapper } from "./AddAppraisal.elements";
import TransferCertificateImg from "library/../assets/img/provenance.png";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import { AddAppraisalFormValuesType } from "../AddAppraisalTakeoverController";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { useAddAppraisalMutation } from "../data/useAddAppraisalMutation";

const validateTransferValues = (values: AddAppraisalFormValuesType) => {
  let errors = null;

  if (!values.appraisalDate || !values.appraisalReason || !values.appraisedBy) {
    errors = "Please fill in all the required fields";
  }

  if (isNaN(Date.parse(values.appraisalDate))) {
    errors = "Please enter in a valid date";
  }

  return errors;
};

export const AddAppraisalStep3 = ({
  isBusy,
  onClose,
  prevStep,
  nextStep,
  setIsBusy,
  onSuccess,
  certificate,
  updateFormValues,
  transferFormValues,
  handleAddAppraisal,
}: {
  isBusy: boolean;
  onClose: () => void;
  prevStep: () => void;
  nextStep: () => void;
  setIsBusy: (value: boolean) => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
  updateFormValues: ({ name, value }: { name: string; value: any }) => void;
  transferFormValues: AddAppraisalFormValuesType;
  handleAddAppraisal: () => void;
}) => {
  const handleOnAddAppraisal = () => {
    const validateFormValues = validateTransferValues(transferFormValues);

    if (validateFormValues) {
      alert(validateFormValues);
      return;
    }

    setIsBusy(true);
    handleAddAppraisal();
  };

  const mainContent = (
    <AddAppraisalMainContentWrapper>
      <Typography variant="h2">Details</Typography>

      <div style={{ width: "100%" }}>
        <RowInputField
          inputProps={{ type: "number", min: 0 }}
          value={transferFormValues.appraisedValue}
          onChange={(val: string) =>
            updateFormValues({ name: "appraisedValue", value: parseInt(val) })
          }
          fieldName="Appraised Value"
          helperText="The value of appraisal. This field is optional"
        />
        <RowInputField
          inputProps={{ multiline: true, rows: 3 }}
          value={transferFormValues.appraisalNotes}
          onChange={(val: string) =>
            updateFormValues({ name: "appraisalNotes", value: val })
          }
          fieldName="Appraisal Notes"
          helperText="Enter any notes. This field is optional"
        />
      </div>
    </AddAppraisalMainContentWrapper>
  );

  const secondaryContent = <img src={TransferCertificateImg} />;

  const actions = (
    <>
      <Button variant="outlined" onClick={prevStep}>
        ← Back
      </Button>
      <Button
        color="info"
        variant="contained"
        onClick={handleOnAddAppraisal}
        disabled={isBusy}
      >
        Add Appraisal
      </Button>
    </>
  );

  return {
    actions,
    mainContent,
    secondaryContent,
  };
};
