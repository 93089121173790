import * as React from "react";

import {
  Dialog,
  Button,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { postReportForCertificate } from "../../../../lib/api-client";

export const ReportCertificateModal = ({ certId, open, setOpen }) => {
  const [reason, setReason] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnSubmit = () => {
    if (reason === "" || !reason) {
      enqueueSnackbar(`Please enter a reason.`, {
        variant: "error",
      });
      return;
    }

    if (reason.length > 200) {
      enqueueSnackbar(
        `Max length is 200. You added ${reason.length} characters which is ${
          reason.length - 200
        } more than allowed.`,
        { variant: "error" }
      );
      return;
    }

    postReportForCertificate({
      certId,
      reason,
    })
      .then(() => {
        enqueueSnackbar(
          `Thank you for your report. A member of the team has been notified and will review your complaint.`,
          { variant: "success" }
        );
        setReason("");
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`An error occured with your report.`, {
          variant: "error",
        });
        setReason("");
        setOpen(false);
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report This Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a reason for your report and a member of our team will
            review your compaint.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reason"
            label="Reason for report"
            type="text"
            fullWidth
            multiline
            rows={2}
            value={reason}
            onChange={(evt) => setReason(evt.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleOnSubmit}>Report</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
