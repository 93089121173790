import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import {
  getAdminGetUsers,
  postUpdateUserCredits,
} from "../../../../../lib/api-client";

const UsersTable = (props) => {
  const [users, set_users] = useState([]);
  const [selectedUser, set_selectedUser] = useState(0);
  const [modalVisible, set_modalVisible] = useState(false);
  const [newValues, set_newValues] = useState({
    credits: 0,
  });

  useEffect(() => {
    getAdminGetUsers()
      .then((response) => {
        set_users(response.data);
      })
      .catch((getUsersError) => {
        console.log(getUsersError);
        alert("unable to get users");
      });
  }, []);

  const renderUsers = () =>
    users.map((user, indx) => {
      return (
        <tr key={indx}>
          <td>{user.username}</td>
          <td>{user.credits || 0}</td>
          <td>{user.accountType || "undetermined"}</td>
          <td>{user.dateCreated || "N/A"}</td>
          <td>{user.roles.map((role) => role.name).join(",")}</td>
          <td>
            <Button
              size="small"
              onClick={() => {
                set_selectedUser(indx);
                set_modalVisible(true);
              }}
            >
              Edit
            </Button>
          </td>
        </tr>
      );
    });

  const closeModal = () => {
    set_modalVisible(false);
  };

  const updateNewFieldValues = (fieldKey, fieldValue) => {
    let updatedNewValues = newValues;
    updatedNewValues[fieldKey] = fieldValue;
    set_newValues(updatedNewValues);
  };

  const updateUser = () => {
    if (newValues.credits < 0) {
      return;
    }

    set_modalVisible(false);

    postUpdateUserCredits({
      credits: newValues.credits,
      userId: users[selectedUser]._id,
    })
      .then((response) => {
        let newUsers = users;
        newUsers[selectedUser] = {
          ...newUsers[selectedUser],
          credits: newValues.credits,
        };
        set_users([...newUsers]);
      })
      .catch((updateCreditErr) => {
        console.log(updateCreditErr);
      });
  };

  return (
    <div>
      <h3>Users</h3>

      <div id="admin-layer">
        <table className="certs-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Credits</th>
              <th>Account Type</th>
              <th>Date created</th>
              <th>Roles</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>{renderUsers()}</tbody>
        </table>
      </div>

      {modalVisible && (
        <Dialog open={modalVisible} onClose={closeModal}>
          <DialogTitle>Edit User</DialogTitle>
          <DialogContent>
            <DialogContentText>Update user credits:</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="credits"
              label="Credits"
              type="number"
              fullWidth
              variant="standard"
              defaultValue={users[selectedUser].credits || 0}
              min={0}
              onChange={(evt) =>
                updateNewFieldValues("credits", evt.target.value)
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button onClick={updateUser} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

UsersTable.propTypes = {};

export default UsersTable;
