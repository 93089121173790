import * as React from "react";
import { Box, Grid, Link } from "@mui/material";
import { WalletHeaderCards } from "../components/WalletHeaderCards";
import { CurrencyConversionType } from "library/features/ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";
import { getEthUsdConversion } from "lib/api-client";
import { ethers } from "ethers";
import { formatMoney } from "library/helpers/moneyFormat";
import { UserCreatedCertificatesData } from "library/routes/DashboardRevamp/data/getUserCreatedCertificates";
import { LatestTransactionsController } from "./LatestTransactionsController";
import { UserExchangesController } from "./UserExchangesController";
import { UserCookieType } from "library/hooks/useGetCurrentUser";
import { GetOwnerBalancesType } from "library/features/UserWalletHeaderDropdown/data/useGetUserBalances";

type FilterEthBalancesType = {
  token_address: string;
  symbol: string;
  balance: {
    type: string;
    hex: string;
  };
  preparing_withdrawal: {
    type: string;
    hex: string;
  };
  withdrawable: {
    type: string;
    hex: string;
  };
};

export const WalletPageController = ({
  user,
  balances,
  userExchanges,
  balancesLoading,
  createdTokenIds,
  isLoadingTransfers,
  latestTransactions,
}: {
  user: UserCookieType;
  balances?: GetOwnerBalancesType;
  userExchanges?: any[];
  balancesLoading: boolean;
  createdTokenIds?: UserCreatedCertificatesData[];
  isLoadingTransfers: boolean;
  latestTransactions: any[];
}) => {
  const [conversion, setConversion] = React.useState<CurrencyConversionType>(
    {}
  );

  React.useEffect(() => {
    getEthUsdConversion().then((data: any) => {
      if (data) {
        setConversion(data);
      }
    });
  }, []);

  const ethBalance: FilterEthBalancesType | undefined = React.useMemo(() => {
    const filterEthBalances = balances?.result.filter(
      (bal) => bal.symbol === "ETH"
    );
    if (filterEthBalances?.length) {
      return filterEthBalances[0];
    } else {
      return undefined;
    }
  }, [balances]);

  const convertedPrice = ethers.utils.formatEther(
    ethBalance?.balance?.hex ?? 0
  );

  const estimatedConversion =
    conversion.ethereum &&
    `Estimated conversion: ${formatMoney(
      parseFloat(convertedPrice) * conversion.ethereum.usd
    )} (USD)`;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <WalletHeaderCards
          walletBalance={ethBalance}
          convertedPrice={convertedPrice}
          balancesLoading={balancesLoading}
          createdTokensCount={createdTokenIds?.length ?? 0}
          isLoadingTransfers={isLoadingTransfers}
          estimatedConversion={estimatedConversion}
          latestTransactionsCount={latestTransactions?.length ?? 0}
        />
      </Grid>

      {user.accountType === "artist" && (
        <Grid item xs={12}>
          <LatestTransactionsController
            latestTransactions={latestTransactions}
          />
        </Grid>
      )}

      {userExchanges && (
        <Grid item xs={12}>
          <UserExchangesController userExchanges={userExchanges} />
        </Grid>
      )}
    </Grid>
  );
};
