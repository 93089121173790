import React from "react";

import { Typography } from "@mui/material";

const Title = ({ children }) => {
  return (
    <Typography
      sx={{ marginBottom: 0, fontWeight: 400 }}
      variant="h1"
      color="primary"
      gutterBottom
    >
      {children}
    </Typography>
  );
};

export default Title;
