import { gql, useMutation } from "@apollo/client";

export type CreateOrderInputValues = {
  amount: number;
  currency: string;
  certificateId: string;
};

const CREATE_ORDER = gql`
  mutation CreateOrder($createOrderInputValues: CreateOrderInputValues!) {
    CreateOrder(createOrderInputValues: $createOrderInputValues)
  }
`;

export const useCreateOrderMutation = () => {
  return useMutation(CREATE_ORDER);
};
