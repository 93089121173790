import React, { useContext, useState } from "react";
import { now } from "moment";
import { useHistory } from "react-router";

import {
  Box,
  Grid,
  Paper,
  Button,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";

import { useSnackbar } from "notistack";

import { store } from "../../../../lib/store";
import { createAutographCOA } from "../../../../lib/api-client";
import { RowInputGroup } from "../components/RowInputGroup";
import { RowInputField } from "../components/RowInputField";
import { BootstrapSelect } from "../components/BootstrapInput";

const genericFields = ({ updateFormValues }) => [
  {
    required: true,
    itemKey: "itemName",
    fieldName: "Item Name",
    inputProps: {
      placeholder: "ie. Mitch Marner Auto Patch | 2020 Series One",
    },
  },
  {
    required: true,
    itemKey: "itemSku",
    fieldName: "Item SKU",
    inputProps: {
      placeholder: "ie. 3/8",
    },
  },
  {
    required: true,
    itemKey: "itemMedium",
    fieldName: "Medium",
    inputProps: {
      placeholder: "ie. Collectible Card, hardback book",
    },
    helperText:
      "The material of the item, is it a cotten jersey? An art canvas? Marble sculture?",
  },
  {
    required: true,
    itemKey: "itemDescription",
    fieldName: "Item Description",
    inputProps: {
      rows: 4,
      multiline: true,
      placeholder:
        "ie. weight: 10lbs | size: 30cm | color: black & blue with red trim",
    },
    helperText:
      "Describe the item in detail including attributes such as weight, size, colour, etc. Max length is 300 characters.",
  },
  {
    required: true,
    itemKey: "itemImage",
    fieldName: "Item image",
    fileUpload: true,
    helperText: "Upload an overview image of your item",
    inputProps: {
      onChangeCustom: (files) => updateFormValues("itemImage", files[0]),
    },
  },
];

const autographFields = ({ updateFormValues }) => [
  {
    required: true,
    itemKey: "autographedBy",
    fieldName: "Autographed By",
    inputProps: {
      placeholder: "ie. Mitch Marner",
    },
  },
  {
    required: true,
    itemKey: "autographDate",
    fieldName: "Autographed Date",
    inputProps: {
      placeholder: "ie. 20 January 2021",
    },
    helperText: 'If you do not know the date, use "Unknown" as the value!',
  },
  {
    required: true,
    itemKey: "autographLocation",
    fieldName: "Autograph Location",
    inputProps: {
      placeholder: "ie. Top left corner, back left shoulder of jersey",
    },
  },
  {
    required: true,
    itemKey: "autographSize",
    fieldName: "Autograph Size",
    inputProps: {
      placeholder: "ie. 9cm x 3cm",
    },
  },
  {
    required: true,
    itemKey: "autographUtensil",
    fieldName: "Autographed By Utensil",
    inputProps: {
      placeholder: "ie. Sharpie, ballpoint pen",
    },
  },
  {
    required: true,
    itemKey: "autographColor",
    fieldName: "Autograph Color",
    inputProps: {
      placeholder: "ie. Blue",
    },
  },
  {
    required: true,
    itemKey: "numOfAutographs",
    fieldName: "Number Of Autographs",
    inputProps: {
      placeholder: "ie. 1",
      type: "number",
      min: 1,
    },
  },
  {
    required: true,
    itemKey: "autographImages",
    fieldName: "Autograph images",
    fileUpload: true,
    helperText: "Upload up to 5 images showing the autograph of the item",
    inputProps: {
      multiple: true,
      onChangeCustom: (files) => updateFormValues("autographImages", files),
    },
  },
];

const videoProofFields = ({ updateFormValues }) => [
  {
    required: true,
    itemKey: "autographEventName",
    fieldName: "Event Name",
    inputProps: {
      placeholder: "ie. Mitch Marner Meet and Greet",
    },
  },
  {
    required: true,
    itemKey: "autographEventLocation",
    fieldName: "Event Location",
    inputProps: {
      placeholder: "ie. Toronto Air Canada Center",
    },
  },
  {
    required: true,
    itemKey: "autographEventDate",
    fieldName: "Event Date",
    inputProps: {
      placeholder: "ie. January 20 2021",
    },
  },
  {
    required: true,
    itemKey: "proofAttachment",
    fieldName: "Video Proof",
    fileUpload: true,
    helperText: "Video should clearly show the autograph happening live",
    inputProps: {
      accept: "video/*",
      onChangeCustom: (files) => updateFormValues("proofAttachment", files[0]),
    },
  },
];

const letterProofFields = ({ updateFormValues }) => [
  {
    required: true,
    itemKey: "authenticationServiceName",
    fieldName: "Authentication Service Name",
    inputProps: {
      placeholder: "ie. JSA, PSA, Beckett",
    },
  },
  {
    required: true,
    itemKey: "authenticationServiceWebsite",
    fieldName: "Authentication Service Website",
    inputProps: {
      placeholder: "ie. https://www.certseal.ca",
    },
  },
  {
    required: true,
    itemKey: "authenticationServiceSubNum",
    fieldName: "Authentication Service Submission #",
    inputProps: {
      placeholder: "ie. JSA100001",
    },
  },
  {
    required: true,
    itemKey: "authenticationServiceCertNum",
    fieldName: "Certification #",
    inputProps: {
      placeholder: "ie. 12345",
    },
  },
  {
    required: true,
    itemKey: "authenticationServiceDate",
    fieldName: "Certification Date",
    inputProps: {
      placeholder: "ie. January 20 2021",
    },
  },
  {
    required: true,
    itemKey: "proofAttachment",
    fieldName: "Letter Of Authenticity Proof",
    fileUpload: true,
    inputProps: {
      onChangeCustom: (files) => updateFormValues("proofAttachment", files[0]),
    },
  },
];

const AutographCOARoute = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(store);
  const { enqueueSnackbar } = useSnackbar();

  const [formValues, set_formValues] = useState({
    itemSku: "",
    itemName: "",
    proofType: "video",
    itemImage: null,
    itemDescription: "",
    itemMedium: "",
    autographedBy: "",
    autographDate: "",
    autographLocation: "",
    autographSize: "",
    autographUtensil: "",
    autographColor: "",
    numOfAutographs: "",
    autographImages: null,
    autographEventName: "",
    autographEventLocation: "",
    autographEventDate: "",
    proofAttachment: null,
    authenticationServiceName: "",
    authenticationServiceWebsite: "",
    authenticationServiceSubNum: "",
    authenticationServiceCertNum: "",
    authenticationServiceDate: "",
  });
  const [isLoading, set_isLoading] = useState(false);

  const updateFormValues = (key, value) => {
    if (key === "autographImages" && value.length > 5) {
      alert("You have selected more than 5 images. Please only select 5.");
      return;
    }

    let newValues = { ...formValues, [key]: value };
    set_formValues(newValues);
  };

  const formValuesValidate = () => {
    let validationErrors = [];

    if (!formValues.itemName || formValues.itemName === "") {
      validationErrors.push("Item name is required");
    }

    if (!formValues.itemSku || formValues.itemSku === "") {
      validationErrors.push("Item sku is required");
    }

    if (!formValues.itemImage || formValues.itemImage.length <= 0) {
      validationErrors.push("Item image are required");
    }

    if (!formValues.autographImages || formValues.autographImages.length <= 0) {
      validationErrors.push("Autograph images are required");
    }

    if (formValues.autographImages && formValues.autographImages.length > 5) {
      validationErrors.push(
        "Maximum of 5 images are allowed to be uploaded for this type of certificate"
      );
    }

    if (formValues.autographDate && formValues.autographDate === "") {
      validationErrors.push("Autograph date is required");
    }

    if (formValues.itemDescription.length > 300) {
      validationErrors.push(
        "Item description must be less than 300 characters"
      );
    }

    if (!formValues.itemMedium || formValues.itemMedium === "") {
      validationErrors.push("Item medium is required");
    }

    if (!formValues.autographedBy || formValues.autographedBy === "") {
      validationErrors.push("Autographed by is required");
    }

    if (!formValues.autographLocation || formValues.autographLocation === "") {
      validationErrors.push("Autograph location is required");
    }

    if (!formValues.autographSize || formValues.autographSize === "") {
      validationErrors.push("Autograph size is required");
    }

    if (!formValues.autographUtensil || formValues.autographUtensil === "") {
      validationErrors.push("Autograph utensil is required");
    }

    if (!formValues.autographColor || formValues.autographColor === "") {
      validationErrors.push("Autograph color is required");
    }

    if (!formValues.numOfAutographs || formValues.numOfAutographs === "") {
      validationErrors.push("Number of autographs is required");
    }

    if (!formValues.proofAttachment || formValues.proofAttachment.length <= 0) {
      validationErrors.push("Autograph proof attachment is required");
    }

    if (formValues.proofType === "video") {
      if (
        !formValues.autographEventName ||
        formValues.autographEventName === ""
      ) {
        validationErrors.push("Autograph proof event name is required");
      }

      if (
        !formValues.autographEventLocation ||
        formValues.autographEventLocation === ""
      ) {
        validationErrors.push("Autograph proof event location is required");
      }

      if (
        !formValues.autographEventDate ||
        formValues.autographEventDate === ""
      ) {
        validationErrors.push("Autograph proof event date is required");
      }
    } else {
      if (
        !formValues.authenticationServiceName ||
        formValues.authenticationServiceName === ""
      ) {
        validationErrors.push(
          "Autograph proof authentication service name is required"
        );
      }

      if (
        !formValues.authenticationServiceWebsite ||
        formValues.authenticationServiceWebsite === ""
      ) {
        validationErrors.push(
          "Autograph proof authentication service website is required"
        );
      }

      if (
        !formValues.authenticationServiceDate ||
        formValues.authenticationServiceDate === ""
      ) {
        validationErrors.push(
          "Autograph proof authentication service date is required"
        );
      }

      if (
        !formValues.authenticationServiceCertNum ||
        formValues.authenticationServiceCertNum === ""
      ) {
        validationErrors.push(
          "Autograph proof authentication service certification number is required"
        );
      }

      if (
        !formValues.authenticationServiceSubNum ||
        formValues.authenticationServiceSubNum === ""
      ) {
        validationErrors.push(
          "Autograph proof authentication service submission number is required"
        );
      }
    }

    return validationErrors;
  };

  const handleFormSubmit = () => {
    set_isLoading(true);
    const validationErrors = formValuesValidate();
    if (validationErrors.length > 0) {
      let errors;

      if (validationErrors.length > 3) {
        errors = [
          {
            type: "error",
            text: "Multiple errors were found with your submission. Please ensure you filled in _all_ of the values!",
          },
        ];
      } else {
        errors = validationErrors.map((err) => {
          return {
            type: "error",
            text: err,
          };
        });
      }

      // toast errors
      enqueueSnackbar(errors[0].text, { variant: "error" });
      set_isLoading(false);
      return;
    }

    let formData = new FormData();
    formData.append("userId", state.currentUser.id);
    formData.append("certType", "autographed");

    Object.keys(formValues).map((key) => {
      if (key !== "autographImages") {
        formData.append(key, formValues[key]);
      }
    });

    Object.values(formValues.autographImages).map((file, indx) => {
      formData.append("autographImages", file);
    });

    createAutographCOA(formData)
      .then(() => {
        // success toast
        enqueueSnackbar(
          "Success! Your certificate of authenticity has been created.",
          { variant: "success" }
        );
        set_isLoading(false);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        let errMsg = "Something went wrong with the creation.";

        enqueueSnackbar(errMsg, { variant: "error" });
        set_isLoading(false);
      });
  };

  const renderFields = (fields) =>
    fields.map(({ itemKey, fieldName, ...rest }, indx) => (
      <RowInputField
        {...rest}
        key={indx}
        value={formValues[itemKey]}
        itemKey={itemKey}
        onChange={(val) => updateFormValues(itemKey, val)}
        fieldName={fieldName}
      />
    ));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5">
              Create an Autograph Certificate Of Authenticity
            </Typography>
            <Typography sx={{ margin: "12px 0" }}>
              An autograph COA is a certificate that represent an authentic
              memorabilia item with autographs / signatures. Creating this type
              of certificate requires a little bit more scrutiny to ensure the
              item is authentic. We will ask for, and require, a lot of details
              regarding the autograph, its origins, verifications and proof.
            </Typography>
            <Divider sx={{ margin: "24px 0" }}></Divider>

            <RowInputGroup
              groupHeading="Generic Info"
              groupSubtitle="Generic information about your certificate."
            >
              {renderFields(genericFields({ updateFormValues }))}
            </RowInputGroup>

            <RowInputGroup
              groupHeading="Autograph Information"
              groupSubtitle="Provide us with as much detail about the autograph / signature on your item."
            >
              {renderFields(autographFields({ updateFormValues }))}
            </RowInputGroup>

            <RowInputGroup
              groupHeading="Autograph Verification"
              groupSubtitle="To mint a COA to the blockchain we require information that prove your item is authentic."
            >
              <RowInputField
                required
                itemKey="proofType"
                fieldName="Proof Type"
                helperText="If you have a newly signed item with video proof select video proof. The video must show the item being autographed. Otherwise select Letter proof. Letter proof is a Letter Of Authenticity from a trusted autograph verification service such as JSA, PSA, Beckett."
                customInput={
                  <BootstrapSelect
                    id="bootstrap-input-proofType"
                    value={formValues.proofType}
                    onChange={(evt) =>
                      updateFormValues("proofType", evt.target.value)
                    }
                  >
                    <MenuItem value="video">Video</MenuItem>
                    <MenuItem value="letter">Letter</MenuItem>
                  </BootstrapSelect>
                }
              />

              {formValues.proofType === "video"
                ? renderFields(videoProofFields({ updateFormValues }))
                : renderFields(letterProofFields({ updateFormValues }))}
            </RowInputGroup>

            <RowInputGroup
              groupHeading="Create"
              groupSubtitle="Make sure you are confident with the values you have entered! Blockchain is immutable meaning these values cannot be changed ever again!"
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={handleFormSubmit}
                >
                  Create Certificate
                </Button>
              </Box>
            </RowInputGroup>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AutographCOARoute;
