import { List, ListItem, ListItemText } from "@mui/material";
import { _e } from "library/helpers/displayEscapedText";
export const ArtCertificateDescription = ({
  certificate,
}: {
  certificate: any;
}) => {
  const renderDimensionValue = (dimensions: {
    w: number;
    h: number;
    d: number;
    type: string;
  }) => {
    const { w, h, d, type } = dimensions;
    return `${w}${type} x ${h}${type} x ${d}${type}`;
  };

  return (
    <List>
      <ListItem disableGutters divider disablePadding>
        <ListItemText sx={{ fontWeight: "600" }}>Artist Name</ListItemText>
        <ListItemText
          primaryTypographyProps={{ sx: { fontWeight: "500" } }}
          sx={{ textAlign: "right" }}
        >
          {_e(certificate.additionalDetails?.artistName ?? "")}
        </ListItemText>
      </ListItem>

      <ListItem disableGutters divider disablePadding>
        <ListItemText sx={{ fontWeight: "600" }}>Artwork Year</ListItemText>
        <ListItemText
          primaryTypographyProps={{ sx: { fontWeight: "500" } }}
          sx={{ textAlign: "right" }}
        >
          {_e(certificate.additionalDetails?.artworkYear ?? "")}
        </ListItemText>
      </ListItem>

      {certificate.additionalDetails?.category && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>Category</ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {_e(certificate.additionalDetails.category)}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.itemMedium && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>Item Medium</ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {_e(certificate.additionalDetails.itemMedium)}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.signedBy && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>Signed By</ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {_e(certificate.additionalDetails.signedBy)}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.edition?.collectionName && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>Edition</ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {_e(certificate.additionalDetails.edition.collectionName)}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.dimensions && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>Dimensions</ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {renderDimensionValue(certificate.additionalDetails.dimensions)}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.frameDimensions && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText sx={{ fontWeight: "600" }}>
            Frame Dimensions
          </ListItemText>
          <ListItemText
            primaryTypographyProps={{ sx: { fontWeight: "500" } }}
            sx={{ textAlign: "right" }}
          >
            {renderDimensionValue(
              certificate.additionalDetails.frameDimensions
            )}
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.frameDetails && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText
            secondary={_e(certificate.additionalDetails.frameDetails)}
          >
            Paper Specification
          </ListItemText>
        </ListItem>
      )}

      {certificate.additionalDetails?.paperSpecifications && (
        <ListItem disableGutters divider disablePadding>
          <ListItemText
            secondary={_e(certificate.additionalDetails.paperSpecifications)}
          >
            Paper Specification
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};
