export const handleViewCertificateInNewWindow = () => {
  if (!window) {
    return;
  }

  const mywindow = window.open("", "PRINT", "height=595,width=842");

  if (!mywindow) {
    return;
  }

  const certificateInDOM = document.getElementById("certificate");

  if (!certificateInDOM) {
    return;
  }

  mywindow?.document.write("<html><head><title>" + document.title + "</title>");
  mywindow?.document.write("</head><body >");
  mywindow?.document.write(certificateInDOM.innerHTML);
  mywindow?.document.write("</body></html>");

  mywindow?.document.close(); // necessary for IE >= 10
  mywindow?.focus(); // necessary for IE >= 10*/

  return true;
};
