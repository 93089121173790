import * as React from "react";
import { useHistory } from "react-router";
import { Box, Typography, Button, Grid, Link } from "@mui/material";
import { DashboardSectionTitleWrapper } from "../components/DashbaordRoute.elements";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";
import {
  GettingStartedCardCertificate,
  GettingStartedCardProfile,
} from "../components/GettingStartedCards";

export const WelcomeController = () => {
  const user = useGetCurrentUser();
  const history = useHistory();

  return (
    <>
      <Box sx={{ mb: 6 }}>
        <DashboardSectionTitleWrapper>
          <Typography variant="h2">Welcome to CertSeal</Typography>
        </DashboardSectionTitleWrapper>

        <Typography>
          Welcome to CertSeal, we’re glad to have you onboard! Our app is being
          built specifically to help artists such as yourself effortlessly
          explore, manage, and unlock the full potential of digital certificates
          of authenticity. To get started simply create your first digital
          certificate of authenticity for an original artwork.
        </Typography>

        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => history.push("/create/art")}
        >
          Create your certificate
        </Button>
      </Box>
      <Box sx={{ mb: 6 }}>
        <DashboardSectionTitleWrapper>
          <Typography variant="h2">Getting started</Typography>
        </DashboardSectionTitleWrapper>

        <Grid container spacing={2} sx={{ alignItems: "stretch" }}>
          <Grid item xs={12} md={6}>
            <GettingStartedCardCertificate />
          </Grid>

          <Grid item xs={12} md={6}>
            <GettingStartedCardProfile />
          </Grid>
        </Grid>

        <Box sx={{ border: "solid 1px #333", padding: "16px", mt: 2 }}>
          <strong>Need help?</strong> Check out our{" "}
          <Link
            href="https://certseal.ca/kb/"
            target="_blank"
            sx={{ textDecoration: "underline!important" }}
          >
            getting started video series
          </Link>{" "}
          to learn the basics or reach out to our team and we’d be happy to
          help!
        </Box>
      </Box>
    </>
  );
};
