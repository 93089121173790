import React from "react";
import { InputLabel, FormControl, FormHelperText } from "@mui/material";

import FileUploadInput from "./FileUploadButton";
import { BootstrapInput } from "./BootstrapInput";

export const RowInputField = (props) => {
  const {
    value,
    error,
    itemKey,
    onChange,
    fieldName,
    inputProps,
    customInput,
    ...rest
  } = props;

  return (
    <FormControl
      variant="standard"
      fullWidth
      style={props.style}
      error={error}
      sx={{
        marginBottom: "14px",
      }}
    >
      {!!fieldName && (
        <InputLabel
          sx={{
            fontWeight: "bold",
          }}
          shrink
          htmlFor={`bootstrap-input-${itemKey}`}
        >
          {fieldName}
          {rest.required && "*"}
        </InputLabel>
      )}
      {customInput ? (
        customInput
      ) : rest.fileUpload === true ? (
        <FileUploadInput
          {...inputProps}
          id={`bootstrap-input-${itemKey}`}
          onChange={inputProps?.onChangeCustom}
        />
      ) : (
        <BootstrapInput
          {...inputProps}
          inputProps={inputProps}
          id={`bootstrap-input-${itemKey}`}
          fullWidth
          value={value}
          onChange={
            inputProps?.onChangeCustom
              ? inputProps.onChangeCustom
              : (evt) => onChange(evt.target.value)
          }
        />
      )}
      {!!error ? (
        <FormHelperText error={!!error}>{error}</FormHelperText>
      ) : rest.helperText ? (
        <FormHelperText>{rest.helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
