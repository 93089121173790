import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Grid } from "@mui/material";

import "./CheckoutStep.scss";
import {
  postAddUserTransactionIntent,
  postCreatePaymentIntent,
} from "../../../../lib/api-client";
import { StoreMallDirectory } from "@mui/icons-material";

const CheckoutStep = (props) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (props.amount > 1000 || props.amount < 1) {
      return <p>Please select an accepted amount</p>;
    } else {
      postCreatePaymentIntent(props.amount)
        .then((response) => {
          setClientSecret(response.data.clientSecret);
        })
        .catch((err) => {
          console.log(err);
          alert("An error occured.");
        });
    }
  }, []);

  if (props.amount > 1000 || props.amount < 1) {
    return <p>Please select an accepted amount</p>;
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: props.currentUser.email,
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      postAddUserTransactionIntent({ payload });
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <div className="checkoutStep">
      <Grid container>
        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            <small>Pay now with your credit card using Stripe</small>
          </div>
          <form
            id="payment-form"
            onSubmit={handleSubmit}
            className={succeeded ? "hidden" : ""}
          >
            <CardElement
              id="card-element"
              options={cardStyle}
              onChange={handleChange}
            />
            <button disabled={processing || disabled || succeeded} id="submit">
              <span id="button-text">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
          </form>

          {error && (
            <div
              style={{ textAlign: "center", color: "red", fontWeight: "bold" }}
              className="card-error"
              role="alert"
            >
              {error}
            </div>
          )}

          <p className={succeeded ? "result-message" : "result-message hidden"}>
            Thank you your payment has been initiated. Your credits will be
            added to your account automatically as soon as we verify the payment
            on our end. This may take up to 12 hours, if its been more than 12
            hours and you do not see a confirmation email or your credits in
            your account please contact us at support@certseal.ca
          </p>

          {!succeeded && !error && (
            <div
              style={{
                margin: "0 auto",
                textAlign: "center",
                maxWidth: "400px",
              }}
            >
              <small>
                Please note that your credits will be automatically deposited
                into your account <em>after</em> we confirm payment on our end.
                This usually takes no longer than 3 minutes but may take up to
                12 hours depend on the demand.
              </small>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

CheckoutStep.propTypes = {
  amount: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default CheckoutStep;
