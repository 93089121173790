import { MoreVert } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { CertificateStatus } from "library/shared/sharedTypes";
import * as React from "react";
import { GetSingleCertificateType } from "../data/getSingleCertificate";

export const useGetPageActions = ({
  certificate,
  isUserTrueOwner,
  handleOnAddAppraisal,
  handleOnViewCertificate,
  handleOnTransferCertificate,
}: {
  certificate?: GetSingleCertificateType;
  isUserTrueOwner?: boolean;
  handleOnAddAppraisal: () => void;
  handleOnViewCertificate: () => void;
  handleOnTransferCertificate: () => void;
}) => {
  let actionComponents: React.ReactNode[] = [];
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!certificate) return { actions: null };

  const getActions = () => {
    const actionsMemo = [
      {
        label: "View certificate",
        onClick: handleOnViewCertificate,
      },
    ];

    if (
      isUserTrueOwner &&
      certificate.isMinted &&
      certificate.status === CertificateStatus.minted
    ) {
      actionsMemo.push(
        ...[
          {
            label: "Transfer to new owner",
            onClick: handleOnTransferCertificate,
          },
          {
            label: "Add new appraisal",
            onClick: handleOnAddAppraisal,
          },
        ]
      );
    }

    return actionsMemo;
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = getActions();

  if (actions.length === 1) {
    actionComponents = [
      <Button
        key="single button"
        variant="contained"
        onClick={actions[0]?.onClick}
      >
        {actions[0]?.label}
      </Button>,
    ];
  } else {
    actionComponents = [
      <>
        <Button
          key="actions menu button"
          variant="contained"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Actions <MoreVert sx={{ ml: 1 }} />
        </Button>
        <Menu
          key="actions menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorPosition={{ top: 0, left: 0 }}
        >
          {actions.map((action, indx) => (
            <MenuItem
              key={`menu_item_${indx}`}
              onClick={() => {
                handleClose();
                action.onClick();
              }}
            >
              {action.label}
            </MenuItem>
          ))}
        </Menu>
      </>,
    ];
  }

  return {
    actions: actionComponents,
  };
};
