import * as React from "react";
import {
  FormLabel,
  FormControl,
  OutlinedInput,
  FormControlTypeMap,
  FormHelperText,
} from "@mui/material";
import FileUploadInput from "../../../routes/CreateCOA/components/FileUploadButton";
import { DimensionTypes } from "../../../routes/CreateCOA/art/sharedTypes";

type FormFieldInputTypes = {
  name: string;
  value?:
    | string
    | null
    | number
    | boolean
    | File
    | File[]
    | { h: number; w: number; d: number; type: DimensionTypes };
  error?: boolean;
  itemKey?: string;
  onChange: (evt: any) => void;
  required?: boolean;
  fieldName?: string;
  fileUpload?: boolean;
  helperText?: string;
  inputProps?: any;
  customInput?: React.ReactNode;
  formControlProps?: FormControlTypeMap<any, "div">;
  fileUploadOnChange?: (evt: any) => void;
};

export const FormFieldInput = ({
  name,
  error,
  value,
  required,
  onChange,
  fieldName,
  helperText,
  inputProps,
  fileUpload,
  customInput,
  formControlProps,
  fileUploadOnChange,
}: FormFieldInputTypes) => {
  const id = name + Math.random();

  const renderInputField = () => {
    if (customInput) {
      return customInput;
    } else if (fileUpload) {
      return (
        <FileUploadInput
          {...inputProps}
          id={`bootstrap-input-${id}`}
          onChange={fileUploadOnChange}
        />
      );
    } else {
      return (
        <OutlinedInput
          sx={{ padding: "2px 0" }}
          id={id}
          error={error}
          required={required}
          size="small"
          value={value}
          onChange={(evt) => onChange(evt.target.value)}
          {...inputProps}
        />
      );
    }
  };

  return (
    <FormControl {...formControlProps} sx={{ marginBottom: 2 }}>
      <FormLabel
        htmlFor={id}
        sx={{ marginBottom: "8px" }}
        required={required}
        error={error}
      >
        {fieldName}
      </FormLabel>

      {renderInputField()}

      <FormHelperText
        sx={{ margin: 0, marginTop: "8px" }}
        error={error}
        variant="outlined"
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
