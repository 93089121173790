import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../lib/helpers";
import { useSnackbar } from "notistack";
import {
  getunAuthedSingleUserCertificate,
  prefixUrl,
} from "../../../lib/api-client";
import CertificateInlineSVGDark from "../../components/atoms/certificateInlineSVG.dark";
import PageLayoutUnauth from "../../modules/PageLayout/PageLayoutUnauth";
import { CertificateSlideshow } from "../SingleCertificateView/components/CertificateSlideshow";
import { Link } from "react-router-dom";

import confettiImg from "../../../assets/img/confetti.svg";

export function AcceptTransferUnauth() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [certificate, set_certificate] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const certId = queryParams.get("certId");
    if (!certId) {
      return;
    }
    getunAuthedSingleUserCertificate(certId)
      .then((res) => {
        set_certificate(res.data.certificate);
      })
      .catch((updatePassError) => {
        if (
          updatePassError.response &&
          updatePassError.response.data &&
          updatePassError.response.data.message
        ) {
          enqueueSnackbar(`Error! Could not find certificate.`, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            "Error, unkown error occured. Please contact an admin if this issue persists",
            { variant: "error" }
          );
        }
      });
  }, []);

  console.log(window.location.search);

  return (
    <PageLayoutUnauth>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={6}>
          <Paper
            sx={{
              position: "relative",
              padding: 2,
              paddingTop: 3,
              textAlign: "left",
            }}
          >
            <img className="confetti-bg" src={confettiImg} />
            <Typography component="h1" variant="h4" sx={{ marginBottom: 2 }}>
              Accept Your Certificate
            </Typography>
            <Typography variant="subtitle1">
              Congratulations! Someone has sent you a Certificate of
              Authenticity and you're almost ready to claim it as your own.
              Please login to your account to continue. If you do not have an
              account with us, you must first create an account (Its free).
              Remember to use the email address that received this request.
            </Typography>
          </Paper>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
            <Link to={`/register${window.location.search}`}>
              <Button variant="contained">Register an account</Button>
            </Link>
            <Typography variant="subtitle1" sx={{ margin: 2 }}>
              OR
            </Typography>
            <Link
              to={{
                pathname: "/",
                search: `?redirect=${encodeURIComponent(window.location.href)}`,
              }}
            >
              <Button variant="contained" color="secondary">
                Login
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          {certificate && (
            <Box sx={{ marginBottom: 3 }}>
              <CertificateSlideshow certificate={certificate} />
            </Box>
          )}
        </Grid>
      </Grid>
    </PageLayoutUnauth>
  );
}
