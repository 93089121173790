import * as React from "react";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

import LogoSvg from "../../components/atoms/LogoSvg";
import RegisterForm from "../../components/molecules/RegisterForm/RegisterForm";
import { useGetUrlQuery } from "../../../lib/helpers";
import { Link as RouterLink } from "react-router-dom";
import Copyright from "library/components/atoms/Copyright-unauth";

export default function RegisterRoute() {
  const urlParams = useGetUrlQuery();

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        className="img-grid"
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?art)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          Every artwork deserves an ID
          <Typography
            style={{
              display: "block",
              fontSize: 18,
              marginTop: 12,
              textAlign: "center",
              lineHeight: "1.3em",
              letterSpacing: "0.8px",
            }}
          >
            Protect your value with digital certificates of authenticity you can
            trust!
          </Typography>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
            maxWidth: 400,
          }}
        >
          <Box>
            <LogoSvg />
          </Box>

          <Typography variant="h1">Create an account</Typography>

          <RegisterForm
            email={urlParams.get("recipient")}
            token={urlParams.get("token")}
          />

          <Grid container>
            <Grid item xs textAlign="center">
              <RouterLink to="/">
                <Link href="#" variant="body2">
                  Have an account? <strong>Log in</strong>
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}
