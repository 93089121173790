import * as React from "react";
import { ViewCertificateCreateOrder } from "library/features/ViewCertificateCreateOrderCard/components/ViewCertificateCreateOrder";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { getEthUsdConversion } from "lib/api-client";
import { useToggle } from "library/hooks/useToggle";
import { ViewCertificateCreateOrderConfirmation } from "./components/ViewCertificateCreateOrderConfirmation";
import { useSnackbar } from "notistack";
import { useCreateOrderMutation } from "./data/useCreateOrderMutation";
import {
  ActiveOrdersForCertType,
  useActiveOrdersForCertQuery,
} from "./data/useActiveOrdersForCertQuery";
import { Card } from "library/components/atoms/Card/Card";
import { AttachMoney } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ViewCertificateCancelOrder } from "./components/ViewCertificateCancelOrder";
import { useCancelOrderMutation } from "./data/useCancelOrderMutation";
import { ViewCertificateCancelOrderConfirmation } from "./components/ViewCertificateCancelOrderConfirmation";
import { ApolloQueryResult } from "@apollo/client/core/types";

export enum CreateOrderCurrencyType {
  USD = "usd",
  ETHEREUM = "eth",
}

export type CurrencyConversionType = {
  ethereum?: { usd: number };
};

export const ViewCertificateCreateOrderCardController = ({
  certificate,
}: {
  certificate: GetSingleCertificateType;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [conversion, setConversion] = React.useState<CurrencyConversionType>(
    {}
  );
  const [selectedCurrency, setSelectedCurrency] = React.useState(
    CreateOrderCurrencyType.ETHEREUM
  );
  const [selectedAmount, setSelectedAmount] = React.useState(0);
  const {
    value: isConfirmModalShowing,
    setToggleOn: showConfirmModal,
    setToggleOff: closeConfirmModal,
  } = useToggle(false);
  const {
    value: isCancelConfirmModalShowing,
    setToggleOn: showCancelConfirmModal,
    setToggleOff: closeCancelConfirmModal,
  } = useToggle(false);
  const {
    value: isRefetching,
    setToggleOn: setIsRefetchingTrue,
    setToggleOff: setIsRefetchingFalse,
  } = useToggle(false);
  const {
    refetch,
    loading: activeOrdersLoading,
    activeOrders,
  } = useActiveOrdersForCertQuery({
    tokenId: certificate.tokenId,
    certificateId: certificate._id,
  });
  const [createOrderMutation, { loading: isCreateMutationLoading }] =
    useCreateOrderMutation();

  const [cancelOrderMutation, { loading: isCancelMutationLoading }] =
    useCancelOrderMutation();

  const handleRefetchWithWait = () => {
    setIsRefetchingTrue();
    window.setTimeout(refetch, 3000);
  };

  React.useEffect(() => {
    getEthUsdConversion().then((data: any) => {
      if (data) {
        setConversion(data);
      }
    });
  }, []);

  const hasActiveOrders = React.useMemo(() => {
    setIsRefetchingFalse();
    if (!activeOrders) {
      return false;
    }

    return activeOrders?.web3?.result?.length > 0;
  }, [activeOrders]);

  const handleCreateOrder = async () => {
    if (!selectedAmount || selectedAmount <= 0) {
      closeConfirmModal();
      enqueueSnackbar("Please enter a positive number for the amount.", {
        variant: "error",
      });
      return;
    }

    try {
      const createOrderResult = await createOrderMutation({
        variables: {
          createOrderInputValues: {
            amount: selectedAmount,
            currency: selectedCurrency,
            certificateId: certificate._id,
          },
        },
      });

      if (!createOrderResult) {
        console.log(createOrderResult);
        closeConfirmModal();
        enqueueSnackbar(
          "An unknown error occured during the process, if this issue persist please contact support@certseal.ca.",
          {
            variant: "error",
          }
        );
        return;
      }

      // Success! show toast
      closeConfirmModal();
      enqueueSnackbar("Order has been created.", { variant: "success" });
      handleRefetchWithWait();
    } catch (exception: any) {
      if (exception?.response) {
        if (exception.response?.data?.message) {
          if (
            exception.response?.data?.message.includes(
              "Certificate has pending transfer request"
            )
          ) {
            enqueueSnackbar(
              "We cannot create an order because there is an active transfer request for this certificate.",
              {
                variant: "error",
              }
            );
            return;
          }
        }
      }
      closeConfirmModal();
      enqueueSnackbar(
        "An unknown error occured during the process, if this issue persist please contact support@certseal.ca.",
        {
          variant: "error",
        }
      );
    }
  };

  const handleCancelOrder = async () => {
    if (!certificate) {
      closeCancelConfirmModal();
      return;
    }

    try {
      const cancelOrderResult = await cancelOrderMutation({
        variables: {
          certificateId: certificate._id,
        },
      });

      if (!cancelOrderResult) {
        closeCancelConfirmModal();
        enqueueSnackbar(
          "An unknown error occured during the process, if this issue persist please contact support@certseal.ca.",
          {
            variant: "error",
          }
        );
        return;
      }

      // Success! show toast
      closeCancelConfirmModal();
      enqueueSnackbar("Order has been cancelled.", { variant: "success" });
      handleRefetchWithWait();
    } catch (exception) {
      console.log(exception);
      closeCancelConfirmModal();
      enqueueSnackbar(
        "An unknown error occured during the process, if this issue persist please contact support@certseal.ca.",
        {
          variant: "error",
        }
      );
    }
  };

  const artistRoyaltyFee: number | undefined =
    certificate?.additionalDetails.royaltyPercent ?? undefined;

  if (activeOrdersLoading || isRefetching) {
    return (
      <div style={{ marginTop: 24 }}>
        <Card title="Order Details" icon={<AttachMoney />}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size="20px" sx={{ color: "#d4d4d4" }} />
          </Box>
        </Card>
      </div>
    );
  }

  if (hasActiveOrders) {
    return (
      <>
        <ViewCertificateCancelOrder
          conversion={conversion}
          activeOrders={activeOrders}
          artistRoyalty={artistRoyaltyFee}
          showConfirmModal={showCancelConfirmModal}
        />
        {/* dialog for confirm cancel order */}
        <ViewCertificateCancelOrderConfirmation
          open={isCancelConfirmModalShowing}
          isBusy={isCancelMutationLoading}
          onClose={closeCancelConfirmModal}
          onConfirmOk={handleCancelOrder}
        />
      </>
    );
  }

  return (
    <>
      <ViewCertificateCreateOrder
        isLoading={activeOrdersLoading}
        conversion={conversion}
        artistRoyalty={artistRoyaltyFee}
        selectedAmount={selectedAmount}
        selectedCurrency={selectedCurrency}
        showConfirmModal={showConfirmModal}
        setSelectedAmount={setSelectedAmount}
        setSelectedCurrency={setSelectedCurrency}
      />

      {/* dialog for confirm create order */}
      <ViewCertificateCreateOrderConfirmation
        open={isConfirmModalShowing}
        isBusy={isCreateMutationLoading}
        onClose={closeConfirmModal}
        onConfirmOk={handleCreateOrder}
      />
    </>
  );
};
