import React, { useContext, useState } from "react";
import { now } from "moment";
import { useHistory } from "react-router";
import {
  Box,
  Grid,
  Paper,
  Button,
  Divider,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { store } from "../../../../lib/store";
import FileUploadInput from "../components/FileUploadButton";
import { RowInputGroup } from "../components/RowInputGroup";
import { BootstrapInput } from "../components/BootstrapInput";
import { createSimpleCOA } from "../../../../lib/api-client";

const SimpleCOARoute = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { state } = useContext(store);
  const [formValues, set_formValues] = useState({
    itemSku: "",
    itemName: "",
    itemImage: null,
    itemDescription: "",
  });
  const [isLoading, set_isLoading] = useState(false);

  const updateFormValues = (key, value) => {
    let newValues = { ...formValues, [key]: value };
    set_formValues(newValues);
  };

  const formValuesValidate = () => {
    let validationErrors = [];

    if (!formValues.itemName || formValues.itemName === "") {
      validationErrors.push("Item name is required");
    }

    if (!formValues.itemSku || formValues.itemSku === "") {
      validationErrors.push("Item sku is required");
    }

    if (!formValues.itemImage || formValues.itemImage.length <= 0) {
      validationErrors.push("Item image is required");
    }

    if (formValues.itemDescription.length > 300) {
      validationErrors.push(
        "Item description must be less than 300 characters"
      );
    }

    return validationErrors;
  };

  const handleFormSubmit = () => {
    set_isLoading(true);
    const validationErrors = formValuesValidate();
    if (validationErrors.length > 0) {
      // toast error
      enqueueSnackbar(`Form field errors: ${validationErrors[0]}`, {
        variant: "error",
      });

      set_isLoading(false);
      return;
    }

    let formData = new FormData();

    formData.append("userId", state.currentUser.id);
    formData.append("certType", "simple");

    Object.keys(formValues).map((key, indx) => {
      formData.append(key, formValues[key]);
    });

    createSimpleCOA(formData)
      .then(() => {
        // success toast

        set_isLoading(false);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
        let errMsg = "Something went wrong with the creation.";
        if (err.response && err.response.data && err.response.data.message) {
          errMsg = err.response.data.message;

          if (err.response.data.message === "Failed! Not enough credits!") {
            enqueueSnackbar(`Not enough credits to create certificate`, {
              variant: "error",
            });
            return;
          }
        }

        // unknown error toast
        enqueueSnackbar(
          `An error occured with your request, please try again. If the error persists, please contact us at support@certseal.ca`,
          { variant: "error" }
        );
        set_isLoading(false);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5">
              Create a Simple Certificate Of Authenticity
            </Typography>
            <Typography sx={{ margin: "12px 0" }}>
              A simple COA is a certificate that can be applied to any physical
              product. All we require is Photos of the item and a SKU number (if
              applicable).
            </Typography>
            <Divider sx={{ margin: "24px 0" }}></Divider>

            <RowInputGroup
              groupHeading="Generic Info"
              groupSubtitle="Generic information about your certificate."
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: "14px" }}
              >
                <InputLabel shrink htmlFor="bootstrap-input">
                  Item Name*
                </InputLabel>
                <BootstrapInput
                  required
                  fullWidth
                  placeholder="Mitch Marner Auto Patch | 2020 Series One"
                  value={formValues.itemName}
                  onChange={(evt) =>
                    updateFormValues("itemName", evt.target.value)
                  }
                />
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: "14px" }}
              >
                <InputLabel shrink htmlFor="bootstrap-input">
                  Item Sku*
                </InputLabel>
                <BootstrapInput
                  required
                  fullWidth
                  placeholder="3/8"
                  value={formValues.itemSku}
                  onChange={(evt) =>
                    updateFormValues("itemSku", evt.target.value)
                  }
                />
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: "14px" }}
              >
                <InputLabel shrink htmlFor="bootstrap-input">
                  Item Description*
                </InputLabel>
                <BootstrapInput
                  required
                  fullWidth
                  placeholder="weight: 10lbs | size: 30cm | color: black & blue with red trim"
                  value={formValues.itemDescription}
                  multiline={true}
                  rows={4}
                  maxRows={4}
                  maxLength={300}
                  onChange={(evt) =>
                    updateFormValues("itemDescription", evt.target.value)
                  }
                />
                <FormHelperText>
                  Describe the item in detail including attributes such as
                  weight, size, colour, etc. Max length is 300 characters.
                </FormHelperText>
              </FormControl>

              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: "14px" }}
              >
                <InputLabel shrink htmlFor="bootstrap-input">
                  Item Image*
                </InputLabel>
                <FileUploadInput
                  onChange={(files) => updateFormValues("itemImage", files[0])}
                />
                <FormHelperText>
                  This is the main image for the item and will be displayed in
                  the meta data of the certificate
                </FormHelperText>
              </FormControl>
            </RowInputGroup>

            <RowInputGroup
              groupHeading="Create"
              groupSubtitle="Make sure you are confident with the values you have entered! Blockchain is immutable meaning these values cannot be changed ever again!"
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={isLoading}
                  variant="contained"
                  onClick={handleFormSubmit}
                >
                  Create Certificate
                </Button>
              </Box>
            </RowInputGroup>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default SimpleCOARoute;
