import * as React from "react";
import FullPageTakeover from "library/modules/Takeover/TakeoverController";
import { FullPageAction } from "library/modules/FullPageAction/FullPageAction";
import { Button } from "@mui/material";
import { useGetTransferCertificateSteps } from "./hooks/useGetTransferCertificateSteps";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";

type TransferCertificateTakeoverControllerType = {
  onClose: () => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
};

export enum TransferType {
  SELL = "sell",
  DONATE = "donate",
  TRANSFER = "transfer",
}

export type TransferFormValuesType = {
  email: string;
  confirmEmail: string;
  transferType: TransferType;
};

export const TransferCertificateTakeoverController = ({
  onClose,
  onSuccess,
  certificate,
}: TransferCertificateTakeoverControllerType) => {
  const [transferFormValues, setTransferFormValues] =
    React.useState<TransferFormValuesType>({
      email: "",
      confirmEmail: "",
      transferType: TransferType.TRANSFER,
    });

  const updateFormValues = ({ name, value }: { name: string; value: any }) => {
    setTransferFormValues({
      ...transferFormValues,
      [name]: value,
    });
  };

  const { actions, mainContent, secondaryContent } =
    useGetTransferCertificateSteps({
      onClose,
      onSuccess,
      certificate,
      updateFormValues,
      transferFormValues,
    });

  return (
    <FullPageTakeover onClose={onClose}>
      <FullPageAction
        style={{ height: "calc(100% - 65px)" }}
        actions={actions}
        mainContent={mainContent}
        secondaryContent={secondaryContent}
      />
    </FullPageTakeover>
  );
};
