import * as React from "react";
import { Divider } from "@mui/material";
import Title from "library/components/atoms/Title";
import {
  RouteHeaderWrapper,
  RouteHeaderTitleWrapper,
  RouteHeaderTitleActionsWrapper,
} from "library/components/molecules/RouteHeader/components/RouteHeader.elements";

type RouteHeaderType = {
  title: string;
  actions?: React.ReactNode;
};

export const RouteHeader = ({ title, actions }: RouteHeaderType) => {
  return (
    <RouteHeaderWrapper>
      <RouteHeaderTitleWrapper>
        <Title>{title}</Title>

        <RouteHeaderTitleActionsWrapper>
          {actions}
        </RouteHeaderTitleActionsWrapper>
      </RouteHeaderTitleWrapper>

      <Divider
        style={{ marginTop: 24, marginBottom: 24, borderColor: "transparent" }}
      />
    </RouteHeaderWrapper>
  );
};
