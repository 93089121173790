import * as React from "react";
import { CreateArtCOAFormValuesTypes, DimensionTypes } from "./sharedTypes";
import { CreateArtCertificatePresentation } from "./components/ArtCOA.elements";
import { CREATE_ART_CERTIFICATE } from "./data/CreateArtCOAMutation";
import { useMutation } from "@apollo/client";
import { ValidationError } from "library/shared/sharedTypes";
import { validateFormFields } from "./selectors/validateFormFields";
import { useSnackbar } from "notistack";
import { uploadArtImages } from "lib/api-client";
import { useHistory } from "react-router-dom";
import { useGetEditionsQuery } from "library/routes/Editions/Editions/data/GetEditionsQuery";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";
import { MenuItem } from "@mui/material";
import { useGetSearchParams } from "library/hooks/useGetSearchParams";
import { selectDefaultValues } from "./selectors/selectDefaultValues";
import { useGetCertificateQuery } from "library/data/getSingleCertificate";

const CreateArtCOAController = () => {
  const searchParams = useGetSearchParams();
  const editionId = searchParams.get("editionId");
  const templateId = searchParams.get("templateId");
  const { certificate } = useGetCertificateQuery({ id: templateId ?? "" });

  const [formValues, _setFormValues] =
    React.useState<CreateArtCOAFormValuesTypes>(
      selectDefaultValues({ editionId })
    );

  React.useEffect(() => {
    _setFormValues(selectDefaultValues({ editionId, template: certificate }));
  }, [certificate, selectDefaultValues]);

  const updateFormValues = (
    key: string,
    value:
      | boolean
      | number
      | string
      | File
      | File[]
      | {
          w: number;
          h: number;
          d: number;
          type: DimensionTypes;
        }
  ) => {
    const newValues = {
      ...formValues,
      [key]: value,
    } as CreateArtCOAFormValuesTypes;
    _setFormValues(newValues);
  };

  const [useCreateArtCertificateMutation, { loading }] = useMutation(
    CREATE_ART_CERTIFICATE
  );

  const user = useGetCurrentUser();
  const { collections: userCollectionsData } = useGetEditionsQuery({
    id: user?.id ?? "",
  });

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isBusy, setIsBusy] = React.useState(false);
  const [validationErrors, setValidationErrors] = React.useState<
    ValidationError[]
  >([]);

  const handleOnCreateCertificateHandler = () => {
    setValidationErrors([]);
    setIsBusy(true);
    const errors = validateFormFields(formValues);

    if (errors.length) {
      enqueueSnackbar(
        "Errors detected in the form. Please ensure all require fields are filled in",
        {
          variant: "error",
        }
      );
      setValidationErrors(errors);
      setIsBusy(false);
      return;
    }

    useCreateArtCertificateMutation({
      variables: {
        formValues: {
          ...formValues,
          framed: undefined,
          signed: undefined,
          isEdition: undefined,
          hasRoyalty: undefined,
        },
      },
    })
      .then((res) => {
        const certId = res.data.CreateArtCertificate;
        uploadArtImages({
          itemImage: formValues.itemImage,
          certId,
        })
          .then(() => {
            enqueueSnackbar("Certificate created!", {
              variant: "success",
            });
            history.push("/");
          })
          .catch(() => {
            enqueueSnackbar(
              "An error occured while creating a certificate. If this continues please contact an admin for help",
              {
                variant: "error",
              }
            );
            setIsBusy(false);
          });
      })
      .catch(() => {
        enqueueSnackbar(
          "An error occured while creating a certificate. If this continues please contact an admin for help",
          {
            variant: "error",
          }
        );
        setIsBusy(false);
      });
  };

  const userEditionsOptions = React.useMemo(() => {
    if (userCollectionsData && userCollectionsData.length) {
      return userCollectionsData.map((collection) => (
        <MenuItem value={collection._id}>
          {decodeURIComponent(collection.collectionName ?? "")}
        </MenuItem>
      ));
    } else {
      return null;
    }
  }, [userCollectionsData]);

  return (
    <CreateArtCertificatePresentation
      isBusy={isBusy}
      loading={loading}
      formValues={formValues}
      updateFormValues={updateFormValues}
      validationErrors={validationErrors}
      userEditionsOptions={userEditionsOptions}
      handleOnCreateCertificateHandler={handleOnCreateCertificateHandler}
    />
  );
};

export default CreateArtCOAController;
