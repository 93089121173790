import { gql, useQuery } from "@apollo/client";

export type UserCreatedCertificatesData = {
  _id: string;
  tokenId: number | null;
  itemName: string;
};

type UserCreatedCertificatesQueryData = {
  UserCreatedCertificates: UserCreatedCertificatesData[];
};

type UserCreatedCertificatesQueryVariables = {
  id: string;
  limit?: number;
};

export const getUserCertificates = gql`
  query GetUserCreatedCertificates($id: ID!, $limit: Int) {
    UserCreatedCertificates(id: $id, limit: $limit) {
      _id
      tokenId
      itemName
    }
  }
`;

export const useUserCreatedCertificatesQuery = ({
  id,
  limit,
}: {
  id: string;
  limit?: number;
}) => {
  const result = useQuery<
    UserCreatedCertificatesQueryData,
    UserCreatedCertificatesQueryVariables
  >(getUserCertificates, {
    fetchPolicy: "cache-and-network",
    variables: { id: id, limit },
  });

  return {
    ...result,
    tokenIds: result?.data?.UserCreatedCertificates,
  };
};
