export enum DimensionTypes {
  ft = "ft",
  cm = "cm",
  inch = "inch",
}
export type CreateArtCOAFormValuesTypes = {
  tags: string[];
  notes: string;
  signed: boolean;
  framed: boolean;
  edition: string;
  itemSku: string;
  category: string;
  itemName: string;
  signedBy: string;
  isEdition: boolean;
  itemImage: File[];
  artistName: string;
  dimensions: {
    h: number;
    w: number;
    d: number;
    type: DimensionTypes;
  };
  hasRoyalty: boolean;
  itemMedium: string;
  artworkYear: string;
  frameDetails: string;
  editionNumber: number;
  royaltyPercent: number;
  frameDimensions: {
    h: number;
    w: number;
    d: number;
    type: DimensionTypes;
  };
  itemDescription: string;
  paperSpecifications: string;
};

export type updateFormValuesTypes = (
  key: string,
  value:
    | string
    | File
    | File[]
    | number
    | boolean
    | {
        w: number;
        h: number;
        d: number;
        type: DimensionTypes;
      }
) => void;

export type CreateArtCOAFormTypes = {
  formValues: CreateArtCOAFormValuesTypes;
  updateFormValues: updateFormValuesTypes;
};
