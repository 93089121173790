import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Box,
  Chip,
  Table,
  Tooltip,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";
import { Spa, Sell, Autorenew } from "@mui/icons-material";
import "./styles.scss";
import {
  getImxTokenInfoQuery,
  getImxTokenTransactions,
} from "../../../../../lib/api-client";
import { ethers } from "ethers";
const CertificateTimeline = ({ certificateId, originalMinter }) => {
  const [imxTokenInfo, setImxTokenInfo] = useState(null);
  const [timeLine, set_timeLine] = useState([]);
  const [isLoading, set_isLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getImxTokenInfoQuery(certificateId)
      .then((resp) => {
        setImxTokenInfo(resp.data);
        getImxTokenTransactions(certificateId)
          .then((transactionsResponse) => {
            const [orders, transactions, withdrawals, filledOrders] =
              transactionsResponse.data;
            const list = [
              ...orders.result.map((order) => ({
                ...order,
                transactionType: "sale",
              })),
              ...transactions.result.map((order) => ({
                ...order,
                transactionType: "transfer",
              })),
              ...withdrawals.result.map((order) => ({
                ...order,
                transactionType: "withdrawal",
              })),
            ]
              .flat()
              .sort((a, b) => {
                if (a.timestamp > b.timestamp) {
                  return 1;
                } else if (a.timestamp < b.timestamp) {
                  return -1;
                } else {
                  return 0;
                }
              })
              .reverse();
            list.push({
              user: "0000",
              receiver: originalMinter,
              timestamp: resp.data.created_at,
              transactionType: "MINT",
            });
            set_timeLine(list);
          })
          .catch((err) => {
            console.log(err);
            setHasError(true);
          });
        set_isLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
      });
  }, []);

  if (hasError) {
    return <p>This certificate isn't minted to blockchain yet.</p>;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const TooltipCaption = React.forwardRef((props, ref) => (
    <Box {...props} ref={ref}>
      {props.children}
    </Box>
  ));

  const trimUserAddress = (address) => {
    return address.length > 7 ? `${address.slice(0, 7)}...` : address;
  };

  const TypeIcon = ({ type }) => {
    if (type === "sale") {
      return <Sell sx={{ fontSize: "16px" }} />;
    }
    if (type === "MINT") {
      return <Spa sx={{ fontSize: "16px" }} />;
    }
    if (type === "transfer") {
      return <Autorenew sx={{ fontSize: "16px" }} />;
    }
    return null;
  };

  const TransactionTypeColumnRender = ({ type }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <TypeIcon type={type} />
      <Typography sx={{ textTransform: "capitalize" }}>
        {type.toLowerCase()}
      </Typography>
    </div>
  );

  const TransactionUserColumnRender = ({ transaction }) => {
    const address = transaction.user || transaction.sender;
    const trimmedAddress = trimUserAddress(address);
    let event = "";

    if (transaction?.transactionType === "sale") {
      event = "Listed by ";
    }

    if (transaction?.transactionType === "transfer") {
      event = "Sent by ";
    }

    return (
      <Typography>
        {event}
        <a href={`https://immutascan.io/address/${address}`} target="_blank">
          {trimUserAddress(address)}
        </a>
      </Typography>
    );
  };

  const pillcolor = (transaction) =>
    transaction.transactionType === "MINT" || transaction.status === "success"
      ? "success"
      : transaction.status === "active"
      ? "info"
      : transaction.status === "filled"
      ? "success"
      : "default";

  return (
    <TableContainer>
      <Table aria-label="timeline table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>User</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeLine.map((transaction, indx) => {
            const sender = transaction.user || transaction.sender;
            const price =
              transaction.transactionType === "sale"
                ? transaction?.buy?.data?.quantity_with_fees
                : null;
            const weiethprice = price ? price / 1000000000000000000 : null;

            return (
              <TableRow
                key={`_${indx}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {weiethprice ? (
                    <Tooltip title={`Price: ${weiethprice} (eth)`}>
                      <TooltipCaption>
                        <TransactionTypeColumnRender
                          type={transaction.transactionType}
                        />
                      </TooltipCaption>
                    </Tooltip>
                  ) : (
                    <TransactionTypeColumnRender
                      type={transaction.transactionType}
                    />
                  )}
                </TableCell>
                <TableCell scope="row">
                  <TransactionUserColumnRender transaction={transaction} />
                </TableCell>

                <TableCell align="right">
                  <Chip
                    sx={{ textTransform: "capitalize" }}
                    size="small"
                    label={
                      transaction.transactionType === "MINT"
                        ? "Minted"
                        : transaction.status
                    }
                    color={pillcolor(transaction)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    {moment.utc(transaction.timestamp).fromNow()}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CertificateTimeline.propTypes = {};

export default CertificateTimeline;
