import * as React from "react";
import styled from "@emotion/styled";

import {
  CardActionArea,
  Card,
  CardContent,
  Box,
  Typography,
} from "@mui/material";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

const IconContainer = styled.div({
  "> svg": {
    fontSize: 42,
  },
});

export default function CreateNewCertCardButton({
  disabled,
  title,
  icon,
  desc,
  onClick,
}) {
  const disabledColor = { backgroundColor: "#ddd" };
  return (
    <Card style={disabled && disabledColor}>
      <CardActionArea onClick={onClick} disabled={disabled}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <CardContent style={{ textAlign: "center" }}>
            <IconContainer>
              {icon ? icon : <AssignmentOutlinedIcon />}
            </IconContainer>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {desc}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
}
