import styled from "@emotion/styled";

export const TransferCertificateMainContentWrapper = styled.div({
  gap: 24,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  justifyContent: "center",
});

export const TransferCertificateSelect = styled.select({
  border: "1px solid #ced4da",
  padding: 12,
  marginTop: 24,
  background: "#fcfcfb",
  borderRadius: 4,
});
