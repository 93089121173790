import React, { useState } from "react";

import { Grid, Tabs, Tab } from "@mui/material";

import UsersTable from "./components/UsersTable.js";
import ApprovalsTable from "./components/ApprovalsTable.js";

const AdminViewRoute = () => {
  const [selectedTab, set_selectedTab] = useState(0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tabs value={0} variant="scrollable" scrollButtons={false}>
            <Tab
              value={selectedTab}
              label="Certificates"
              onClick={() => set_selectedTab(0)}
            />
            <Tab label="Users" onClick={() => set_selectedTab(1)} />
          </Tabs>
          {selectedTab === 0 ? <ApprovalsTable /> : <UsersTable />}
        </Grid>
      </Grid>
    </>
  );
};

export default AdminViewRoute;
