import * as React from "react";
import { useHistory } from "react-router-dom";
import { useGetSearchParams } from "library/hooks/useGetSearchParams";

export const useQueryParams = ({ pathname }: { pathname: string }) => {
  const params = useGetSearchParams();
  const history = useHistory();

  const currentParams: any = React.useMemo(() => {
    const searchParamsList: any = {};
    params.forEach((value, key) => (searchParamsList[key] = value));
    return searchParamsList;
  }, [params]);

  const appendQueryParam = ({ key, value }: { key: string; value: string }) => {
    // has key and matches current value; do nothing
    if (currentParams[key] === value) {
      return;
    }

    // no key exists so push to params
    if (!currentParams[key]) {
      params.append(key, value);
      history.push({ pathname: pathname, search: params.toString() });
    }

    // has key but value does not match so replace
    if (currentParams[key] !== value) {
      params.delete(key);
      params.append(key, value);
      history.push({ pathname: pathname, search: params.toString() });
    }
  };

  const deleteQueryParam = (key: string) => {
    params.delete(key);
    history.push({ pathname: pathname, search: params.toString() });
  };

  return {
    currentParams,
    appendQueryParam,
    deleteQueryParam,
  };
};
