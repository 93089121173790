import * as React from "react";
import {
  Grid,
  Typography,
  IconButton,
  Button,
  Input,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { WallpaperOutlined, ChevronRight, Search } from "@mui/icons-material";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";
import { useGetUserCertificatesQuery } from "./data/getDashboardCertificates";
import { Card } from "library/components/atoms/Card/Card";
import {
  DashboardRouteCTAButtonText,
  DashboardRouteCTAButtonWrapper,
} from "./components/DashbaordRoute.elements";
import { useHistory } from "react-router-dom";
import { useGetEditionsQuery } from "../Editions/Editions/data/GetEditionsQuery";
import { GetStartedController } from "../../features/GetStarted/GetStartedController";
import { LatestTransfersCardController } from "./controllers/LatestTransfersCardController";
import { LatestCertificatesController } from "./controllers/LatestCertificatesController";
import { WalletWidgetController } from "./controllers/WalletWidgetController";
import { TopClientsController } from "./controllers/TopClientsController";
import { WelcomeController } from "./controllers/WelcomeController";

export const DashboardRouteController = () => {
  const user = useGetCurrentUser() as UserCookieType;
  const history = useHistory();
  const [searchValue, setSearchValue] = React.useState("");

  const canCreateCertificates =
    user.accountType === "artist" || user.accountType === "company";

  const { certificates: userCertificates, loading: userCertificatesLoading } =
    useGetUserCertificatesQuery({ id: user.id ?? "" });

  const { collections: userEditions, loading: userEditionsLoading } =
    useGetEditionsQuery({
      id: user.id ?? "",
    });

  const certificatesCountStats = React.useMemo(() => {
    const counts = {
      onhold: 0,
      minted: 0,
      revoked: 0,
      approved: 0,
    };

    userCertificates?.forEach(
      (cert) => (counts[cert.status] = counts[cert.status] + 1)
    );

    return counts;
  }, [userCertificates]);

  const renderCertificatesComponent = React.useMemo(
    () => <LatestCertificatesController />,
    []
  );

  const renderWelcomeComponent = React.useMemo(() => <WelcomeController />, []);

  if (!user) {
    <p>error</p>;
  }

  if (userCertificatesLoading || userEditionsLoading) {
    return <p>loading</p>;
  }

  const handleSearchRedirect = () => {
    if (searchValue === "") {
      return;
    }
    history.push(
      `/certificates?search=${encodeURIComponent(searchValue)}${
        canCreateCertificates ? "&createdOnly=true" : ""
      }`
    );
  };

  return (
    <Grid container spacing={6}>
      <Grid item sm={12} textAlign="center">
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          size="small"
          sx={{ background: "white", width: "380px" }}
          placeholder="Search for certificates"
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearchRedirect();
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                sx={{ color: "rgba(0,0,0,0.2)" }}
                aria-label="toggle password visibility"
                onClick={() => alert("search")}
                onMouseDown={handleSearchRedirect}
                edge="end"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      <Grid key="left" item md={8} sm={7} xs={12}>
        {/* Latest certificates */}
        {canCreateCertificates && userCertificates?.length === 0
          ? renderWelcomeComponent
          : renderCertificatesComponent}

        {/* Latest transactions */}

        {/* Inbox */}

        {/* Social feed */}

        <LatestTransfersCardController id={user.id} />
      </Grid>
      <Grid key="right" item md={4} sm={5} xs={12}>
        <WalletWidgetController />
        <TopClientsController />
      </Grid>
    </Grid>
  );
};
