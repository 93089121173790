import { gql, useQuery } from "@apollo/client";

export type GetOwnerBalanceType = {
  token_address: string;
  symbol: string;
  balance: {
    type: string;
    hex: string;
  };
  preparing_withdrawal: {
    type: string;
    hex: string;
  };
  withdrawable: {
    type: string;
    hex: string;
  };
};

export type GetOwnerBalancesType = {
  l1Balance: {
    hex: string;
    type: string;
  };
  result: {
    token_address: string;
    symbol: string;
    balance: {
      type: string;
      hex: string;
    };
    preparing_withdrawal: {
      type: string;
      hex: string;
    };
    withdrawable: {
      type: string;
      hex: string;
    };
  }[];
};

const GET_OWNER_BALANCES = gql`
  query ActiveOrdersForCert {
    getOwnerBalances
  }
`;

export const useGetOwnerBalances = () => {
  const query = useQuery<{ getOwnerBalances: GetOwnerBalancesType }>(
    GET_OWNER_BALANCES
  );

  return {
    ...query,
    balances: query?.data?.getOwnerBalances,
  };
};
