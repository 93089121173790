import * as React from "react";
import { Box, Typography } from "@mui/material";
import { getCreateArtFormFields } from "../selectors/getCreateArtFormFields";
import { RowInputField } from "../../components/RowInputField";
import type {
  updateFormValuesTypes,
  CreateArtCOAFormTypes,
  CreateArtCOAFormValuesTypes,
} from "../sharedTypes";
import type { ValidationError } from "library/shared/sharedTypes";

type FormFieldTypes = {
  hidden?: boolean;
  itemKey: string;
  fieldName: string;
  required?: boolean;
  inputProps: any;
  fileInput?: boolean;
  helperText?: string;
  halfWidth?: boolean;
};

type RenderInputRowType = {
  fields: FormFieldTypes[];
  formValues: CreateArtCOAFormValuesTypes;
  validationErrors: ValidationError[];
  updateFormValues: updateFormValuesTypes;
};

const renderInputRow = ({
  fields,
  formValues,
  validationErrors,
  updateFormValues,
}: RenderInputRowType) =>
  fields.map(
    ({ itemKey, fieldName, ...rest }: FormFieldTypes, indx: number) =>
      !rest.hidden && (
        <RowInputField
          {...rest}
          key={indx}
          error={validationErrors.find((err) => err.key === itemKey)?.message}
          style={rest.halfWidth && { width: "calc(50% - 12px)" }}
          value={formValues[itemKey as keyof CreateArtCOAFormValuesTypes]}
          itemKey={itemKey}
          onChange={(val: string | File) => updateFormValues(itemKey, val)}
          fieldName={fieldName}
        />
      )
  );

const CreateArtCOAForm = ({
  formValues,
  updateFormValues,
  validationErrors,
  userEditionsOptions,
}: CreateArtCOAFormTypes & {
  validationErrors: ValidationError[];
  userEditionsOptions: React.ReactNode;
}) => {
  const formFields = getCreateArtFormFields({
    formValues,
    updateFormValues,
    userEditionsOptions,
  });

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px 24px" }}>
      <Typography sx={{ margin: "12px 0" }}>
        An art COA is a certificate that represents an authentic artwork. Only
        artists are able to create this type of certificate.
      </Typography>

      {renderInputRow({
        fields: formFields,
        formValues,
        validationErrors,
        updateFormValues,
      })}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="subtitle1" textAlign="center" mb={2}>
          Please ensure you have entered everything correctly! Blockchain is
          immutable meaning updates or revisions are <strong>not</strong>{" "}
          possible
        </Typography>
      </Box>
    </div>
  );
};

export default CreateArtCOAForm;
