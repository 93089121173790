import { Box } from "@mui/material";
import { getImxClient } from "lib/imx/link/imxLinkController";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ViewCertificateSkeletons } from "../ViewCertificateRevamp/components/ViewCertificateSkeletons";
import { VerifyCertificateController } from "./controllers/VerifyCertificateController";
import { useSingleCertificateForVerifyQuery } from "./data/getSingleCertificateForVerify";
import { ImmutableOrderStatus } from "@imtbl/imx-sdk";
import "./styles.scss";

export const VerifyCertificateUnAuthController = () => {
  const { id } = useParams<{ id: string }>();
  const [imxData, setImxData] = React.useState<any>();

  const {
    certificate,
    error: certificateQueryError,
    loading: certificateQueryLoading,
  } = useSingleCertificateForVerifyQuery({ id });

  React.useEffect(() => {
    getImxClient()
      .then(async ({ client }) => {
        const promises = await Promise.all([
          client.getAsset({
            id: `${certificate?.tokenId}`,
            address: certificate?.blockchainData.imx.contract_address,
          }),
          client.getOrders({
            status: ImmutableOrderStatus.active,
            page_size: 1,
            include_fees: true,
            sell_token_id: `${certificate?.tokenId}`,
            sell_token_address:
              certificate?.blockchainData.imx.contract_address,
          }),
        ]);

        setImxData(promises);
      })
      .catch((err) => console.log(err));
  }, [certificate]);

  if (certificateQueryLoading) {
    return <ViewCertificateSkeletons />;
  }

  if (certificateQueryError || !certificate) {
    return (
      <p>
        An error occured, either the certificate ID is incorrect or we have
        experienced a bug. If this issue persists, please contact
        support@certseal.ca.
      </p>
    );
  }

  return (
    <Box sx={{ maxWidth: 1400, m: "0 auto 42px" }}>
      <VerifyCertificateController
        imxData={imxData}
        certificate={certificate}
      />
    </Box>
  );
};
