import React, { useEffect, useState, useContext } from "react";
import { now } from "moment";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import "./styles.scss";
import { store } from "../../../lib/store";
import CheckoutStep from "./modules/CheckoutStep";
import Title from "../../components/atoms/Title";
import { ButtonSelectRadio } from "./components/ButtonSelectRadio";

const stripeApiKeys = {
  test: "pk_test_51JeYufHsBxryJaoPNnKSeMMEdNtPfvrPdDj4k8PlB0I4WTmgpnr3NqgwC5nxhCVut8o9afbQsYAzlvixwt6KDB7400kMdNqmSd",
  prod: "pk_live_51JeYufHsBxryJaoPoxFMAoDMFvBCnkhEGLzjpzcYC1UAYkmTFWG7OB9xoBMLBW1xitxz7CgLkKhtBx1MhAKT72Nw004r4DFBbr",
};

const isDev = process.env.NODE_ENV !== "production";

const PurchaseCreditsRoute = (props) => {
  const { state } = useContext(store);
  const { enqueueSnackbar } = useSnackbar();

  const [stepIndex, set_stepIndex] = useState(0);
  const [amount, set_amount] = useState(15);
  const [amountType, set_amountType] = useState(0);
  const promise = loadStripe(isDev ? stripeApiKeys.test : stripeApiKeys.prod);

  const handlePriceSelectChange = (type, amount) => {
    set_amountType(type);
    set_amount(amount);
  };

  const goToCheckout = () => {
    if (amount < 1 || amount > 1000) {
      enqueueSnackbar("Please enter a valid amount", { variant: "error" });
      return;
    }

    set_stepIndex(1);
  };

  const renderStepsContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item md={3} sm={6} xs={12}>
              <ButtonSelectRadio
                selected={amountType === 0}
                title="$15"
                subtitle="15 Credits"
                onClick={() => handlePriceSelectChange(0, 15)}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <ButtonSelectRadio
                selected={amountType === 1}
                title="$50"
                subtitle="50 Credits"
                onClick={() => handlePriceSelectChange(1, 50)}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <ButtonSelectRadio
                selected={amountType === 2}
                title="$100"
                subtitle="100 Credits"
                onClick={() => handlePriceSelectChange(2, 100)}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <ButtonSelectRadio
                selected={amountType === 3}
                title="Custom"
                subtitle="Enter a custom amount"
                onClick={() => handlePriceSelectChange(3, 25)}
              />
            </Grid>

            {amountType === 3 && (
              <Grid
                item
                xs={12}
                sx={{
                  marginTop: "24px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TextField
                  label="Custom Amount"
                  helperText="Enter an amount between $1 and $1000"
                  onChange={(evt) =>
                    handlePriceSelectChange(3, evt.target.value)
                  }
                  value={amount}
                  min="1"
                  max="1000"
                  type="number"
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button onClick={goToCheckout} variant="contained">
                Proceed To Checkout
              </Button>
            </Grid>
          </>
        );

      case 1:
        return (
          <Grid item xs={12}>
            <Elements stripe={promise}>
              <CheckoutStep
                amount={parseInt(amount)}
                currentUser={state.currentUser}
              />
            </Elements>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Title>Purchase Credits</Title>
          <strong>Why credits?</strong>
          <Typography>
            Because the price of crypto fluctuates constantly, we wanted to make
            sure that the price of our certificates always stay the same.
            Credits also allow us to accept CAD instead of crypto which make
            things a lot easier for those who don't want to worry about wallets
            and private keys.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              margin: "42px 0",
            }}
          >
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              {stepIndex === 0 ? "Select Amount" : `Total: $${amount}`}
            </Typography>
          </Box>
          <Grid spacing={4} container>
            {renderStepsContent()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PurchaseCreditsRoute;
