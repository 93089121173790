import * as React from "react";
import { Button, Typography, MenuItem } from "@mui/material";
import {
  AddAppraisalSelect,
  AddAppraisalMainContentWrapper,
} from "./AddAppraisal.elements";
import TransferCertificateImg from "library/../assets/img/provenance.png";
import { RowInputField } from "library/routes/CreateCOA/components/RowInputField";
import { AddAppraisalFormValuesType } from "../AddAppraisalTakeoverController";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { AppraisalReasonType } from "../sharedTypes";

const validateTransferValues = (values: AddAppraisalFormValuesType) => {
  const errors = null;

  return errors;
};

export const AddAppraisalStep2 = ({
  isBusy,
  prevStep,
  nextStep,
  setIsBusy,
  onSuccess,
  certificate,
  updateFormValues,
  transferFormValues,
}: {
  isBusy: boolean;
  prevStep: () => void;
  nextStep: () => void;
  setIsBusy: (value: boolean) => void;
  onSuccess: () => void;
  certificate: GetSingleCertificateType;
  updateFormValues: ({ name, value }: { name: string; value: any }) => void;
  transferFormValues: AddAppraisalFormValuesType;
}) => {
  const handleTransferOwnership = () => {
    const validateFormValues = validateTransferValues(transferFormValues);

    if (validateFormValues) {
      alert(validateFormValues);
      return;
    }

    setIsBusy(true);

    alert(JSON.stringify(transferFormValues));
  };

  const mainContent = (
    <AddAppraisalMainContentWrapper>
      <Typography variant="h2">Details</Typography>

      <div>
        <RowInputField
          fieldName="Appraisal Reason"
          helperText="This helps us better display provenance"
          customInput={
            <AddAppraisalSelect
              id="bootstrap-input-type"
              value={transferFormValues.appraisalReason}
              onChange={(evt) =>
                updateFormValues({
                  name: "appraisalReason",
                  value: evt?.target?.value ?? "",
                })
              }
            >
              <option value={AppraisalReasonType.DONATING_WORK}>
                {AppraisalReasonType.DONATING_WORK}
              </option>
              <option value={AppraisalReasonType.MANAGE_LOSS}>
                {AppraisalReasonType.MANAGE_LOSS}
              </option>
              <option value={AppraisalReasonType.OTHER}>
                {AppraisalReasonType.OTHER}
              </option>
              <option value={AppraisalReasonType.PLANNING_TO_SELL_OR_BUY}>
                {AppraisalReasonType.PLANNING_TO_SELL_OR_BUY}
              </option>
              <option value={AppraisalReasonType.SETTLE_FAMILY_ESTATE}>
                {AppraisalReasonType.SETTLE_FAMILY_ESTATE}
              </option>
              <option value={AppraisalReasonType.SETTLING_DISPUTE}>
                {AppraisalReasonType.SETTLING_DISPUTE}
              </option>
              <option value={AppraisalReasonType.UPDATE_INSURANCE}>
                {AppraisalReasonType.UPDATE_INSURANCE}
              </option>
            </AddAppraisalSelect>
          }
        />

        <RowInputField
          inputProps={{ placeholder: "John doe" }}
          value={transferFormValues.appraisedBy}
          onChange={(val: string) =>
            updateFormValues({ name: "appraisedBy", value: val })
          }
          required
          fieldName="Appraised By"
          helperText="Enter in the name or organization that completed the appraisal."
        />

        <RowInputField
          inputProps={{
            placeholder: "John doe",
            type: "date",
            max: new Date().toJSON().split("T")[0],
          }}
          value={transferFormValues.appraisalDate}
          onChange={(val: string) =>
            updateFormValues({ name: "appraisalDate", value: val })
          }
          required
          fieldName="Appraisal Date"
          helperText="The date of appraisal."
        />
      </div>
    </AddAppraisalMainContentWrapper>
  );

  const secondaryContent = <img src={TransferCertificateImg} />;

  const actions = (
    <>
      <Button variant="outlined" onClick={prevStep}>
        ← Back
      </Button>
      <Button
        color="info"
        variant="contained"
        onClick={nextStep}
        disabled={
          isBusy ||
          !transferFormValues.appraisedBy ||
          !transferFormValues.appraisalDate
        }
      >
        Continue
      </Button>
    </>
  );

  return {
    actions,
    mainContent,
    secondaryContent,
  };
};
