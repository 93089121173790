import * as React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import LogoSvg from "../../components/atoms/LogoSvg";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Drawer, Toolbar, IconButton, List, Divider } from "@mui/material";
import { mainListItems, secondaryListItems } from "./menu-items";
import {
  useGetCurrentUser,
  UserCookieType,
} from "library/hooks/useGetCurrentUser";

const drawerWidth = 240;

const DrawerFull = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const SideDrawer = ({
  isDrawerOpen,
  setDrawerState,
}: {
  isDrawerOpen: boolean;
  setDrawerState: (state: boolean) => void;
}) => {
  const user = useGetCurrentUser() as UserCookieType;

  const canCreateCertificates =
    user?.accountType === "artist" || user?.accountType === "company";

  const drawer = () => (
    <>
      <List>{mainListItems(canCreateCertificates)}</List>
      <Divider sx={{ marginTop: "auto" }} />
      <List>{isDrawerOpen && secondaryListItems}</List>
    </>
  );

  return (
    <>
      {/* MOBILE DRAWER */}
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={() => setDrawerState(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{ display: { sm: "block", md: "none" } }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <Box sx={{ width: "50%", marginRight: "auto", marginLeft: "12px" }}>
            <LogoSvg />
          </Box>
          <IconButton onClick={() => setDrawerState(!isDrawerOpen)}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        {drawer()}
      </Drawer>

      {/* FULL DRAWER */}
      <DrawerFull
        open={isDrawerOpen}
        variant="permanent"
        sx={{
          height: "calc(100vh - 64px)",
          display: { xs: "none", sm: "none", md: "flex" },
        }}
      >
        {drawer()}
      </DrawerFull>
    </>
  );
};
