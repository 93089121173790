import React from "react";

import logonew from "../../../assets/img/logonew.svg";
import logosrc from "../../../assets/img/CertSealLogoDark_new.svg";
import logowhite from "../../../assets/img/CertSealLogoLight_new.svg";

type LogoSvgProps = {
  light?: boolean;
  href?: string;
};

const LogoSvg = (props: LogoSvgProps) => {
  return (
    <a href={props.href ? props.href : "/"} style={{ display: "flex" }}>
      <img
        src={props.light ? logowhite : logonew}
        height="50px"
        width="100%"
        title="CertSeal logo"
        alt="CertSeal logo"
      />
    </a>
  );
};

export default LogoSvg;
