import { gql, useQuery } from "@apollo/client";

export type GetUserByWalletData = {
  _id: string;
  username: string;
  wallet: {
    pub: string;
  };
};

type GetUserByWalletQueryData = {
  GetUserByWallet: GetUserByWalletData[];
};

type GetUserByWalletQueryVariables = {
  walletIds: string[];
};

export const getUserCertificates = gql`
  query GetUserByWallet($walletIds: [String]) {
    GetUserByWallet(walletIds: $walletIds) {
      _id
      username
      wallet {
        pub
      }
    }
  }
`;

export const useGetUserByWalletQuery = ({
  walletIds,
}: {
  walletIds: string[];
}) => {
  const result = useQuery<
    GetUserByWalletQueryData,
    GetUserByWalletQueryVariables
  >(getUserCertificates, {
    fetchPolicy: "cache-and-network",
    variables: { walletIds },
  });

  return {
    ...result,
    users: result?.data?.GetUserByWallet,
  };
};
