export const getCurrentUserFromStorage = () => {
  const localStorageData = localStorage.getItem("user");
  const user = JSON.parse(localStorageData);

  if (user && user.accessToken) {
    const timeLog = new Date(user.timeLog);
    timeLog.setDate(timeLog.getDate() + 1);

    if (timeLog < new Date()) {
      clearCurrentUserFromStorage();
      document.location.href = "/";
      return null;
    } else {
      return user;
    }
  } else {
    return null;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const clearCurrentUserFromStorage = () => {
  localStorage.removeItem("user");
};

export const decodeEntity = (inputStr) => {
  var textarea = document.createElement("textarea");
  textarea.innerHTML = inputStr;
  return textarea.value;
};

export const useGetUrlQuery = () => {
  return new URLSearchParams(window.location.search);
};
