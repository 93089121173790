import * as React from "react";
import { Box, Link } from "@mui/material";
import { WalletHeaderCard } from "./WalletHeaderCard";
import { GetOwnerBalanceType } from "library/features/UserWalletHeaderDropdown/data/useGetUserBalances";

type WalletHeaderCardsProps = {
  convertedPrice: string;
  walletBalance?: GetOwnerBalanceType;
  balancesLoading: boolean;
  createdTokensCount: number;
  isLoadingTransfers: boolean;
  estimatedConversion?: string;
  latestTransactionsCount: number;
};
export const WalletHeaderCards = ({
  walletBalance,
  convertedPrice,
  balancesLoading,
  createdTokensCount,
  isLoadingTransfers,
  estimatedConversion,
  latestTransactionsCount,
}: WalletHeaderCardsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "72px",
        justifyContent: "stretch",
        "@media screen and (max-width: 1000px)": {
          gap: 3,
        },
        "@media screen and (max-width: 900px)": {
          gap: 2,
        },
        "@media screen and (max-width: 768px)": {
          gap: 1.5,
          flexWrap: "wrap",
        },
      }}
    >
      <WalletHeaderCard
        title={`${createdTokensCount}`}
        caption={[
          <Link href="/certificates?createdOnly=true" sx={{ color: "#655A7C" }}>
            VIEW ALL
          </Link>,
        ]}
        subTitle="Number of certificates"
      />
      <WalletHeaderCard
        title={`${latestTransactionsCount}`}
        loading={isLoadingTransfers}
        caption={
          <span key="num transaction">Includes sales and transfers</span>
        }
        subTitle="Number of transactions"
      />
      <WalletHeaderCard
        title={
          convertedPrice
            ? convertedPrice.length > 4
              ? `${parseFloat(convertedPrice).toFixed(4)}`
              : `${convertedPrice}`
            : "0.00"
        }
        loading={balancesLoading}
        caption={
          <span key="estimatedconversion">{estimatedConversion ?? "0.00"}</span>
        }
        subTitle="Wallet balance"
        hasTooltip={convertedPrice ?? "0.00"}
        titleCaption="ETH"
      />
    </Box>
  );
};
