import React from "react";
import { useHistory } from "react-router-dom";

import { Grid, Paper, Divider, Typography } from "@mui/material";
import Title from "../../components/atoms/Title";
import CreateNewCertCardButton from "./components/CreateNewButtonCard";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { RouteHeader } from "library/components/molecules/RouteHeader/RouteHeader";

const DashboardRoute = () => {
  const history = useHistory();

  return (
    <>
      <RouteHeader title="Create Certificates" />
      <Grid container spacing={4}>
        <Grid item md={4} sm={12} xs={12}>
          <CreateNewCertCardButton
            title="Simple COA"
            onClick={() => history.push("/create/simple")}
            desc="A generic certificate for everyday items"
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <CreateNewCertCardButton
            title="Autograph COA"
            icon={<CreateOutlinedIcon />}
            onClick={() => history.push("/create/autograph")}
            desc="Certificate for autographed memorabilia"
          />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <CreateNewCertCardButton
            title="Art COA"
            icon={<ColorLensOutlinedIcon />}
            onClick={() => history.push("/create/art")}
            desc="Certificate for artists"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardRoute;
