import { gql, useMutation } from "@apollo/client";

export interface AddAppraisalQueryData {
  AddAppraisal: boolean;
}

export type AddCertificateAppraisalInputValues = {
  appraisedBy: string;
  certificateId: string;
  appraisalDate: string;
  appraisedValue?: number;
  appraisalNotes?: string;
  appraisalReason: string;
  appraisalDocuments?: any;
};

const setAddAppraisalMutation = gql`
  mutation AddAppraisal(
    $addCertificateAppraisalInputValues: AddCertificateAppraisalInputValues!
  ) {
    AddCertificateAppraisal(formValues: $addCertificateAppraisalInputValues)
  }
`;

export const useAddAppraisalMutation = () => {
  return useMutation(setAddAppraisalMutation);
};
