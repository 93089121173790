import * as React from "react";
import { GetSingleCertificateType } from "library/routes/ViewCertificateRevamp/data/getSingleCertificate";
import { getEthUsdConversion } from "lib/api-client";
import { useSnackbar } from "notistack";
import { Card } from "library/components/atoms/Card/Card";
import { AttachMoney, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CurrencyConversionType } from "../ViewCertificateCreateOrderCard/ViewCertificateCreateOrderCardController";
import { UnknownObject } from "library/shared/sharedTypes";
import { ethers } from "ethers";
import { formatMoney } from "library/helpers/moneyFormat";
import { useGetCurrentUser } from "library/hooks/useGetCurrentUser";

export const ViewCertificateBuyOptionsController = ({
  imxData,
  certificate,
}: {
  imxData?: any[];
  certificate: GetSingleCertificateType;
}) => {
  const user = useGetCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [conversion, setConversion] = React.useState<CurrencyConversionType>(
    {}
  );

  // get the active order
  const activeOrder = React.useMemo(
    () => (imxData && imxData[1] ? imxData[1].result[0] : undefined),
    [imxData]
  );

  React.useEffect(() => {
    getEthUsdConversion().then((data: any) => {
      if (data) {
        setConversion(data);
      }
    });
  }, []);

  const convertedLayer2Price = React.useMemo(
    () =>
      activeOrder?.buy?.data?.quantity_with_fees
        ? parseFloat(
            ethers.utils.formatEther(activeOrder?.buy?.data?.quantity_with_fees)
          )
        : 0,
    [activeOrder]
  );

  const TooltipCaption = React.forwardRef((props, ref) => (
    <Box
      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
      {...props}
      ref={ref}
      color="GrayText"
    >
      <InfoOutlined fontSize="inherit" color="inherit" />
      <Typography
        sx={{ display: "flex", gap: "4px", lineHeight: 0 }}
        variant="caption"
      >
        This order contains fees
      </Typography>
    </Box>
  ));

  const AuthenticatedActions = React.useMemo(
    () => (
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{ mb: 2 }}
          color="inherit"
          disabled
          variant="contained"
          onClick={() => alert("coming soon")}
        >
          Buy
        </Button>

        <Button
          sx={{ mb: 2 }}
          color="inherit"
          disabled
          variant="contained"
          onClick={() => alert("coming soon")}
        >
          Pay with Credit Card
        </Button>

        <Button
          sx={{ mb: 2 }}
          color="inherit"
          disabled
          variant="contained"
          onClick={() => alert("coming soon")}
        >
          Make an offer
        </Button>

        <Tooltip
          title={
            <ul style={{ paddingLeft: "14px" }}>
              {activeOrder?.fees.map((fee: UnknownObject, indx: number) => (
                <li key={indx}>
                  {ethers.utils.formatEther(fee.amount)} (eth) for {fee.type}
                </li>
              ))}
            </ul>
          }
          placement="bottom"
          arrow
          disableInteractive
        >
          <TooltipCaption />
        </Tooltip>
      </Box>
    ),
    [activeOrder]
  );

  if (!activeOrder) {
    return null;
  }

  return (
    <div style={{ marginTop: 24 }}>
      <Card title="Order Details" icon={<AttachMoney />}>
        <Typography sx={{ mb: 2 }}>
          Price includes transfer and royalty fees.
        </Typography>

        <Box
          sx={{ mb: 4, display: "flex", gap: "4px", flexDirection: "column" }}
        >
          <Typography variant="h5">
            {convertedLayer2Price}
            <small
              style={{
                fontSize: 14,
                marginLeft: 4,
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
              }}
            >
              (ETH)
            </small>
          </Typography>
          <Typography variant="caption" color="GrayText">
            {activeOrder &&
              conversion.ethereum &&
              `Estimated conversion: ${formatMoney(
                convertedLayer2Price * conversion.ethereum.usd
              )} (USD)`}
          </Typography>
        </Box>

        {user ? (
          AuthenticatedActions
        ) : (
          <Box>
            <Typography sx={{ mb: 1 }}>
              In order to purchase certificates or make offers you will need to
              login to your account. If you do not have an account, you may
              register one for free.
            </Typography>
            <Button variant="contained" href="/">
              Sign in
            </Button>
            <Typography variant="caption" sx={{ m: "0 8px" }}>
              OR
            </Typography>
            <Button variant="contained" href="/register">
              Register an account
            </Button>
          </Box>
        )}
      </Card>
    </div>
  );
};
