import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import { BrowserRouter as Router } from "react-router-dom";

import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { StateProvider, store } from "./lib/store";
import { ENDPOINT, authHeader } from "./lib/api-client";
import { getCurrentUserFromStorage } from "./lib/helpers";

import "./index.css";

const currentUser = getCurrentUserFromStorage();

if (process.env.NODE_ENV === "production") {
  if (currentUser) {
    if (currentUser.id !== "61516214d9168c18dc677506") {
      LogRocket.init("gmt1fe/certseal");
      LogRocket.identify(currentUser.id, {
        username: currentUser.username,
        accountType: currentUser.accountType,
      });
    }
  } else {
    LogRocket.init("gmt1fe/certseal");
  }
}

const gqlClient = new ApolloClient({
  uri: `${ENDPOINT}/graphql`,
  cache: new InMemoryCache(),
  headers: {
    ...authHeader(),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={gqlClient}>
      <StateProvider>
        <Router>
          <App />
        </Router>
      </StateProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
